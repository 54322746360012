import React from 'react';

function CrmIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#689f38"
        d="M48,21c0-1.788-0.821-3.5-2.275-4.54C44.618,15.669,43.071,15,41,15s-3.618,0.669-4.725,1.46	C34.821,17.5,34,19.212,34,21l0,0H48L48,21z"
      />
      <path
        fill="#ffb74d"
        d="M45,10c0,2.209-1.791,4-4,4s-4-1.791-4-4s1.791-4,4-4S45,7.791,45,10"
      />
      <path
        fill="#689f38"
        d="M48,41c0-1.788-0.821-3.5-2.275-4.54C44.618,35.669,43.071,35,41,35s-3.618,0.669-4.725,1.46	C34.821,37.5,34,39.212,34,41l0,0H48L48,41z"
      />
      <path
        fill="#ffb74d"
        d="M45,30c0,2.209-1.791,4-4,4s-4-1.791-4-4s1.791-4,4-4S45,27.791,45,30"
      />
      <path
        fill="#d1c4e9"
        d="M19,39l-1-3l1-4h4v7H19z M19,16l-2-4l2-3h4v7H19z"
      />
      <path
        fill="#f44336"
        d="M0,24l4,1l3-1c0-4.648,3.246-8,8-8h4V9h-3.66C6.868,9,0,15.715,0,24z"
      />
      <path
        fill="#1976d2"
        d="M15.34,39H19v-7h-4c-4.754,0-8-3.351-8-8H0C0,32.285,6.868,39,15.34,39z"
      />
      <path
        fill="#607d8b"
        d="M30,11h-4c-0.552,0-1-0.448-1-1s0.448-1,1-1h4c0.552,0,1,0.448,1,1S30.552,11,30,11z"
      />
      <path
        fill="#607d8b"
        d="M33,16h-5c-0.552,0-1-0.448-1-1s0.448-1,1-1h5c0.552,0,1,0.448,1,1S33.552,16,33,16z"
      />
      <path
        fill="#607d8b"
        d="M30,39h-4c-0.552,0-1-0.448-1-1s0.448-1,1-1h4c0.552,0,1,0.448,1,1S30.552,39,30,39z"
      />
      <path
        fill="#607d8b"
        d="M33,34h-5c-0.552,0-1-0.448-1-1s0.448-1,1-1h5c0.552,0,1,0.448,1,1S33.552,34,33,34z"
      />
    </svg>
  );
}

export default CrmIcon;
