import {employee_http} from "../../https/employee_http";
import {API_URL} from "../../utils/constants";
import {toast} from "react-toastify";
import * as actionTypes from "../actionTypes";
import {setEmployeeLoading} from "./miscActions";

export const getAllStory = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/story`)
      .then(async (res) => {
        dispatch({
          type: actionTypes.GET_ALL_MARKETING_STORIES,
          payload: res.data,
        });

        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createNewStoryName = (title) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/story`, { name: title })
      .then(() => {
        toast.success("Story Created Successfully");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong, While adding story image");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadStoryImage = (id, file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .put(`${API_URL}/story/upload/${id}`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success("Story Uploaded Successfully");
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong, While adding story image");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateImageUrl = (id, image) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/story/${id}`, { image_url: [...image] })
      .then(() => {
        dispatch(setEmployeeLoading(false));
        toast.success("Image Deleted Successfully");
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const deleteEntireStory = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .delete(`${API_URL}/story/${id}`)
      .then(() => {
        dispatch(setEmployeeLoading(false));
        toast.success("Story Deleted Successfully");
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};
