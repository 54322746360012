import {toast} from "react-toastify";
import {setMerchantLoading} from ".";
import {merchant_http} from "../../https/merchant_http";
import {API_URL} from "../../utils/constants";
// import { employee_http } from "../../https/employee_http";
import * as actionTypes from "../actionTypes";

export const fetchMonthDayCost = (from, to) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/analytics/sale/overview/partner?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MONTH_DAY_COST,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchStoreOrders = (from, to) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/sale?&from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_ORDERS,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};
