import React from 'react'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {Divider} from '@mui/material';
import {fetchPartnerNotifications, markPartnerNotificationAsRead, setReloadAPI} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import Button from '@mui/material/Button';
import {push} from "connected-react-router";
import CloseIcon from '@mui/icons-material/Close';
import Badge from '@mui/material/Badge';

const Notifications = () => {
    const dispatch = useDispatch();
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenUserMenu = (event) => {
        dispatch(fetchPartnerNotifications());
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const reloadAPI = useSelector((state) => state.misc.reloadAPI);
    const notifications = useSelector((state) => state.misc.notifications);

    React.useEffect(() => {
        if (reloadAPI) {
            dispatch(setReloadAPI(false));
        } else {
            dispatch(fetchPartnerNotifications());
        }
    }, [dispatch, reloadAPI]);

    const refreshNotifications = () => {
        dispatch(fetchPartnerNotifications());
    }

    return (
        <>
            <Tooltip title="Notifications">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color: "white" }}>
                    {/* <Avatar alt="Notifications" src={notificationIcon} /> */}
                    <Badge
                        badgeContent={"10+"}
                        color="error"
                    >
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Menu
                sx={{ mt: '30px', maxWidth: "500px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {notifications?.map((notification, index) => (
                    <>
                        {index > 0 && <Divider />}
                        <MenuItem>
                            <Box direction="column" sx={{ maxWidth: "100%" }}>
                                <Stack spacing={3} direction="row" justifyContent="space-between" alignItems="center" sx={{ minWidth: "300px" }}>
                                    <Typography variant="subtitle1">{notification?.title}</Typography>
                                    <IconButton size="small" aria-label="close">
                                        <CloseIcon fontSize="small" onClick={() => dispatch(markPartnerNotificationAsRead(notification?._id, refreshNotifications))} />
                                    </IconButton>
                                </Stack>
                                <Typography sx={{ color: "#00000099", maxWidth: "100%", whiteSpace: "normal" }} variant="body2">{notification?.message}</Typography>
                                <Typography variant="caption">{moment(notification?.createdAt).format("DD/MM/YYYY")}</Typography>
                                <Stack spacing={2} sx={{ mt: 4 }} direction="row" justifyContent="space-between" alignItems="center">
                                    {notification?.actions?.map((action) => (
                                        <Button
                                            color="primary" variant="contained" component="span" size="small"
                                            sx={{ textTransform: "none" }}
                                            onClick={(e) => dispatch(push(action?.link))}
                                            disabled={!action?.link}>
                                            {action?.text}
                                        </Button>
                                    ))}

                                </Stack>
                            </Box>

                        </MenuItem>
                    </>
                ))}
            </Menu>
        </>
    )
}

export default Notifications