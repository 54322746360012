import {toast} from "react-toastify";
import {setEmployeeLoading, setMerchantLoading, setReloadAPI} from ".";
import {employee_http} from "../../https/employee_http";
import {merchant_http} from "../../https/merchant_http";
import * as actionTypes from "../actionTypes";
import {API_URL} from "../../utils/constants";

export const fetchTempDetails = (status) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/bank-details-edit`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TEMP_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const updateTempDetails = (data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/bank-details-edit/approve`, data)
      .then(async () => {
        if(cb){
          cb()
        }
        await dispatch(setEmployeeLoading(false));
        await dispatch(setReloadAPI(true));
        //   toast.success("Username generated");
      })
      .catch(() => {
        toast.error("Error while generating user name");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const sendRequest = (data, file) => {
  return (dispatch) => {
      let formData = new FormData();
      formData.append("file", file);
      formData.append("storeId",data.storeId)
      formData.append("accountNumber",data.accountNumber)
      formData.append("ifscCode",data.ifscCode)
      formData.append("bankName",data.bankName)
      formData.append("branchName",data.branchName)
      formData.append("beneficiaryName",data.beneficiaryName)
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}/bank-details-edit`, formData, {
          "Content-Type": "multipart/form-data",
      })
      .then(async () => {
        await dispatch(setMerchantLoading(false));
        toast.success("Application sent");
      })
      .catch((error) => {
        toast.error(
          error.response.data
            ? error.response.data.message
            : "Unable to process !"
        );
        dispatch(setMerchantLoading(false));
      });
  };
};
