import * as actionTypes from "../actionTypes";

let initialState = {
  config: [],
  partnerConfig: [],
  calculation: [],
  calculationTotal: {}
};

export const fundInflowReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ESTIMATED_FUND_INFLOW_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    case actionTypes.UPDATE_ESTIMATED_FUND_INFLOW_CONFIG:
      let configUpdate = [...state.config];
      const configUpdateIndex = configUpdate.findIndex(
        (a) => a?._id?.partner_id === action.payload?._id?.partner_id
      );
      if (configUpdateIndex > -1) {
        configUpdate[configUpdateIndex] = action.payload;
      }
      return {
        config: configUpdate,
      };
    case actionTypes.SET_ESTIMATED_FUND_INFLOW_CALCULATION:
      return {
        ...state,
        calculation: action.payload,
      };
    case actionTypes.SET_ESTIMATED_FUND_INFLOW_CALCULATION_TOTAL:
      return {
        ...state,
        calculationTotal: action.payload,
      };
    case actionTypes.SET_ESTIMATED_FUND_INFLOW_CONFIG_FOR_PARTNER:
      return {
        ...state,
        partnerConfig: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        config: [],
        partnerConfig: [],
        calculation: [],
        calculationTotal: {}
      };
    default:
      return state;
  }
};
