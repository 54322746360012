import React from 'react';

function MapIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <g data-name="03-google map">
        <circle cx="45" cy="19" r="6" fill="#a83229" />
        <polygon fill="#4a9bf6" points="41 63 7 63 24 46 41 63" />
        <path
          fill="#d3d9f2"
          d="M63,7V57a6,6,0,0,1-6,6H53L30,40l7.61-7.61A102.883,102.883,0,0,0,45,41S59,26.73,59,19a13.921,13.921,0,0,0-1.57-6.43Z"
        />
        <path
          fill="#ffdf64"
          d="M63,7l-5.57,5.57a13.908,13.908,0,0,0-6-6L57,1A6,6,0,0,1,63,7Z"
        />
        <polygon fill="#f7f7ff" points="53 63 41 63 24 46 30 40 53 63" />
        <path
          fill="#ffdf64"
          d="M37.61,32.39,30,40l-6,6L7,63a6,6,0,0,1-6-6L32.89,25.11A50.652,50.652,0,0,0,37.61,32.39Z"
        />
        <path
          fill="#28be77"
          d="M57,1,51.43,6.57A13.991,13.991,0,0,0,31,19a14.149,14.149,0,0,0,1.89,6.11L1,57V7A6,6,0,0,1,7,1Z"
        />
        <path
          fill="#d73d3f"
          d="M57.43,12.57A13.921,13.921,0,0,1,59,19c0,7.73-14,22-14,22a102.883,102.883,0,0,1-7.39-8.61,50.652,50.652,0,0,1-4.72-7.28A14.149,14.149,0,0,1,31,19a14,14,0,0,1,26.43-6.43ZM51,19a6,6,0,1,0-6,6A6,6,0,0,0,51,19Z"
        />
        <polygon fill="#3b7cc4" points="11 59 7 63 41 63 37 59 11 59" />
        <path
          fill="#aeb2d4"
          d="M57,59H49l4,4h4a6,6,0,0,0,6-6V53A6,6,0,0,1,57,59Z"
        />
        <path fill="#b8bce0" d="M62.117,7.883A5.945,5.945,0,0,1,63,11V7Z" />
        <path
          fill="#ffb844"
          d="M57,5a5.992,5.992,0,0,1,5.117,2.883L63,7a6,6,0,0,0-6-6L53,5Z"
        />
        <polygon fill="#cacfe8" points="37 59 41 63 53 63 49 59 37 59" />
        <path
          fill="#ffb844"
          d="M7,59a5.992,5.992,0,0,1-5.117-2.883L1,57a6,6,0,0,0,6,6l4-4Z"
        />
        <path fill="#1fa463" d="M1,53v4l.883-.883A5.945,5.945,0,0,1,1,53Z" />
        <path fill="#1fa463" d="M7,5H53l4-4H7A6,6,0,0,0,1,7v4A6,6,0,0,1,7,5Z" />
        <path
          fill="#91323c"
          d="M45,17a6,6,0,0,1,5.65,4,6,6,0,1,0-11.3,0A6,6,0,0,1,45,17Z"
        />
        <path
          fill="#1fa463"
          d="M31.2,20.71A14.048,14.048,0,0,0,31,23a10.248,10.248,0,0,0,.668,3.332L32.89,25.11A17.958,17.958,0,0,1,31.2,20.71Z"
        />
        <path
          fill="#aeb2d4"
          d="M58.793,20.735C57.082,28.681,45,41,45,41a102.883,102.883,0,0,1-7.39-8.61l-1.68,1.68c.549.787,1.108,1.566,1.68,2.32A102.883,102.883,0,0,0,45,45S59,30.73,59,23A13.843,13.843,0,0,0,58.793,20.735Z"
        />
        <path
          fill="#ffb844"
          d="M32.89,25.11l-1.222,1.222A21.63,21.63,0,0,0,32.89,29.11a43.491,43.491,0,0,0,3.04,4.96l1.68-1.68A50.652,50.652,0,0,1,32.89,25.11Z"
        />
        <path d="M45,12a7,7,0,1,0,7,7A7.008,7.008,0,0,0,45,12Zm0,12a5,5,0,1,1,5-5A5.006,5.006,0,0,1,45,24Z" />
        <rect
          width="2.828"
          height="2"
          x="28.586"
          y="27"
          transform="rotate(-45 30 28)"
        />
        <path d="M13,7a5.974,5.974,0,0,1,4.684,2.25L19.245,8A8,8,0,1,0,21,13a1,1,0,0,0-1-1H13v2h5.917A6,6,0,1,1,13,7Z" />
        <path d="M62,57a5.006,5.006,0,0,1-5,5H53.414l-22-22,4.293-4.293-1.414-1.414L6.624,61.962a4.978,4.978,0,0,1-4.586-4.586L27.707,31.707l-1.414-1.414L2,54.586V7A5.006,5.006,0,0,1,7,2H9V0H7A7.008,7.008,0,0,0,0,7V57a7.008,7.008,0,0,0,7,7H57a7.008,7.008,0,0,0,7-7V55H62ZM50.586,62H41.414l-16-16L30,41.414Zm-12,0H9.414L24,47.414Z" />
        <path d="M57,0H15V2H54.586L51.219,5.367A14.969,14.969,0,0,0,30,19c0,8.048,13.7,22.106,14.286,22.7a1,1,0,0,0,1.428,0C46.3,41.106,60,27.048,60,19a14.89,14.89,0,0,0-1.367-6.219L61.95,9.464c.035.541.05,1.062.05,1.536V53h2V11C64,1.072,59.1,0,57,0ZM45,39.55c-2.817-3-13-14.284-13-20.55a13,13,0,0,1,26,0C58,25.266,47.817,36.549,45,39.55Zm12.636-28.6A15.107,15.107,0,0,0,53.05,6.364l4.332-4.332c2.55.21,3.727,2.468,4.241,4.931Z" />
        <rect width="2" height="2" x="11" />
      </g>
    </svg>
  );
}

export default MapIcon;
