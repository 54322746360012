import axios from "axios";
import { toast } from "react-toastify";
import { setEmployeeLoading, setMerchantLoading, setReloadAPI } from ".";
import { employee_http } from "../../https/employee_http";
import { merchant_http } from "../../https/merchant_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";
import fileDownload from "js-file-download";

export const fetchMyTickets = (
  status,
  limit,
  assignedTeam,
  assignedCategory,
  assignedSubCategory,
  number,
  title,
  partner
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/ticket?status=${status}&page=1&limit=${limit}&partner=${
      partner ? partner : ""
    }`;
    if (assignedTeam && assignedTeam !== "all") {
      url += `&team=${assignedTeam}`;
    }
    if (assignedCategory && assignedCategory !== "") {
      url += `&category=${assignedCategory}`;
    }
    if (assignedSubCategory && assignedSubCategory !== "") {
      url += `&subCategory=${assignedSubCategory}`;
    }
    if (number) {
      url += `&number=${number}`;
    }
    if (title) {
      url += `&title=${title}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MY_TICKETS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get tickets !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchMyTicketsPagination = (url) => {
  return (dispatch) => {
    employee_http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_MY_TICKETS_PAGINATION,
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const fetchPartnerMyTickets = (
  status,
  limit,
  assignedTeam,
  number,
  title
) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    let url = `${API_URL}/ticket/partner?status=${status}&page=1&limit=${limit}`;
    if (assignedTeam && assignedTeam !== "all") {
      url += `&team=${assignedTeam}`;
    }
    if (number) {
      url += `&number=${number}`;
    }
    if (title) {
      url += `&title=${title}`;
    }
    merchant_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_MY_TICKETS,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get tickets !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchPartnerMyTicketsPagination = (url) => {
  return (dispatch) => {
    merchant_http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_PARTNER_MY_TICKETS_PAGINATION,
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const fetchAllTickets = (
  status,
  limit,
  assignedTeam,
  assignedCategory,
  assignedSubCategory,
  number,
  title,
  partner
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/ticket/all?status=${status}&page=1&limit=${limit}&partner=${
      partner ? partner : ""
    }`;
    if (assignedTeam && assignedTeam !== "all") {
      url += `&team=${assignedTeam}`;
    }
    if (assignedCategory && assignedCategory !== "") {
      url += `&category=${assignedCategory}`;
    }
    if (assignedSubCategory && assignedSubCategory !== "") {
      url += `&subCategory=${assignedSubCategory}`;
    }
    if (number) {
      url += `&number=${number}`;
    }
    if (title) {
      url += `&title=${title}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ALL_TICKETS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get tickets !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAllTicketsPagination1 = (url) => {
  return (dispatch) => {
    employee_http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_TICKETS,
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const fetchAllTicketsCount = async (
  assignedTeam,
  assignedCategory,
  assignedSubCategory,
  number,
  title,
  partner
) => {
  try {
    let url = `${API_URL}/ticket/count/all`;
    let added = false;
    if (assignedTeam && assignedTeam !== "all") {
      if (!added) url += "?";
      else url += "&";
      url += `team=${assignedTeam}`;
      added = true;
    }
    if (assignedCategory && assignedCategory !== "") {
      url += `&category=${assignedCategory}`;
    }
    if (assignedSubCategory && assignedSubCategory !== "") {
      url += `&subCategory=${assignedSubCategory}`;
    }
    if (number) {
      if (!added) url += "?";
      else url += "&";
      url += `number=${number}`;
      added = true;
    }
    if (partner) {
      if (!added) url += "?";
      else url += "&";
      url += `partner=${partner}`;
      added = true;
    }
    if (title) {
      if (!added) url += "?";
      else url += "&";
      url += `&title=${title}`;
      added = true;
    }
    return (await employee_http.get(url)).data;
  } catch (e) {
    toast.error("Error in Fetch All Tickets Count");
  }
};
export const fetchMyTicketsCount = async () => {
  try {
    return (await employee_http.get(`${API_URL}/ticket/count/my/employee`))
      .data;
  } catch (e) {
    toast.error("Error in Fetch My Tickets Count");
  }
};
export const fetchAssignedTicketsCount = async (selectedPartner) => {
  try {
    return (
      await employee_http.get(
        `${API_URL}/ticket/count/assigned/employee?partner=${
          selectedPartner ? selectedPartner : ""
        }`
      )
    ).data;
  } catch (e) {
    toast.error("Error in Fetch Assigned Tickets Count");
  }
};
export const fetchPartnerMyTicketsCount = async () => {
  try {
    return (await merchant_http.get(`${API_URL}/ticket/count/my/store`)).data;
  } catch (e) {
    toast.error("Error in Partner Fetch My Tickets Count");
  }
};

export const fetchAllTicketsPagination = (url) => {
  return (dispatch) => {
    employee_http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_ALL_TICKETS_PAGINATION,
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const fetchPartnerTicketByNumber = (number) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/ticket/partner/${number}`)
      .then((res) => {
        dispatch(setMerchantLoading(false));
        if (res?.data?._id) {
          dispatch({
            type: actionTypes.SET_TICKET_DETAILS,
            payload: res?.data?._id ? res.data : {},
          });
        } else {
          dispatch(setViewTicket(""));
          dispatch({
            type: actionTypes.SET_TICKET_DETAILS,
            payload: {},
          });
        }
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
        dispatch(setViewTicket(""));
        dispatch({
          type: actionTypes.SET_TICKET_DETAILS,
          payload: {},
        });
      });
  };
};

export const fetchTicketsOverview = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/analytics/ticket/overview/status`)
      .then((res) => {
        dispatch(setEmployeeLoading(false));
        dispatch({
          type: actionTypes.SET_TICKETS_OVERVIEW,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchDelayedReports = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/ticket/eta/crossed`)
      .then((res) => {
        dispatch(setEmployeeLoading(false));
        dispatch({
          type: actionTypes.SET_DELAYED_REPORTS,
          payload: res.data,
        });
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createNewTicket = (formData, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/ticket`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        dispatch(setEmployeeLoading(false));
        dispatch({
          type: actionTypes.ADD_TO_MY_TICKETS,
          payload: res.data,
        });
        cb();
      })
      .catch(() => {
        toast.error("Unable to create ticket !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createNewPartnerTicket = (formData, cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}/ticket/partner`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        dispatch(setMerchantLoading(false));
        dispatch({
          type: actionTypes.ADD_TO_PARTNER_MY_TICKETS,
          payload: res.data,
        });
        cb();
      })
      .catch(() => {
        toast.error("Unable to create ticket !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const updateAssignedTeam = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/ticket/${id}/team`, data)
      .then((res) => {
        dispatch(setEmployeeLoading(false));
        dispatch({
          type: actionTypes.UPDATE_ALL_TICKETS,
          payload: res.data,
        });
        cb();
      })
      .catch(() => {
        toast.error("Unable to update ticket !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAssignedTickets = (status, limit, selectedPartner) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/ticket/assigned?status=${status}&page=1&limit=${limit}&partner=${
          selectedPartner ? selectedPartner : ""
        }`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ASSIGNED_TICKETS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get tickets !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAssignedTicketsPagination = (url) => {
  return (dispatch) => {
    employee_http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_ASSIGNED_TICKETS_PAGINATION,
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const setViewTicket = (ticketId) => ({
  type: actionTypes.SET_VIEW_TICKET,
  payload: ticketId,
});

export const getIndividualTicketDetails = (ticketId) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    axios
      .get(`${API_URL}/ticket/${ticketId}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TICKET_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get ticket details !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const addTicketComments = (ticketId, formData) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/ticket/${ticketId}/comment`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TICKET_DETAILS,
          payload: res.data,
        });
        // await setViewTicket(ticketId);
        await dispatch(setEmployeeLoading(false));
      })

      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const addTicketInternalComments = (ticketId, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/ticket/${ticketId}/internalComment`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TICKET_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })

      .catch(() => {
        // toast.error("Unable to add comments !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const addPartnerTicketComments = (ticketId, formData) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}/ticket/${ticketId}/comment/partner`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TICKET_DETAILS,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to add comments !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const setTicketStatus = (ticketId, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/ticket/${ticketId}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TICKET_DETAILS,
          payload: res.data,
        });
        await dispatch(setReloadAPI(true));
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        // toast.error("Unable to update status!");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const updateEta = (ticketId, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/ticket/${ticketId}/eta`, data)
      .then(async (res) => {
        toast.success("Eta added !");
        await dispatch(setReloadAPI(true));
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to add Eta!");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const downloadAttachment = (url, name) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    axios
      .get(`https://${url}`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `${name}`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateStoreTimingDetails = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/my-details/timing`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_DATA,
          payload: res.data,
        });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updatePackagingCharges = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/my-details/packagingCharges`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateAutoOnOff = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/autoonoff/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateErrorInKot = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/ErrorInKOT/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateKotInvoice = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/kotInvoice/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateUnableLogin = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/unableToLogin/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateUnableToMarkOOS = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/unbleToMarkOOS/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const updateResIDClosed = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/resIDClosed/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateRidersComplaints = (formData) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .post(`${API_URL}/store/ridersComplaints/mail`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const updateOrderCancellation = (formData) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .post(`${API_URL}/store/orderCancellation/mail`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const updateRaiderSpoil = (formData) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .post(`${API_URL}/store/raiderSpoil/mail`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const updateAutoAcceptanced = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/autoAcceptance/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateCustomerComplaints = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/customerComplaints/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateMenuStacking = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/menuStacking/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateBuzzerNotWorking = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/buzzerNotWorking/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const ticketActiveMarkIdsMail = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/active-marks-ids/mail`, data)
      .then(async (res) => {
        // await dispatch({
        //   type: actionTypes.SET_MERCHANT_DATA,
        //   payload: res.data,
        // });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateInactiveDate = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/my-details/inactive`, data)
      .then(async (res) => {
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const updateResCallingNumber = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/callingNumber/mail`, data)
      .then(async (res) => {
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const updateEmployeeTicketAddressDetails = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .put(`${API_URL}/store/my-details/address`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_DATA,
          payload: res.data,
        });
        toast.success("Update and Send Mail !");
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
