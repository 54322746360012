import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../assets/logo/logo_1.svg";
import empAvatar from "../../assets/header/employee-profile.jpg";
import Popover from "@mui/material/Popover";
import Logout from "@mui/icons-material/LogoutOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";

import {useDispatch, useSelector} from "react-redux";
import {employeeLogout} from "../../redux/actions";
import MenuDrawer from "./components/MenuDrawer";
import Notifications from "./components/Notifications";
import {WHATSAPP_ACCESS} from "../../utils/constants";
import CustomWhatsapp from "../EmployeeHeader/components/CustomWhatsapp";

const ResponsiveAppBar = () => {
  const dispatch = useDispatch();
  const employeeDetails = useSelector((state) => state.employeeAuth.loginData);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "black",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ maxHeight: "50px" }}>
            <Box sx={{ mx: "12px", flexGrow: 1 }}>
              <img src={logo} height={64} width={97} alt={""} />
            </Box>
            <Box sx={{ mx: "12px", flexGrow: 0 }}>
              <Typography textAlign="center">
                {employeeDetails?.name}
              </Typography>
            </Box>
            <Box sx={{ mx: "12px", flexGrow: 0 }}>
              <Notifications />
            </Box>
            <Box sx={{ mx: "12px", flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Profile" src={empAvatar} />
                </IconButton>
              </Tooltip>
              <Popover
                anchorEl={anchorElUser}
                keepMounted
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {WHATSAPP_ACCESS.includes(employeeDetails?.username) && (
                  <div style={{ padding: 8 }}>
                    <CustomWhatsapp checkStatusUpdate={Boolean(anchorElUser)} />
                  </div>
                )}
                <MenuItem onClick={() => dispatch(employeeLogout())}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Popover>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <MenuDrawer />
    </>
  );
};
export default ResponsiveAppBar;
