import * as actionTypes from "../actionTypes";

let initialState = {
  monthdaycost: [],
  storeorders: [],
};

export const merchantFoodCostReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MONTH_DAY_COST:
      return {
        ...state,
        monthdaycost: action.payload,
      };
    case actionTypes.SET_STORE_ORDERS:
      return {
        ...state,
        storeorders: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        monthdaycost: [],
        storeorders: [],
      };
    default:
      return state;
  }
};
