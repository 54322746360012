export const SET_MISC_LOADING = "SET_MISC_LOADING";

export const EMPLOYEE_LOADING = "EMPLOYEE_LOADING";
export const MERCHANT_LOADING = "MERCHANT_LOADING";

export const SET_RELOAD_API = "SET_RELOAD_API";

export const SET_BRAND_LIST = "SET_BRAND_LIST";
export const SET_KPT_RWT = "SET_KPT_RWT";
export const UPDATE_BRAND_LIST = "UPDATE_BRAND_LIST";

export const SET_STATE_LIST = "SET_STATE_LIST";
export const UPDATE_STATE_LIST = "UPDATE_STATE_LIST";

export const SET_BANNER_LIST = "SET_BANNER_LIST";
export const ADD_NEW_BANNER_LIST = "ADD_NEW_BANNER_LIST";
export const UPDATE_BANNER_LIST = "UPDATE_BANNER_LIST";
export const DELETE_BANNER = "DELETE_BANNER";

export const SET_EMPLOYEE_COC = "SET_EMPLOYEE_COC";

export const SET_AGGREGATOR_MAIL_DATA = "SET_AGGREGATOR_MAIL_DATA";
export const SET_AGGREGATOR_MAIL_APPROVAL_DATA =
  "SET_AGGREGATOR_MAIL_APPROVAL_DATA";

export const SET_PETTY_CASH_PAYMENTS = "SET_PETTY_CASH_PAYMENTS";
export const UPDATE_PETTY_CASH_PAYMENTS = "UPDATE_PETTY_CASH_PAYMENTS";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";

export const SET_PARTNER_NEWS_LIST = "SET_PARTNER_NEWS_LIST";
export const SET_NEWS_LIST = "SET_NEWS_LIST";
export const UPDATE_NEWS_LIST = "UPDATE_NEWS_LIST";
export const ADD_NEW_NEWS = "ADD_NEW_NEWS";
export const DELETE_NEWS = "DELETE_NEWS";

export const SET_LEARNING_RESOURCES = "SET_LEARNING_RESOURCES";

export const SET_UPLOAD_STATUS_LIST = "SET_UPLOAD_STATUS_LIST";

export const SET_TICKET_CATEGORY = "SET_TICKET_CATEGORY";

export const GET_ALL_JD = "GET_ALL_JD";

export const SET_ALL_TAGS = "SET_ALL_TAGS";
export const GET_ALL_FAQS = "GET_ALL_FAQS";
export const GET_SUGGEST_FAQS = "GET_SUGGEST_FAQS";

export const GET_ALL_CUISINES = "GET_ALL_CUISINES";

export const GET_ALL_BLOGGER_ORDERS = "GET_ALL_BLOGGER_ORDERS";
export const GET_ALL_LOGS = "GET_ALL_LOGS";
export const GET_ADDITIONAL_BRAND_REQUEST = "GET_ADDITIONAL_BRAND_REQUEST";
export const GET_ALL_PARTNER_BUCKET = "GET_ALL_PARTNER_BUCKET";
export const GET_ALL_LEAD_GEN = "GET_ALL_LEAD_GEN";
export const GET_ALL_LEAD_GEN_COUNT = "GET_ALL_LEAD_GEN_COUNT";
export const GET_ALL_LEAD_GEN_STATUS_COUNTS = "GET_ALL_LEAD_GEN_STATUS_COUNTS";
export const fetch_reviews = "fetch_reviews";
export const fetch_weekly_review = "fetch_weekly_review";
export const fetch_brandwise_review = "fetch_brandwise_review";
export const fetch_review_comments = "fetch_review_comments";
export const fetch_reviews_count = "fetch_reviews_count";
export const treanding_words = "treanding_words";
export const fetch_gmail_logs = "fetch_gmail_logs";
export const fetch_gmail_logs_details = "fetch_gmail_logs_details";
export const check_bio_metric_code = "check_bio_metric_code";
export const FIND_ALL_EMPLOYEE = "FIND_ALL_EMPLOYEE";
export const FETCH_OPTIPRO_SCHEDULERS = "FETCH_OPTIPRO_SCHEDULERS";
export const FETCH_OPTIPRO_SCHEDULERS_FOR_CONFIRMATION =
  "FETCH_OPTIPRO_SCHEDULERS_FOR_CONFIRMATION";
export const FETCH_BEST_SALE_DATES = "FETCH_BEST_SALE_DATES";
