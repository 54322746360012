import * as actionTypes from "../actionTypes";

let initialState = {
  approvalList: [],
  employeeList: [],
  departments: [],
  regions: [],
};

export const employeeDetailsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEE_APPROVAL_LIST:
      return {
        ...state,
        approvalList: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LIST:
      return {
        ...state,
        employeeList: action.payload,
      };
    case actionTypes.UPDATE_EMPLOYEE_LIST:
      let update_employee_list = [...state.employeeList];
      const update_employee_list_index = update_employee_list.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_employee_list_index > -1)
        update_employee_list[update_employee_list_index] = action.payload;
      return {
        ...state,
        employeeList: update_employee_list,
      };
    case actionTypes.DELETE_EMPLOYEE_LIST:
      let delete_employee_list = [...state.employeeList];
      const delete_employee_list_index = delete_employee_list.findIndex(
        (a) => a._id === action.payload
      );
      if (delete_employee_list_index > -1)
        delete_employee_list.splice(delete_employee_list_index, 1);
      return {
        ...state,
        employeeList: delete_employee_list,
      };
    case actionTypes.SET_EMPLOYEE_DEPARTMENT_LIST:
      return {
        ...state,
        departments: action.payload,
      };
    case actionTypes.UPDATE_EMPLOYEE_DEPARTMENT_LIST:
      let update_employee_department = [...state.departments];
      const update_employee_department_index =
        update_employee_department.findIndex(
          (a) => a._id === action.payload._id
        );
      if (update_employee_department_index > -1)
        update_employee_department[update_employee_department_index] =
          action.payload;
      return {
        ...state,
        departments: update_employee_department,
      };

    case actionTypes.ADD_EMPLOYEE_DEPARTMENT_LIST:
      return {
        ...state,
        departments: [...state.departments, action.payload],
      };
    case actionTypes.SET_EMPLOYEE_REGION_LIST:
      return {
        ...state,
        regions: action.payload,
      };
    case actionTypes.UPDATE_EMPLOYEE_REGION_LIST:
      let update_employee_region = [...state.regions];
      const update_employee_region_index = update_employee_region.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_employee_region_index > -1)
        update_employee_region[update_employee_region_index] = action.payload;
      return {
        ...state,
        regions: update_employee_region,
      };
    case actionTypes.ADD_EMPLOYEE_REGION_LIST:
      return {
        ...state,
        regions: [...state.regions, action.payload],
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        approvalList: [],
        employeeList: [],
        departments: [],
        regions: [],
      };
    default:
      return state;
  }
};
