import React from 'react';

function AnalyticsIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 520 520"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#f2f2f2"
        d="M431.211,118.71c-5.431,5.43-12.931,8.79-21.211,8.79H115c-16.57,0-30-13.43-30-30v350h355v-30 v-320C440,105.78,436.641,113.28,431.211,118.71z"
      />
      <path
        fill="#87c7ff"
        d="M472.5,172.5c6.9,0,12.5,5.6,12.5,12.5s-5.6,12.5-12.5,12.5c-6.909,0-12.5-5.6-12.5-12.5 S465.591,172.5,472.5,172.5z"
      />
      <path
        fill="#87c7ff"
        d="M50,267.5c8.28,0,15,6.72,15,15s-6.72,15-15,15c-8.29,0-15-6.72-15-15S41.71,267.5,50,267.5z"
      />
      <rect width="30" height="56.69" x="355" y="275.81" fill="#e04434" />
      <rect width="30" height="74.76" x="295" y="257.74" fill="#e04434" />
      <polygon
        fill="#ff7058"
        points="415,190 415,332.5 385,332.5 385,275.81 385,190"
      />
      <polygon
        fill="#ff7058"
        points="355,275.81 355,332.5 325,332.5 325,257.74 325,235.16 355,235.16"
      />
      <polygon
        fill="#ff7058"
        points="295,257.74 295,332.5 265,332.5 265,221.61 295,221.61"
      />
      <path
        fill="#4a7aff"
        d="M242.83,336.19l-12.47,4.54l-57.2,20.84l21.31-56.65l4.67-12.42 C219.21,300.17,235.15,316.12,242.83,336.19z"
      />
      <path
        fill="#f5ea0a"
        d="M173.16,361.57l57.2-20.84l0.07,0.18c2.95,6.5,4.13,14.17,4.13,22.43c0,2.57-0.16,5.101-0.46,7.59 L173.16,361.57z"
      />
      <path
        fill="#f5ea0a"
        d="M172.57,301.36c7.68,0,14.77,1.18,21.85,3.54l0.05,0.02l-21.31,56.65l-18.22-57.75 C160.53,302.22,166.44,301.36,172.57,301.36z"
      />
      <path
        fill="#07cc66"
        d="M173.16,361.57l60.94,9.359c-1.71,14.12-8.15,26.88-17.69,36.551 c-11.22,11.359-26.72,18.449-43.84,18.449c-34.83,0-62.57-28.34-62.57-62.59c0-28.21,18.83-52.01,44.94-59.52L173.16,361.57z"
      />
      <path
        fill="#c4c3c2"
        d="M115,67.5c16.56,0,30,13.43,30,30s-13.44,30-30,30c-16.57,0-30-13.43-30-30S98.43,67.5,115,67.5z"
      />
      <path
        fill="#dddcdb"
        d="M410,67.5c16.561,0,30,13.43,30,30c0,8.28-3.359,15.78-8.789,21.21 c-5.431,5.43-12.931,8.79-21.211,8.79H115c16.56,0,30-13.43,30-30s-13.44-30-30-30H410z"
      />
      <path d="M472.5,122.5c4.141,0,7.5,3.36,7.5,7.5s-3.359,7.5-7.5,7.5c-4.15,0-7.5-3.36-7.5-7.5S468.35,122.5,472.5,122.5z" />
      <path d="M472.5,317.5c4.141,0,7.5,3.36,7.5,7.5s-3.359,7.5-7.5,7.5c-4.15,0-7.5-3.36-7.5-7.5S468.35,317.5,472.5,317.5z" />
      <path d="M347.5,37.5c4.141,0,7.5,3.36,7.5,7.5s-3.359,7.5-7.5,7.5c-4.15,0-7.5-3.36-7.5-7.5S343.35,37.5,347.5,37.5z" />
      <path d="M242.5,467.5c4.14,0,7.5,3.36,7.5,7.5s-3.36,7.5-7.5,7.5c-4.15,0-7.5-3.36-7.5-7.5S238.35,467.5,242.5,467.5z" />
      <path d="M62.5,202.5c4.14,0,7.5,3.36,7.5,7.5s-3.36,7.5-7.5,7.5c-4.15,0-7.5-3.36-7.5-7.5S58.35,202.5,62.5,202.5z" />
      <path d="M47.5,417.5c4.14,0,7.5,3.36,7.5,7.5s-3.36,7.5-7.5,7.5c-4.15,0-7.5-3.36-7.5-7.5S43.35,417.5,47.5,417.5z" />
      <path d="M42.5,97.5c4.14,0,7.5,3.36,7.5,7.5s-3.36,7.5-7.5,7.5c-4.15,0-7.5-3.36-7.5-7.5S38.35,97.5,42.5,97.5z" />
      <path d="M115,132.5c-19.299,0-35-15.701-35-35s15.701-35,35-35s35,15.701,35,35S134.299,132.5,115,132.5z M115,72.5 c-13.785,0-25,11.215-25,25s11.215,25,25,25s25-11.215,25-25S128.785,72.5,115,72.5z" />
      <path d="M410,132.5H115c-2.761,0-5-2.239-5-5s2.239-5,5-5h295c6.674,0,12.951-2.602,17.675-7.326S435,104.173,435,97.5 c0-13.785-11.215-25-25-25H115c-2.761,0-5-2.239-5-5s2.239-5,5-5h295c19.299,0,35,15.701,35,35 c0,9.344-3.642,18.132-10.254,24.745C428.133,128.858,419.345,132.5,410,132.5z" />
      <path d="M440,452.5H85c-2.761,0-5-2.238-5-5v-350c0-2.761,2.239-5,5-5s5,2.239,5,5v345h345v-345c0-2.761,2.239-5,5-5 c2.762,0,5,2.239,5,5v350C445,450.262,442.762,452.5,440,452.5z" />
      <path d="M50,302.5c-11.028,0-20-8.972-20-20s8.972-20,20-20s20,8.972,20,20S61.028,302.5,50,302.5z M50,272.5 c-5.514,0-10,4.486-10,10s4.486,10,10,10s10-4.486,10-10S55.514,272.5,50,272.5z" />
      <path d="M472.5,202.5c-9.649,0-17.5-7.851-17.5-17.5s7.851-17.5,17.5-17.5S490,175.351,490,185S482.149,202.5,472.5,202.5z M472.5,177.5c-4.136,0-7.5,3.364-7.5,7.5s3.364,7.5,7.5,7.5s7.5-3.364,7.5-7.5S476.636,177.5,472.5,177.5z" />
      <path d="M295,337.5h-30c-2.761,0-5-2.238-5-5V221.61c0-2.761,2.239-5,5-5h30c2.762,0,5,2.239,5,5v36.13c0,2.762-2.238,5-5,5 c-2.761,0-5-2.238-5-5v-31.13h-20V327.5h25c2.762,0,5,2.238,5,5S297.762,337.5,295,337.5z" />
      <path d="M325,337.5h-30c-2.761,0-5-2.238-5-5v-74.76c0-2.761,2.239-5,5-5h30c2.762,0,5,2.239,5,5c0,2.762-2.238,5-5,5h-25v64.76 h25c2.762,0,5,2.238,5,5S327.762,337.5,325,337.5z" />
      <path d="M355,337.5h-30c-2.761,0-5-2.238-5-5v-97.34c0-2.761,2.239-5,5-5h30c2.762,0,5,2.239,5,5v40.649c0,2.762-2.238,5-5,5 c-2.761,0-5-2.238-5-5V240.16h-20v87.34h25c2.762,0,5,2.238,5,5S357.762,337.5,355,337.5z" />
      <path d="M385,337.5h-30c-2.761,0-5-2.238-5-5v-56.69c0-2.762,2.239-5,5-5h30c2.762,0,5,2.238,5,5s-2.238,5-5,5h-25v46.69h25 c2.762,0,5,2.238,5,5S387.762,337.5,385,337.5z" />
      <path d="M415,337.5h-30c-2.761,0-5-2.238-5-5V190c0-2.761,2.239-5,5-5h30c2.762,0,5,2.239,5,5v142.5 C420,335.262,417.762,337.5,415,337.5z M390,327.5h20V195h-20V327.5z" />
      <path d="M207.5,245c-1.28,0-2.559-0.488-3.536-1.464c-1.953-1.953-1.953-5.119,0-7.071l67.5-67.5c1.953-1.952,5.118-1.952,7.071,0 L305,195.429l41.465-41.464c1.953-1.952,5.118-1.952,7.071,0c1.952,1.953,1.952,5.119,0,7.071l-45,45 c-1.953,1.952-5.118,1.952-7.071,0L275,179.571l-63.964,63.964C210.059,244.512,208.78,245,207.5,245z" />
      <path d="M350,182.5c-2.761,0-5-2.239-5-5v-15h-15c-2.761,0-5-2.239-5-5s2.239-5,5-5h20c2.762,0,5,2.239,5,5v20 C355,180.261,352.762,182.5,350,182.5z" />
      <path d="M173.161,366.57c-1.313,0-2.599-0.517-3.555-1.482c-1.374-1.389-1.814-3.45-1.126-5.278l25.979-69.069 c0.468-1.245,1.414-2.253,2.627-2.8c1.214-0.545,2.595-0.586,3.837-0.111c21.403,8.18,38.379,25.155,46.575,46.574 c0.479,1.252,0.435,2.644-0.121,3.862c-0.556,1.22-1.579,2.165-2.838,2.623l-69.669,25.38 C174.313,366.472,173.735,366.57,173.161,366.57z M201.988,299.134l-20.317,54.015l54.501-19.854 C229.141,318.261,217.015,306.144,201.988,299.134z" />
      <path d="M172.57,430.93c-37.258,0-67.57-30.32-67.57-67.59c0-14.818,4.74-28.844,13.708-40.562 c8.696-11.361,21.072-19.801,34.85-23.764c6.154-1.762,12.548-2.654,19.012-2.654c8.104,0,15.768,1.242,23.432,3.797 c2.62,0.873,4.035,3.705,3.162,6.324c-0.873,2.619-3.701,4.036-6.325,3.162c-6.627-2.209-13.257-3.283-20.269-3.283 c-5.532,0-11,0.763-16.253,2.267C131.992,315.623,115,338.123,115,363.34c0,31.755,25.826,57.59,57.57,57.59 c15.172,0,29.479-6.024,40.283-16.963c8.975-9.098,14.758-21.045,16.283-33.639c0.281-2.329,0.424-4.682,0.424-6.988 c0-8.128-1.239-14.979-3.683-20.363c-1.141-2.515-0.028-5.479,2.487-6.619c2.514-1.143,5.478-0.029,6.62,2.486 c3.037,6.691,4.577,14.933,4.577,24.496c0,2.706-0.167,5.461-0.496,8.188c-1.79,14.781-8.571,28.796-19.094,39.464 C207.27,423.85,190.437,430.93,172.57,430.93z" />
      <path d="M234.106,375.931c-0.252,0-0.508-0.02-0.765-0.059l-60.94-9.359c-1.882-0.289-3.437-1.622-4.01-3.438l-18.22-57.75 c-0.831-2.633,0.631-5.441,3.264-6.272c2.632-0.828,5.442,0.63,6.273,3.265l17.284,54.784l57.866,8.887 c2.729,0.42,4.603,2.972,4.183,5.701C238.663,374.161,236.533,375.931,234.106,375.931z" />
      <path d="M440,422.5H250c-2.761,0-5-2.238-5-5s2.239-5,5-5h190c2.762,0,5,2.238,5,5S442.762,422.5,440,422.5z" />
      <path d="M180,167.5h-60c-2.761,0-5-2.239-5-5s2.239-5,5-5h60c2.761,0,5,2.239,5,5S182.761,167.5,180,167.5z" />
      <path d="M180,187.5h-60c-2.761,0-5-2.239-5-5s2.239-5,5-5h60c2.761,0,5,2.239,5,5S182.761,187.5,180,187.5z" />
      <path d="M180,207.5h-60c-2.761,0-5-2.239-5-5s2.239-5,5-5h60c2.761,0,5,2.239,5,5S182.761,207.5,180,207.5z" />
      <path d="M180,227.5h-60c-2.761,0-5-2.239-5-5s2.239-5,5-5h60c2.761,0,5,2.239,5,5S182.761,227.5,180,227.5z" />
      <path d="M180,247.5h-60c-2.761,0-5-2.239-5-5s2.239-5,5-5h60c2.761,0,5,2.239,5,5S182.761,247.5,180,247.5z" />
    </svg>
  );
}

export default AnalyticsIcon;
