/** ingredient Item */
export const INIT_STOCK_ITEM = "INIT_STOCK_ITEM";
export const ADD_STOCK_ITEM = "ADD_STOCK_ITEM";
export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_REQUEST = "GET_ALL_PRODUCTS_REQUEST";
export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const ADD_NEW_UNIT = "ADD_NEW_UNIT";
export const ADD_NEW_BRAND = "ADD_NEW_BRAND";
export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";
export const STATEMENT_DATA = "STATEMENT_DATA";
export const SET_ALL_BRANDS = "SET_ALL_BRANDS";
export const SET_ALL_BRAND_UNIQUE = "SET_ALL_BRAND_UNIQUE";
export const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";
export const SET_ALL_UNITS = "SET_ALL_UNITS";
export const SET_ALL_SUPPLIERS = "SET_ALL_SUPPLIERS";
export const SET_ALL_SUPPLIER_PRODUCTS = "SET_ALL_SUPPLIER_PRODUCTS";
export const SET_ALL_PARTNER_PRODUCTS = "SET_ALL_PARTNER_PRODUCTS";
export const SET_ALL_PURCHASE_ORDERS = "SET_ALL_PURCHASE_ORDERS";
export const SET_ALL_MERCHANT_SALE_ORDERS = "SET_ALL_MERCHANT_SALE_ORDERS";
export const ADD_MERCHANT_SALE_ORDER = "ADD_MERCHANT_SALE_ORDER";
export const ADD_PURCHASE_ORDER = "ADD_PURCHASE_ORDER";
export const SET_ALL_SALE_ORDERS = "SET_ALL_SALE_ORDERS";
export const SET_ALL_DELIVERY_NOTES = "SET_ALL_DELIVERY_NOTES";
export const SET_ALL_INVENTORY_DN = "SET_ALL_INVENTORY_DN";
export const MONTHLY_ORDER_COUNT = "MONTHLY_ORDER_COUNT";
export const ADD_SALE_ORDER = "ADD_SALE_ORDER";
export const SET_ALL_DISTRIBUTOR_DELIVERY_NOTES =
  "SET_ALL_DISTRIBUTOR_DELIVERY_NOTES";
export const SET_ALL_Distibritor_PO = "SET_ALL_Distibritor_PO";
export const SET_ALL_PAYMENT_TERMS = "SET_ALL_PAYMENT_TERMS";
export const SET_ALL_COCOS = "SET_ALL_COCOS";
export const ADD_NEW_SUPPLIER = "ADD_NEW_SUPPLIER";
export const EDIT_SUPPLIER = "EDIT_SUPPLIER";
export const UPDATE_STOCK_ITEM = "UPDATE_STOCK_ITEM";
export const DELETE_STOCK_ITEM = "DELETE_STOCK_ITEM";
export const PAYMENT_CONFIRMATION_DATA = "PAYMENT_CONFIRMATION_DATA";
export const ADMIN_PAYMENT_CONFIRMATION = "ADMIN_PAYMENT_CONFIRMATION";
export const ALL_CREDITS_PAYMENT = "ALL_CREDITS_PAYMENT";

/** semi processed Item */
export const INIT_STOCK_SEMI_PROCESSED_ITEM = "INIT_STOCK_SEMI_PROCESSED_ITEM";
export const ADD_STOCK_SEMI_PROCESSED_ITEM = "ADD_STOCK_SEMI_PROCESSED_ITEM";
export const UPDATE_STOCK_SEMI_PROCESSED_ITEM =
  "UPDATE_STOCK_SEMI_PROCESSED_ITEM";
export const DELETE_STOCK_SEMI_PROCESSED_ITEM =
  "DELETE_STOCK_SEMI_PROCESSED_ITEM";
/** semi processed recipe */
export const INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE =
  "INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE";
export const RESET_STOCK_SEMI_PROCESSED_ITEM_RECIPE =
  "RESET_STOCK_SEMI_PROCESSED_ITEM_RECIPE";
/** inventory items */
export const INIT_STOCK_INVENTORY_ITEM = "INIT_STOCK_INVENTORY_ITEM";
/** iventory items recipe */
export const INIT_STOCK_ITEM_RECIPE = "INIT_STOCK_ITEM_RECIPE";
