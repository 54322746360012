import React from 'react';

function SubscriptionIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#cda1a7"
        d="M49,25h8a2,2,0,0,1,2,2V53a6,6,0,0,1-6,6h0a6,6,0,0,1-6-6V27a2,2,0,0,1,2-2Z"
      />
      <path
        fill="#faefde"
        d="M50,59H9a6,6,0,0,1-6-6V8A3,3,0,0,1,6,5H44a3,3,0,0,1,3,3V56Z"
      />
      <rect width="44" height="4" x="3" y="5" fill="#fff7f0" rx="2" ry="2" />
      <path fill="#efd8be" d="M47,49H3v4a6,6,0,0,0,6,6H50l-3-3Z" />
      <path fill="#72caaf" d="M51 39A12 12 0 1 0 51 63A12 12 0 1 0 51 39Z" />
      <path
        fill="#eb9697"
        d="M31 14h6a1 1 0 0 0 0-2H31a1 1 0 0 0 0 2zM11 14H27a1 1 0 0 0 0-2H11a1 1 0 0 0 0 2zM21 16H11a1 1 0 0 0 0 2H21a1 1 0 0 0 0-2z"
      />
      <path
        fill="#8d6c9f"
        d="M21 24H11a1 1 0 0 0 0 2H21a1 1 0 0 0 0-2zM21 28H11a1 1 0 0 0 0 2H21a1 1 0 0 0 0-2zM21 32H11a1 1 0 0 0 0 2H21a1 1 0 0 0 0-2zM21 36H11a1 1 0 0 0 0 2H21a1 1 0 0 0 0-2zM21 40H11a1 1 0 0 0 0 2H21a1 1 0 0 0 0-2zM37 24H27a1 1 0 0 0 0 2H37a1 1 0 0 0 0-2zM37 28H27a1 1 0 0 0 0 2H37a1 1 0 0 0 0-2zM37 32H27a1 1 0 0 0 0 2H37a1 1 0 0 0 0-2zM37 36H27a1 1 0 0 0 0 2H37a1 1 0 0 0 0-2z"
      />
      <path
        fill="#eb9697"
        d="M38,17a1,1,0,0,0-1-1H25a1,1,0,0,0,0,2H37A1,1,0,0,0,38,17Z"
      />
      <path
        fill="#8d6c9f"
        d="M10 52a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V53A1 1 0 0 0 10 52zM15 52a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V53A1 1 0 0 0 15 52zM20 52a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V53A1 1 0 0 0 20 52zM25 52a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V53A1 1 0 0 0 25 52zM30 52a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V53A1 1 0 0 0 30 52zM35 52a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V53A1 1 0 0 0 35 52z"
      />
      <path
        fill="#8d6c9f"
        d="M60,41.64V28a4,4,0,0,0-4-4H48V7a3,3,0,0,0-3-3H5A3,3,0,0,0,2,7V53a7,7,0,0,0,7,7H41.64A13,13,0,1,0,60,41.64ZM52.56,26A4,4,0,0,0,52,28v7a1,1,0,0,0,2,0V28a2,2,0,0,1,4,0V40.06a12.81,12.81,0,0,0-10-1.7V26ZM9,58a5,5,0,0,1-4-2,1,1,0,0,0,1-1V53a1,1,0,0,0-2,0V50H35a1,1,0,0,0,0-2H4V7A1,1,0,0,1,5,6H45a1,1,0,0,1,1,1V39a13,13,0,0,0-5.94,19Zm42,4A11,11,0,1,1,62,51,11,11,0,0,1,51,62Z"
      />
      <path
        fill="#faefde"
        d="M60.71,50.29a1,1,0,0,0-1.41,0L58,51.63c0-.21,0-.42,0-.63a7,7,0,1,0-7,7,1,1,0,0,0,0-2,5.09,5.09,0,1,1,5-4.45l-1.25-1.25a1,1,0,0,0-1.41,1.41l3,3a1,1,0,0,0,1.41,0l3-3A1,1,0,0,0,60.71,50.29Z"
      />
    </svg>
  );
}

export default SubscriptionIcon;
