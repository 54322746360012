import * as actionTypes from "../actionTypes";

let initialState = {
  myTickets: {
    result: [],
    __metadata: {}
  },
  partnerMyTickets: {
    result: [],
    __metadata: {}
  },
  allTickets: {
    result: [],
    __metadata: {}
  },
  assignedTickets: {
    result: [],
    __metadata: {}
  },
  viewTicket: '',
  ticketDetails: {},
  ticketOverview: [],
  delayedReports: [],
};

export const ticketReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MY_TICKETS:
      return {
        ...state,
        myTickets: action.payload,
      };
    case actionTypes.UPDATE_MY_TICKETS_PAGINATION:
      const tempMyTickets = { ...state.myTickets };
      tempMyTickets['__metadata'] = { ...action.payload.__metadata };
      if (tempMyTickets.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            tempMyTickets.result.push(res);
          });
      }
      return {
        ...state,
        myTickets: tempMyTickets
      }
    case actionTypes.SET_PARTNER_MY_TICKETS:
      return {
        ...state,
        partnerMyTickets: action.payload,
      };
    case actionTypes.UPDATE_PARTNER_MY_TICKETS_PAGINATION:
      const tempPartnerMyTickets = { ...state.partnerMyTickets };
      tempPartnerMyTickets['__metadata'] = { ...action.payload.__metadata };
      if (tempPartnerMyTickets.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            tempPartnerMyTickets.result.push(res);
          });
      }
      return {
        ...state,
        partnerMyTickets: tempPartnerMyTickets
      }
    case actionTypes.SET_ALL_TICKETS:
      return {
        ...state,
        allTickets: action.payload,
      };
    case actionTypes.UPDATE_ALL_TICKETS_PAGINATION:
      const tempAllTickets = { ...state.allTickets };
      tempAllTickets['__metadata'] = { ...action.payload.__metadata };
      if (tempAllTickets.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            tempAllTickets.result.push(res);
          });
      }
      return {
        ...state,
        allTickets: tempAllTickets
      }
    case actionTypes.UPDATE_ALL_TICKETS:
      const tempUpdateAllTickets = { ...state.allTickets };
      const updateAllTicketIndex = tempUpdateAllTickets.result.findIndex(a => a._id === action.payload._id)
      if (updateAllTicketIndex > -1) {
        tempUpdateAllTickets.result[updateAllTicketIndex] = { ...action.payload }
      }
      return {
        ...state,
        allTickets: tempUpdateAllTickets
      }
    case actionTypes.ADD_TO_MY_TICKETS:
      let addTickets = {};
      addTickets['result'] = [action.payload, ...state.myTickets.result];
      addTickets['__metadata'] = { ...state.myTickets.__metadata };
      return {
        ...state,
        myTickets: addTickets
      }
    case actionTypes.ADD_TO_PARTNER_MY_TICKETS:
      let addPartnerTickets = {};
      addPartnerTickets['result'] = [action.payload, ...state.partnerMyTickets.result];
      addPartnerTickets['__metadata'] = { ...state.partnerMyTickets.__metadata };
      return {
        ...state,
        partnerMyTickets: addPartnerTickets
      }
    case actionTypes.SET_ASSIGNED_TICKETS:
      return {
        ...state,
        assignedTickets: action.payload,
      };
    case actionTypes.UPDATE_ASSIGNED_TICKETS_PAGINATION:
      const tempAssignedTickets = { ...state.assignedTickets };
      tempAssignedTickets['__metadata'] = { ...action.payload.__metadata };
      if (tempAssignedTickets.result) {
        action.payload.result &&
          action.payload.result.length > 0 &&
          action.payload.result.forEach((res) => {
            tempAssignedTickets.result.push(res);
          });
      }
      return {
        ...state,
        assignedTickets: tempAssignedTickets
      }
    case actionTypes.SET_VIEW_TICKET:
      return {
        ...state,
        viewTicket: action.payload,
        ticketDetails: {},
      }
    case actionTypes.SET_TICKET_DETAILS:
      return {
        ...state,
        ticketDetails: action.payload
      }
    case actionTypes.SET_TICKETS_OVERVIEW:
      return {
        ...state,
        ticketOverview: action.payload
      }

    case actionTypes.SET_DELAYED_REPORTS:
      return {
        ...state,
        delayedReports: action.payload
      }
    case actionTypes.SET_MERCHANT_LOGOUT:
      return {
        myTickets: {
          result: [],
          __metadata: {}
        },
        partnerMyTickets: {
          result: [],
          __metadata: {}
        },
        allTickets: {
          result: [],
          __metadata: {}
        },
        assignedTickets: {
          result: [],
          __metadata: {}
        },
        viewTicket: '',
        ticketDetails: {},
        ticketOverview: []
      };
    default:
      return state;
  }
};
