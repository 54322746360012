import * as actionTypes from "../actionTypes";
import { toast } from "react-toastify";
import { employee_http } from "../../https/employee_http";
import { merchant_http } from "../../https/merchant_http";
import { API_URL } from "../../utils/constants";
import axios from "axios";
import fileDownload from "js-file-download";
import { user_http } from "../../https/user_http";

export const setMiscLoading = (data) => ({
  type: actionTypes.SET_MISC_LOADING,
  payload: data,
});

export const setEmployeeLoading = (data) => ({
  type: actionTypes.EMPLOYEE_LOADING,
  payload: data,
});

export const setMerchantLoading = (data) => ({
  type: actionTypes.MERCHANT_LOADING,
  payload: data,
});

export const setReloadAPI = (data) => ({
  type: actionTypes.SET_RELOAD_API,
  payload: data,
});

export const fetchBrandList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/brand`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_BRAND_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get brand data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchKPTRWT = (storeId, dateFrom, dateTo, channel) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/analytics/sale/average-kpt?start_date=${dateFrom}&end_date=${dateTo}&store_id=${storeId}${
          channel !== undefined ? `&channel=${channel}` : ""
        }`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_KPT_RWT,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(async () => {
        toast.error("Unable to get kpt data !");
      });
  };
};

export const pushMappingSync = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/brand/mapping/sync`, {})
      .then(async (res) => {
        toast.success(res.data?.message);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to sync !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadBrandMenu = (data, brandID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/brand/upload/${brandID}/menu`, data, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_BRAND_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to upload brand menu !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateBrandDetails = (
  brandID,
  data,
  cuisineList,
  applyToAll,
  cb
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let request = employee_http;
    let message = "";
    if (brandID) {
      request = request.put(
        `${API_URL}/brand/${brandID}?apply_to_all=${applyToAll}`,
        { data, cuisineList }
      );
      message = "Brand Updated!";
    } else {
      request = request.post(`${API_URL}/brand`, data);
      message = "Brand Created!";
    }
    request
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_BRAND_LIST,
          payload: res.data,
        });
        if (cb) {
          cb();
        }
        if (!brandID) {
          await dispatch(fetchBrandList());
        }
        await dispatch(setEmployeeLoading(false));
        toast.success(message);
      })
      .catch(() => {
        toast.error("Unable to update brand details !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchStateList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    axios
      .get(`${API_URL}/state`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STATE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get state list !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadStateGST = (data, stateID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/state/upload/${stateID}/gst`, data, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STATE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to upload GST certificate !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadStateFSSAI = (data, stateID, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/state/upload/${stateID}/fssai`, data, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STATE_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch(() => {
        toast.error("Unable to upload Fssai certificate !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchBannerList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    axios
      .get(`${API_URL}/banners`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_BANNER_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get banner list !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadNewBanner = (file, partnerId) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify({ store_id: partnerId }));
    employee_http
      .post(`${API_URL}/banners`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.ADD_NEW_BANNER_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to upload new banner !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateBannerStatus = (id, status) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/banners/${id}`, { active: status })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_BANNER_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to update banner !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const deleteBanner = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .delete(`${API_URL}/banners/${id}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.DELETE_BANNER,
          payload: id,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to delete banner !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchUploadStatus = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/upload-status`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_UPLOAD_STATUS_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get upload status list !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerNewsList = () => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/news`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_NEWS_LIST,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};
export const fetchEmployeeNewsList = () => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .get(`${API_URL}/news`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_NEWS_LIST,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchNewsList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/news/all`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_NEWS_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get news list !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createNewNews = (formData, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/news`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.ADD_NEW_NEWS,
          payload: res.data,
        });
        cb();
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to add news !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateNewsStatus = (id, status) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/news/${id}`, { active: status })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_NEWS_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to update news !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateNews = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/news/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_NEWS_LIST,
          payload: res.data,
        });
        cb();
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to update news !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const deleteNews = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .delete(`${API_URL}/news/${id}`)
      .then(async () => {
        await dispatch({
          type: actionTypes.DELETE_NEWS,
          payload: id,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to delete news !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchLearningResources = (isMerchant) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    (isMerchant ? merchant_http : employee_http)
      .get(`${API_URL}/learning-resources/`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_LEARNING_RESOURCES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch((e) => {
        console.log(e);
        toast.error("Unable to get learning resources !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateLearningResources = (localData, payload) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/learning-resources/`, payload)
      .then(async (res) => {
        dispatch({
          type: actionTypes.SET_LEARNING_RESOURCES,
          payload: localData,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch((e) => {
        console.log(e);
        toast.error("Unable to update learning resources !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchCOCLink = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/gk_emp/coc`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_COC,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("No employee coc has been uploaded previously!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadNewCOC = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/gk_emp/upload/coc`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_COC,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to upload new Employee COC !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchMailerDetails = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/mailer`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_AGGREGATOR_MAIL_DATA,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get aggregator mail !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchMailApprovalDetails = (status) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/aggregator-mail-requests?status=${status}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_AGGREGATOR_MAIL_APPROVAL_DATA,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get aggregator mail approval list!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const approveOrRejectMailApproval = (id, status, state) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/aggregator-mail-requests/${id}`, { status })
      .then(async () => {
        toast.success(`${status}`);
        await dispatch(setEmployeeLoading(false));
        await dispatch(fetchMailApprovalDetails(state));
      })
      .catch(() => {
        toast.error("Unable to get aggregator mail approval list!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createMailerDetails = (data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/mailer`, data)
      .then(async () => {
        cb();
        await dispatch(setEmployeeLoading(false));
        await dispatch(fetchMailerDetails());
      })
      .catch(() => {
        toast.error("Unable to get aggregator mail !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const downloadFoodCost = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/food-costing`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `food_cost_${new Date()}.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadFoodCost = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/food-costing`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async () => {
        toast.success("uploaded successfully");
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadBloggerOrder = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", data);
    employee_http
      .post(`${API_URL}/payout-schedules/blogger-food-details`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(res.data.message);

        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to upload excel !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const submitPettyCashPayment = (form, success) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    for (var key in form) {
      if (key === "documents" || key === "supportingDocuments") {
        if (form[key] && form[key].length > 0) {
          for (var x = 0; x < form[key].length; x++) {
            formData.append(key, form[key][x]);
          }
        }
      } else {
        formData.append(key, form[key]);
      }
    }
    employee_http
      .post(`${API_URL}/pettycash`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async () => {
        if (success) {
          success();
        }
        await dispatch(setEmployeeLoading(false));
        toast.success("Submission Success!");
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
        toast.error("Submission Failed!");
      });
  };
};

export const fetchPartnerPettyCashPayments = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/pettycash?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PETTY_CASH_PAYMENTS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to fetch the payments");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updatePettyCashPaymentStatus = (id, status, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/pettycash/${id}/status`, { status })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_PETTY_CASH_PAYMENTS,
          payload: res.data,
        });
        if (cb) {
          cb();
        }
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to update the payments");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadPettyCashPayments = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/pettycash/download?from=${from}&to=${to}`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `pettycash_${new Date()}.xlsx`);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadSaleDetails = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/sale/download?from=${from}&to=${to}`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `sales_${new Date()}.xlsx`);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEmpNotifications = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/notification/emp`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_NOTIFICATIONS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to fetch the notifications");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerNotifications = () => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/notification/partner`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_NOTIFICATIONS,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to fetch the notifications");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const markEmpNotificationAsRead = (ID, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/notification/emp/${ID}/read`, {})
      .then(async (res) => {
        if (cb) {
          cb();
        }
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to mark notification as read");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const markPartnerNotificationAsRead = (ID, cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}/notification/partner/${ID}/read`, {})
      .then(async (res) => {
        if (cb) {
          cb();
        }
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to mark notification as read");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const getTicketCategory = () => {
  return async (dispatch) => {
    // console.log("From Redux--  ", );
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/ticket-category/allCategory`
      );
      await dispatch({
        type: actionTypes.SET_TICKET_CATEGORY,
        payload: res.data,
      });
      await dispatch(setEmployeeLoading(false));
    } catch (e) {
      console.log(e);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const editTicketCategory = (body) => {
  return (dispatch) => {
    // console.log("From Redux--  ", );
    dispatch(setEmployeeLoading(true));
    try {
      employee_http
        .post(`${API_URL}/ticket-category/edit`, body)
        .then(async (res) => {
          await dispatch({
            type: actionTypes.SET_TICKET_CATEGORY,
            payload: res.data,
          });
          await dispatch(setEmployeeLoading(false));
        });
    } catch (e) {
      console.log(e);
      toast.error("Unable to edit !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const editTicketCategorySub = (body) => {
  return (dispatch) => {
    // console.log("From Redux--  ", );
    dispatch(setEmployeeLoading(true));
    try {
      employee_http
        .post(`${API_URL}/ticket-category/edit/sub`, body)
        .then(async (res) => {
          await dispatch({
            type: actionTypes.SET_TICKET_CATEGORY,
            payload: res.data,
          });
          await dispatch(setEmployeeLoading(false));
        });
    } catch (e) {
      console.log(e);
      toast.error("Unable to edit !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const deleteTicketCategory = (body) => {
  return (dispatch) => {
    // console.log("From Redux--  ", );
    dispatch(setEmployeeLoading(true));
    try {
      employee_http
        .post(`${API_URL}/ticket-category/delete`, body)
        .then(async (res) => {
          await dispatch({
            type: actionTypes.SET_TICKET_CATEGORY,
            payload: res.data,
          });
          await dispatch(setEmployeeLoading(false));
        });
    } catch (e) {
      console.log(e);
      toast.error("Unable to edit !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const deleteTicketCategorySub = (body) => {
  return (dispatch) => {
    // console.log("From Redux--  ", );
    dispatch(setEmployeeLoading(true));
    try {
      employee_http
        .post(`${API_URL}/ticket-category/delete/sub`, body)
        .then(async (res) => {
          await dispatch({
            type: actionTypes.SET_TICKET_CATEGORY,
            payload: res.data,
          });
          await dispatch(setEmployeeLoading(false));
        });
    } catch (e) {
      console.log(e);
      toast.error("Unable to edit !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const getTicketCategoryMerchant = () => {
  return async (dispatch) => {
    // console.log("From Redux--  ", );
    dispatch(setEmployeeLoading(true));
    try {
      const res = await merchant_http.post(
        `${API_URL}/ticket-category/allCategory`
      );
      await dispatch({
        type: actionTypes.SET_TICKET_CATEGORY,
        payload: res.data,
      });
      await dispatch(setEmployeeLoading(false));
    } catch (e) {
      console.log(e);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const addTicketCategory = (role, categoryName) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const response = await employee_http.post(
        `${API_URL}/ticket-category/addCategory`,
        { role, categoryName }
      );
      dispatch({
        type: actionTypes.SET_TICKET_CATEGORY,
        payload: response.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const addTicketSubCategory = (role, categoryName, subcategoryName) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      console.log(role, categoryName);
      const response = await employee_http.post(
        `${API_URL}/ticket-category/addSubCategotry`,
        { role, categoryName, subcategoryName }
      );
      dispatch({
        type: actionTypes.SET_TICKET_CATEGORY,
        payload: response.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const deleteLearningResource = (localData, payload) => {
  // console.log(localData);
  return (dispatch) => {
    console.log(payload);
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/learning-resources/delete`, payload)
      .then(async (res) => {
        dispatch({
          type: actionTypes.SET_LEARNING_RESOURCES,
          payload: localData,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch((e) => {
        console.log(e);
        toast.error("Unable to update learning resources !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const editLearningResource = (localData, payload) => {
  // console.log(localData);
  return (dispatch) => {
    console.log(payload);
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/learning-resources/edit`, payload)
      .then(async (res) => {
        dispatch({
          type: actionTypes.SET_LEARNING_RESOURCES,
          payload: localData,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch((e) => {
        console.log(e);
        toast.error("Unable to update learning resources !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const addJD = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/jd/add`, data)
      .then(async (res) => {
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to fetch the notifications");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getAllJDs = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/jd/get`)
      .then(async (res) => {
        console.log("helper-  ", res.data);
        await dispatch({
          type: actionTypes.GET_ALL_JD,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get upload status list !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const addTag = (tag) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const response = await employee_http.post(`${API_URL}/faq/addTag`, {
        tag,
      });
      dispatch({
        type: actionTypes.SET_ALL_TAGS,
        payload: response.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const getAllTag = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const response = await employee_http.get(`${API_URL}/faq/allTags`);
      dispatch({
        type: actionTypes.SET_ALL_TAGS,
        payload: response.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const createFAQ = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const response = await employee_http.post(`${API_URL}/faq/create`, {
        data,
      });
      dispatch({
        type: actionTypes.GET_ALL_FAQS,
        payload: response.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const getAllFAQ = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const response = await employee_http.get(`${API_URL}/faq/get`);
      dispatch({
        type: actionTypes.GET_ALL_FAQS,
        payload: response.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const editFAQ = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const response = await employee_http.post(`${API_URL}/faq/edit`, {
        data,
      });
      dispatch({
        type: actionTypes.GET_ALL_FAQS,
        payload: response.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const suggestFAQs = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const response = await employee_http.post(`${API_URL}/faq/suggest-faqs`, {
        data,
      });
      // dispatch({
      //   type: actionTypes.GET_SUGGEST_FAQS,
      //   payload: response.data,
      // });

      dispatch(setEmployeeLoading(false));
      return response.data;
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const addNewCuisine = (selected, changeName, number, email) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/brand/add-cuisine`, {
        selected,
        changeName,
        number,
        email,
      });
      dispatch({
        type: actionTypes.GET_ALL_CUISINES,
        payload: res.data,
      });
      toast.success("Successfully added !");
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const getAllCuisines = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/brand/get-cuisine`);
      dispatch({
        type: actionTypes.GET_ALL_CUISINES,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const removeNewCuisine = ({ id, cuisine }) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/brand/delete-cuisine`, {
        id,
        cuisine,
      });
      dispatch({
        type: actionTypes.GET_ALL_CUISINES,
        payload: res.data,
      });
      toast.success("Successfully added !");
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const getAllBloggerOrder = (date) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/payout-schedules/get/blogger-order`,
        { date }
      );
      dispatch({
        type: actionTypes.GET_ALL_BLOGGER_ORDERS,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const downloadBloggerOrderTemplate = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/analytics/sale/download/template`;

    employee_http
      .get(url, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `Sheet-1.xlsx`);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getAllLogs = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(`${API_URL}/logs/getAll`);
      dispatch({
        type: actionTypes.GET_ALL_LOGS,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const sendBrandAdditionRequest = (formData) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    employee_http
      .post(`${API_URL}/brand/update/brand`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async () => {
        await dispatch(setMerchantLoading(false));
        toast.success("Add Request");
      })
      .catch((error) => {
        toast.error(
          error.response.data
            ? error.response.data.message
            : "Unable to process !"
        );
        dispatch(setMerchantLoading(false));
      });
  };
};
export const getAllAddBrandRequests = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(`${API_URL}/brand/get/requests`);
      dispatch({
        type: actionTypes.GET_ADDITIONAL_BRAND_REQUEST,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const approveAllAddBrandRequests = (item) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/brand/approve/requests`,
        { item }
      );
      dispatch({
        type: actionTypes.GET_ADDITIONAL_BRAND_REQUEST,
        payload: res.data,
      });
      toast.success("Approved Successfully");
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const changeBrandStatus = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/brand/status`, { id: id })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_BRAND_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get brand data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const addPartnerBuckets = (selected, changeName, number, email) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/partner-buckets/add`, {
        selected,
        changeName,
        number,
        email,
      });
      dispatch({
        type: actionTypes.GET_ALL_PARTNER_BUCKET,
        payload: res.data,
      });
      toast.success("Successfully added !");
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const getPartnerBuckets = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(`${API_URL}/partner-buckets/get`);
      dispatch({
        type: actionTypes.GET_ALL_PARTNER_BUCKET,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      console.log(error);
      toast.error("Unable to add !");
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const getAllLeadGen = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(`${API_URL}/lead-gen/all-lead_gen`);
      dispatch({
        type: actionTypes.GET_ALL_LEAD_GEN,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const bulkApprove = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/lead-gen/action/approved`,
        data
      );
      dispatch({
        type: actionTypes.GET_ALL_LEAD_GEN,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const bulkReject = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/lead-gen/action/reject`,
        data
      );
      dispatch({
        type: actionTypes.GET_ALL_LEAD_GEN,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const getAllLeadGenCount = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(`${API_URL}/lead-gen/lead_gen/count`);
      dispatch({
        type: actionTypes.GET_ALL_LEAD_GEN_COUNT,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const getAllActionLeadGen = (id, action) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/lead-gen/lead_gen/action`,
        { id, action }
      );
      dispatch({
        type: actionTypes.GET_ALL_LEAD_GEN,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const getAllCounts = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/lead-gen/all/count`,
        data
      );
      dispatch({
        type: actionTypes.GET_ALL_LEAD_GEN_STATUS_COUNTS,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const downloadLeadGen = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/lead-gen/download`, {
        responseType: "blob",
      })
      .then(async (response) => {
        console.log("Response Content Type:", response.headers["content-type"]);
        console.log("Response Data:", response.data);
        fileDownload(response.data, "leadGen.csv", "text/csv");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getAllApprovedLeadGen = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(`${API_URL}/lead-gen/approved`);
      dispatch({
        type: actionTypes.GET_ALL_LEAD_GEN,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const leadGenPayment = (body) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/lead-gen/pay/cashback`,
        body
      );
      dispatch({
        type: actionTypes.GET_ALL_LEAD_GEN,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const leadGenBulkPayment = (body) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/lead-gen/bulk-pay/cashback`,
        body
      );
      dispatch({
        type: actionTypes.GET_ALL_LEAD_GEN,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const getAllReviews = (id) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/reviews/get/all`, id);
      dispatch({
        type: actionTypes.fetch_reviews,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const lastWeekReview = (id) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/reviews/weeklyRating`,
        id
      );
      dispatch({
        type: actionTypes.fetch_weekly_review,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const brandWiseReview = (id) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/reviews/brandwise`, id);
      dispatch({
        type: actionTypes.fetch_brandwise_review,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const trandingWords = (id) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/reviews/tranding/keywords`,
        id
      );
      dispatch({
        type: actionTypes.treanding_words,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const fetchComments = (id) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/reviews/allreviews`, id);
      dispatch({
        type: actionTypes.fetch_review_comments,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const fetchReviewsResIDWise = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(`${API_URL}/reviews/count/reviews`);
      dispatch({
        type: actionTypes.fetch_reviews_count,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const getAllGmailLogs = (pageNumber, data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/reviews/all/logs?page=${pageNumber}`,
        data
      );
      dispatch({
        type: actionTypes.fetch_gmail_logs,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const fetchAllReviews = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/reviews/fetch/reviews`,
        data
      );
      dispatch({
        type: actionTypes.fetch_gmail_logs,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const getLogDetails = (item) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/reviews/show/details`,
        item
      );
      dispatch({
        type: actionTypes.fetch_gmail_logs_details,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const checkDuplicateBioMetricCode = (code) => {
  return async (dispatch) => {
    // dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/reviews/check/biometriccode`,
        code
      );
      dispatch({
        type: actionTypes.check_bio_metric_code,
        payload: res.data,
      });
      // return res.data;
      // dispatch(setEmployeeLoading(false));
    } catch (error) {
      // dispatch(setEmployeeLoading(false));
    }
  };
};

export const fetchAllEMP = (name) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/store/find`, name);
      console.log(res);
      dispatch({
        type: actionTypes.FIND_ALL_EMPLOYEE,
        payload: res.data,
      });
      // return res.data;
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const addNewEmp = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/store/add/emp`, data);
      dispatch({
        type: actionTypes.FIND_ALL_EMPLOYEE,
        payload: res.data,
      });
      // return res.data;
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};
export const editEmpDetails = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/store/edit/emp`, data);
      dispatch({
        type: actionTypes.FIND_ALL_EMPLOYEE,
        payload: res.data,
      });
      // return res.data;
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const getSalesData = async (date) => {
  try {
    const data = await employee_http.post(
      `${API_URL}/sale/sales-dashboard`,
      {
        date: date,
      },
      {
        "Content-Type": "application/json",
      }
    );
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getSalesDataUploadSwiggy = async (date) => {
  try {
    const data = await employee_http.post(
      `${API_URL}/upload-swiggy-sales/sale-dashboard`,
      {
        date: date,
      },
      {
        "Content-Type": "application/json",
      }
    );
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const getOptiproSchedulers = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(
        `${API_URL}/optipro/schedulers/getall`
      );
      dispatch({
        type: actionTypes.FETCH_OPTIPRO_SCHEDULERS,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const updateOptiproSchedulers = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/optipro/schedulers/update/timings`,
        data
      );
      dispatch({
        type: actionTypes.FETCH_OPTIPRO_SCHEDULERS,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const fetchSchedulersForComfirmation = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(
        `${API_URL}/optipro/schedulers/need-confirmation`
      );
      console.log(res.data);
      dispatch({
        type: actionTypes.FETCH_OPTIPRO_SCHEDULERS_FOR_CONFIRMATION,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const confirmSchedulerChange = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/optipro/schedulers/change-timing`,
        data
      );
      console.log(res.data);
      dispatch({
        type: actionTypes.FETCH_OPTIPRO_SCHEDULERS_FOR_CONFIRMATION,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const rejectSchedulerChange = (data) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(
        `${API_URL}/optipro/schedulers/remove-new-timing`,
        data
      );
      console.log(res.data);
      dispatch({
        type: actionTypes.FETCH_OPTIPRO_SCHEDULERS_FOR_CONFIRMATION,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      dispatch(setEmployeeLoading(false));
    }
  };
};

// export const fetchBestSaleDates = () => {
//   return async (dispatch) => {
//     dispatch(setEmployeeLoading(true));
//     try {
//       const res = await employee_http.get(`${API_URL}/sale/best-sale-dates`);
//       dispatch({
//         type: actionTypes.FETCH_BEST_SALE_DATES,
//         payload: res.data,
//       });
//       dispatch(setEmployeeLoading(false));
//     } catch (error) {
//       dispatch(setEmployeeLoading(false));
//     }
//   };
// };

export const fetchBestSaleDates = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(false));
    let url = `${API_URL}/sale/best-sale-dates`;

    employee_http
      .post(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_BEST_SALE_DATES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const updateBestSaleDates = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sale/update/best-sale-dates`;

    employee_http
      .post(url, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_BEST_SALE_DATES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
