import axios from "axios";
import {push} from "connected-react-router";
import {API_URL} from "../../utils/constants";
import * as actionTypes from "../actionTypes";
import {setMerchantLoading} from "./miscActions";
import {merchant_http} from "../../https/merchant_http";

export const setMerchantLoginError = (err) => ({
  type: actionTypes.SET_MERCHANT_LOGIN_ERROR,
  payload: err,
});

export const merchantLogin = (user, pwd, redirectURL) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MERCHANT_LOGIN_LOADING, payload: true });
    setTimeout(() => {
      dispatch({ type: actionTypes.MERCHANT_LOGIN_LOADING, payload: false });
    }, 5000);
    axios
      .post(`${API_URL}/store/auth/login`, {
        username: user,
        password: pwd,
      })
      .then(async (res) => {
        await window.localStorage.setItem(
          "merchant_auth_token",
          res.data.token
        );
        await window.localStorage.setItem(
          "merchant_refresh_token",
          res.data.refreshToken
        );
        await dispatch({
          type: actionTypes.SET_MERCHANT_LOGIN_DATA,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.MERCHANT_LOGIN_LOADING,
          payload: false,
        });
        await dispatch(push(redirectURL));
      })
      .catch(() => {
        dispatch({ type: actionTypes.MERCHANT_LOGIN_LOADING, payload: false });
        dispatch(setMerchantLoginError(true));
      });
  };
};

export const merchantByPassLogin = (token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.MERCHANT_LOGIN_LOADING, payload: true });
    axios
      .post(
        `${API_URL}/store/app/login-by-pass`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        await window.localStorage.setItem(
          "merchant_auth_token",
          res.data.token
        );
        await window.localStorage.setItem("app_login", true);
        await window.localStorage.setItem(
          "merchant_refresh_token",
          res.data.refreshToken
        );
        await dispatch({
          type: actionTypes.SET_MERCHANT_LOGIN_DATA,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.MERCHANT_LOGIN_LOADING,
          payload: false,
        });
        await dispatch(push("/merchant/home"));
      })
      .catch(() => {
        dispatch({ type: actionTypes.MERCHANT_LOGIN_LOADING, payload: false });
        dispatch(push("/merchant-login"));
      });
  };
};

export const fetchMerchantDetails = () => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/store/my-details`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_DATA,
          payload: res.data,
        });
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const updateMerchantTimingDetails = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .put(`${API_URL}/store/my-details/timing`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_DATA,
          payload: res.data,
        });
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const updateMerchantAddressDetails = (data) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .put(`${API_URL}/store/my-details/address`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_DATA,
          payload: res.data,
        });
        dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const merchantLogout = () => {
  return async (dispatch) => {
    window.localStorage.removeItem("merchant_auth_token");
    window.localStorage.removeItem("merchant_refresh_token");
    await dispatch({ type: actionTypes.SET_MERCHANT_LOGOUT });
  };
};

export const distributorLogout = () => {
  return async (dispatch) => {
    window.localStorage.removeItem("distributor_auth_token");
    window.localStorage.removeItem("distributor_refresh_token");
    await dispatch({ type: actionTypes.SET_DISTRIBUTOR_LOGOUT });
  };
};
