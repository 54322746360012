import React from 'react';
import MerchantHeader from '../../components/MerchantHeader';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import MerchantAppBar from '../../components/MerchantAppBar';

const merchantHeaderHoc = (WrapComponent) => {
  function HocContent() {
    const theme = useTheme();
    const isLargeDevice = useMediaQuery(theme.breakpoints.up('sm'));
    return (
      <>
        {isLargeDevice ?
          <div>
            <MerchantAppBar />
          </div>
          :
          <MerchantHeader />
        }
        <div style={isLargeDevice ? { marginLeft: "100px", marginTop: "16px" } : {}}>
          <WrapComponent />
        </div>
      </>
    );
  }
  return HocContent;
};

export default merchantHeaderHoc;
