import * as actionTypes from "../actionTypes";

let initialState = {
  razorpayRecon: [],
  aggregatorRecon: [],
  cancelled: []
};

export const employeeReconReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEE_RAZORPAY_RECON:
      return {
        ...state,
        razorpayRecon: action.payload,
      };
    case actionTypes.SET_AGGREGATOR_RECON_LIST:
      return {
        ...state,
        aggregatorRecon: action.payload,
      };
    case actionTypes.SET_CANCELLED_LIST:
      return {
        ...state,
        cancelled: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        razorpayRecon: [],
        aggregatorRecon: [],
      };
    default:
      return state;
  }
};
