import {toast} from "react-toastify";
import {setMerchantLoading} from ".";
import {merchant_http} from "../../https/merchant_http";
import {API_URL} from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchMerchantPartnerPaymentLinks = (partnerID, from, to) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(
        `${API_URL}/partner-payments/link/${partnerID}?from=${from}&to=${to}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_PAYMENT_LINK,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const createPartnerPaymentOrder = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}//partner-payments/${id}/payment/order`, data)
      .then((res) => {
        if (cb) cb(res.data);
        dispatch(setMerchantLoading(false));
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Error while generating payment order. Please try again or contact administrator."
        );
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchPartnerPaidOnline = (id, from, to) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/partner-payments/link/${id}/all?&from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_PAID_ONLINE,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchMerchantFinanceReport = (from, to, reportType) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    dispatch({
      type: actionTypes.SET_MERCHANT_FINANCE_REPORT,
      payload: [],
    });
    merchant_http
      .get(
        `${API_URL}/finance-report?from=${from}&to=${to}&reportType=${reportType}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_FINANCE_REPORT,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
        dispatch({
          type: actionTypes.SET_MERCHANT_FINANCE_REPORT,
          payload: [],
        });
      });
  };
};

export const fetchMerchantTaxReport = (from, to) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    dispatch({
      type: actionTypes.SET_MERCHANT_TAX_REPORT,
      payload: [],
    });
    merchant_http
      .get(`${API_URL}/tax-report?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_TAX_REPORT,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
        dispatch({
          type: actionTypes.SET_MERCHANT_TAX_REPORT,
          payload: [],
        });
      });
  };
};

export const fetchMerchantAdjustmentReport = (from, to) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    dispatch({
      type: actionTypes.SET_MERCHANT_ADJUSTMENT_REPORT,
      payload: [],
    });
    merchant_http
      .get(`${API_URL}/adjustment-report?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_ADJUSTMENT_REPORT,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
        dispatch({
          type: actionTypes.SET_MERCHANT_ADJUSTMENT_REPORT,
          payload: [],
        });
      });
  };
};

export const fetchPartnerLedgerReport = () => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    dispatch({
      type: actionTypes.SET_MERCHANT_LEDGER_REPORT,
      payload: {},
    });
    merchant_http
      .get(`${API_URL}/qsr/partner`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_LEDGER_REPORT,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("No ledger details found!");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchPartnerLedgerSummary = () => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    dispatch({
      type: actionTypes.SET_MERCHANT_LEDGER_SUMMARY,
      payload: {},
    });
    merchant_http
      .get(`${API_URL}/qsr/partner/summary`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MERCHANT_LEDGER_SUMMARY,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setMerchantLoading(false));
      });
  };
};

export const createRupifiOrder = (id, cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}/partner-payments/${id}/rupifi/order`, {})
      .then((res) => {
        if (cb) {
          cb(res?.data?.order);
        }
        dispatch(setMerchantLoading(false));
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Error while generating rupifi order. Please try again or contact administrator."
        );
        dispatch(setMerchantLoading(false));
      });
  };
};

export const verifyRupifiOrder = (id, cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}/partner-payments/rupifi/verify`, {
        rupifi_order_id: id,
      })
      .then((res) => {
        if (cb) {
          cb();
        }
        dispatch(setMerchantLoading(false));
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "Error while verifying rupifi order. Please try again or contact administrator."
        );
        dispatch(setMerchantLoading(false));
      });
  };
};
