import * as actionTypes from "../actionTypes";
import { toast } from "react-toastify";
import { employee_http } from "../../https/employee_http";
import { setEmployeeLoading } from "./miscActions";
import { API_URL } from "../../utils/constants";


export const getSchedulerLogs = (from, to) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(`${API_URL}/scheduler-logs?from=${from}&to=${to}`);
      dispatch({
        type: actionTypes.SET_SCHEDULER_LOGS,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      toast.error("Unable to fetch logs !");
      dispatch(setEmployeeLoading(false));
    }
  };
};