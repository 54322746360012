import React from "react";
import { Redirect, Route } from "react-router";
import { useSelector } from "react-redux";

function LoginPageHoc({ component: Component, ...rest }) {
  const employeeID = useSelector((state) => state.employeeAuth.loginData?._id);
  const merchantID = useSelector((state) => state.merchantAuth.loginData?._id);
  const userID = useSelector((state) => state.userAuth.loginData?._id);
  console.log(userID);
  let url = "";

  switch (rest.path) {
    case "/employee-login":
      if (employeeID) url = "/employee/home";
      break;
    case "/merchant-login":
    case "/partner-app-login":
      if (merchantID) url = "/merchant/home";
      break;
    case "/distributor-login":
      if (merchantID) url = "/distributor/home";
      break;
    case "/user-login":
      if (userID) url = "/user/brands";
      break;
    default:
      break;
  }

  return (
    <Route
      {...rest}
      render={(prop) =>
        !url ? <Component {...prop} /> : <Redirect to={url} />
      }
    />
  );
}

export default LoginPageHoc;
