import React, { useState } from "react";
import { useStyles } from "./styles";
import MenuIcon from "../../assets/header/svgs/MenuIcon";
import IconButton from "@mui/material/IconButton";
import MenuDrawer from "./components/MenuDrawer";
import { push } from "connected-react-router";
import { useDispatch, useSelector } from "react-redux";
import HotMenu from "./components/HotMenu";

function MerchantHeader() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);

  const name = useSelector((state) => state.userAuth?.loginData?.name);

  return (
    <div className={classes.root}>
      <div className={classes.leftDiv}>
        <div className={classes.flexBox}>
          <IconButton onClick={() => setMenuOpen(true)}>
            <MenuIcon />
          </IconButton>
          <div
            className={classes.titleText}
            onClick={() => {
              if (
                window.location.pathname &&
                !window.location.pathname.includes("dashboard")
              ) {
                dispatch(push("/user/brands"));
              }
            }}
          >
            {name}
          </div>
        </div>
      </div>
      <div className={classes.rightDiv}>
        <HotMenu />
      </div>
      <MenuDrawer open={menuOpen} handleClose={() => setMenuOpen(false)} />
    </div>
  );
}

export default MerchantHeader;
