import * as actionTypes from "../actionTypes";

let initialState = {
  allLogs: [],
};

export const schedulerLogsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SCHEDULER_LOGS:
      return {
        ...state,
        allLogs: action.payload,
      };
    default:
      return state;
  }
};
