import * as actionTypes from "../actionTypes";

const initialState = {
  partnerGMVData: [],
  partnerRupifiStatement: []
};

export const rupifiReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PARTNER_GMV_DATA:
      return {
        ...state,
        partnerGMVData: action.payload,
      };
      case actionTypes.SET_PARTNER_RUPIFI_STATEMENT:
        return {
          ...state,
          partnerRupifiStatement: action.payload,
        };
  

    default:
      return state;
  }
};
