import * as actionTypes from "../actionTypes";
import { toast } from "react-toastify";
import { employee_http } from "../../https/employee_http";
import { setEmployeeLoading } from "./miscActions";
import { API_URL } from "../../utils/constants";


export const getAllFlags = () => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.get(`${API_URL}/flags`);
      dispatch({
        type: actionTypes.SET_FLAGS,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      toast.error("Unable to get flags !");
      dispatch(setEmployeeLoading(false));
    }
  };
};

export const updateFlag = (id, status) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.put(`${API_URL}/flags/${id}`, {status});
      dispatch({
        type: actionTypes.UPDATE_FLAG,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      toast.error("Unable to update the flag status !");
      dispatch(setEmployeeLoading(false));
    }
  };
};


export const createNewFlag = (name) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    try {
      const res = await employee_http.post(`${API_URL}/flags`, {name});
      dispatch({
        type: actionTypes.ADD_NEW_FLAG,
        payload: res.data,
      });
      dispatch(setEmployeeLoading(false));
    } catch (error) {
      toast.error("Unable to create the flag !");
      dispatch(setEmployeeLoading(false));
    }
  };
};