import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    height: 60,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    boxShadow: `0px 0px 3px ${theme.palette.text.secondary}`,
  },
  leftDiv: {
    paddingLeft: theme.spacing(6),
  },
  rightDiv: {
    paddingRight: theme.spacing(12),
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  titleText: {
    fontSize: 18,
    fontWeight: 600,
    marginLeft: theme.spacing(6),
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  drawerRoot: {
    width: theme.spacing(140),
  },
  iconFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    '& > span': {
      backgroundColor: theme.palette.baseColor,
      color: '#1F212B',
    },
  },
  radioGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  errorMessage: {
    marginTop: theme.spacing(6),
    fontSize: 12,
  },
  /** restInfo */
  restInfoRoot: {
    padding: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    marginBottom: theme.spacing(8),
  },
  restInfoFlex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  restInfoLogo: {
    overflow: 'hidden',
    height: 72,
    minWidth: 72,
    borderRadius: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
  },
  restInfoText: {
    paddingLeft: theme.spacing(8),
  },
  restTitle: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: theme.spacing(3),
    textTransform: 'capitalize',
  },
  restAddress: {
    fontSize: 12,
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
  },
  bottomArea: {
    position: 'relative',
    bottom: '0px',
    marginTop: 16,
    marginBottom: 16,
  },
  // menuList: {
  //   height: '70vh',
  //   overflowY: 'scroll',
  // },
  version: {
    marginRight: theme.spacing(8),
    color: theme.palette.text.secondary,
    fontSize: 12,
    textAlign: 'right',
  },
  /** language selector */
  langRoot: {
    padding: theme.spacing(8),
  },
  divider: {
    width: '100%',
    border: `0.5px solid ${theme.palette.text.secondary}`,
  },
  langFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  langText: {
    padding: theme.spacing(6),
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },
  langActiveText: {
    color: theme.palette.text.primary,
  },
}));
