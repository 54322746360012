import React from "react";
import {useStyles} from "../styles";
import RefreshIcon from "@mui/icons-material/SyncRounded";
import IconButton from "@mui/material/IconButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Tooltip from "@mui/material/Tooltip";
import {useDispatch, useSelector} from "react-redux";
import {merchantLogout, setReloadAPI} from '../../../redux/actions';

function HotMenu() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const route = useSelector((state) => state.router.location.pathname);

  return (
    <div className={classes.iconFlex}>
      {route !== "/merchant/home" && (
        <Tooltip title="Refresh">
          <IconButton onClick={() => dispatch(setReloadAPI(true))}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Logout">
        <IconButton onClick={() => dispatch(merchantLogout())}>
          <PowerSettingsNewIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default HotMenu;
