import * as actionTypes from "../actionTypes";

let initialState = {
  amList: [],
  approvalList: [],
  allList: [],
  storeList: [],
  channelList: [],
  fssaiStoreList: [],
  partnerPaymentList: [],
  partnerPaymentLinkList: [],
  storeBrandMapping: [],
  storeBrandZSUrl: [],
  salesSummary: {},
  salesInsights: [],
  storeStatusCount: [],
  outOfStock: [],
  payoutSchedules: [],
  partnerPayoutSchedules: [],
  hourlySaleData: [],
  ristaIntegrationStoreList: [],
  allStore: [],
  bloggerOrder: [],
  allCPCRequests: [],
  brandWisePayout: [],
  allBrands: [],
  allCPC: [],
  zsDailyLatestUpdate: [],
  techSupprot: [],
};

export const storeDetailsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STORE_AREA_MANAGER_LIST:
      return {
        ...state,
        amList: action.payload,
      };
    case actionTypes.SET_STORE_APPROVAL_LIST:
      return {
        ...state,
        approvalList: action.payload,
      };
    case actionTypes.DELETE_APPROVAL_LIST:
      return {
        ...state,
        approvalList: state.approvalList.filter(
          (l) => l._id !== action.payload
        ),
      };
    case actionTypes.UPDATE_STORE_APPROVAL_LIST:
      let update_approval_list = [...state.approvalList];
      const update_approval_list_index = update_approval_list.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_approval_list_index > -1)
        update_approval_list[update_approval_list_index] = action.payload;
      return {
        ...state,
        approvalList: update_approval_list,
      };
    case actionTypes.SET_ALL_STORE_LIST:
      return {
        ...state,
        allList: action.payload,
      };
    case actionTypes.SET_STORE_LIST:
      return {
        ...state,
        storeList: action.payload,
      };
    case actionTypes.SET_RISTA_INTEGRATION_STORE_LIST:
      return {
        ...state,
        ristaIntegrationStoreList: action.payload,
      };
    case actionTypes.SET_CHANNEL_LIST:
      return {
        ...state,
        channelList: action.payload,
      };
    case actionTypes.UPDATE_STORE_LIST:
      let update_store_list = [...state.storeList];
      const update_store_list_index = update_store_list.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_store_list_index > -1)
        update_store_list[update_store_list_index] = action.payload;
      return {
        ...state,
        storeList: update_store_list,
      };
    case actionTypes.SET_PARTNER_PAYMENT_LIST:
      return {
        ...state,
        partnerPaymentList: action.payload,
      };
    case actionTypes.UPDATE_PARTNER_PAYMENT_LIST:
      let update_partner_payment_list = [...state.partnerPaymentList];
      const update_partner_payment_list_index =
        update_partner_payment_list.findIndex(
          (a) => a._id === action.payload._id
        );
      if (update_partner_payment_list_index > -1)
        update_partner_payment_list[update_partner_payment_list_index] =
          action.payload;
      return {
        ...state,
        partnerPaymentList: update_partner_payment_list,
      };
    case actionTypes.SET_STORE_BRAND_MAPPING:
      return {
        ...state,
        storeBrandMapping: action.payload,
      };
    case actionTypes.UPDATE_STORE_BRAND_MAPPING:
      let update_store_brand_mapping = [...state.storeBrandMapping];
      const update_store_brand_mapping_index =
        update_store_brand_mapping.findIndex(
          (a) => a.brand_id === action.payload.brand_id
        );
      if (update_store_brand_mapping_index > -1)
        update_store_brand_mapping[update_store_brand_mapping_index] =
          action.payload;
      return {
        ...state,
        storeBrandMapping: update_store_brand_mapping,
      };
    case actionTypes.SET_STORE_BRAND_ZS_URL:
      return {
        ...state,
        storeBrandZSUrl: action.payload,
      };
    case actionTypes.UPDATE_STORE_BRAND_ZS_URL:
      let update_store_brand_zs_url = [...state.storeBrandZSUrl];
      const update_store_brand_zs_url_index =
        update_store_brand_zs_url.findIndex(
          (a) => a.brand_id === action.payload.brand_id
        );
      if (update_store_brand_zs_url_index > -1)
        update_store_brand_zs_url[update_store_brand_zs_url_index] =
          action.payload;
      return {
        ...state,
        storeBrandZSUrl: update_store_brand_zs_url,
      };
    case actionTypes.SET_PARTNER_PAYMENT_LINK_LIST:
      return {
        ...state,
        partnerPaymentLinkList: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_SALES_SUMMARY:
      return {
        ...state,
        salesSummary: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_SALES_INSIGHTS:
      return {
        ...state,
        salesInsights: action.payload,
      };
    case actionTypes.SET_STORE_STATUS_COUNT:
      return {
        ...state,
        storeStatusCount: action.payload,
      };
    case actionTypes.SET_RISTA_ITEMS_OUT_OF_STOCK:
      return {
        ...state,
        outOfStock: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_PAYOUT_SCHEDULES:
      return {
        ...state,
        payoutSchedules: action.payload,
      };
    case actionTypes.UPDATE_EMPLOYEE_PAYOUT_SCHEDULES:
      let payoutSchedulesUpdate = [...state.payoutSchedules];
      const payoutSchedulesUpdateIndex = payoutSchedulesUpdate.findIndex(
        (a) => a._id === action.payload._id
      );
      if (payoutSchedulesUpdateIndex > -1)
        payoutSchedulesUpdate[payoutSchedulesUpdateIndex] = action.payload;
      return {
        ...state,
        payoutSchedules: payoutSchedulesUpdate,
      };
    case actionTypes.SET_PARTNER_PAYOUT_SCHEDULES:
      return {
        ...state,
        partnerPayoutSchedules: action.payload,
      };
    case actionTypes.SET_BRAND_WISE_PAYOUT:
      return {
        ...state,
        brandWisePayout: action.payload,
      };
    case actionTypes.GET_ALL_BRAND_LIST:
      return {
        ...state,
        allBrands: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_HOURLY_SALES:
      return {
        ...state,
        hourlySaleData: action.payload,
      };
    case actionTypes.SET_GK_INVESTMENTS:
      return {
        ...state,
        gkInvestments: action.payload,
      };
    case actionTypes.SET_BLOGGER_ORDERS:
      return {
        ...state,
        bloggerOrders: action.payload,
      };
    case actionTypes.SET_ALL_REGIONS_SALES_SUMMARY:
      return {
        ...state,
        allRegionsSalesSummary: action.payload,
      };
    case actionTypes.SET_REGION_SALES_SUMMARY:
      return {
        ...state,
        regionSalesSummary: action.payload,
      };
    case actionTypes.SET_BRANCH_SALES_SUMMARY:
      return {
        ...state,
        branchSalesSummary: action.payload,
      };
    case actionTypes.SET_BRAND_SALES_SUMMARY:
      return {
        ...state,
        brandSalesSummary: action.payload,
      };
    case actionTypes.SET_CHANNEL_SALES_SUMMARY:
      return {
        ...state,
        channelSalesSummary: action.payload,
      };
    case actionTypes.SET_ALL_BRANCHES_SALES_SUMMARY:
      return {
        ...state,
        allBranchesSalesSummary: action.payload,
      };
    case actionTypes.SET_ALL_BRANDS_SALES_SUMMARY:
      return {
        ...state,
        allBrandsSalesSummary: action.payload,
      };
    case actionTypes.SET_ALL_CHANNELS_SALES_SUMMARY:
      return {
        ...state,
        allChannelsSalesSummary: action.payload,
      };
    case actionTypes.SET_PARTNER_INVOICES:
      return {
        ...state,
        partnerInvoices: action.payload,
      };
    case actionTypes.SET_PARTNER_PAYOUT_TRANSACTIONS:
      return {
        ...state,
        partnerPayoutTransactions: action.payload,
      };
    case actionTypes.SET_AREA_MANAGER_OVERVIEW:
      return {
        ...state,
        areaManagerOverview: action.payload,
      };
    case actionTypes.SET_AREA_MANAGER_REGION:
      return {
        ...state,
        areaManagerRegions: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_PAYOUT_TRANSACTIONS:
      return {
        ...state,
        employeePayoutTransactions: action.payload,
      };
    case actionTypes.SET_FSSAI_STORE_LIST:
      return {
        ...state,
        fssaiStoreList: action.payload,
      };
    case actionTypes.GET_ALL_CPC:
      return {
        ...state,
        allCPC: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_BLOGGER_ORDER:
      return {
        ...state,
        bloggerOrder: action.payload,
      };
    case actionTypes.GET_ALL_STORE:
      return {
        ...state,
        allStore: action.payload,
      };
    case actionTypes.GET_ALL_CPC_REQUESTS:
      return {
        ...state,
        allCPCRequests: action.payload,
      };
    case actionTypes.SET_ZS_DAILY_UPDATE:
      return {
        ...state,
        zsDailyLatestUpdate: action.payload,
      };
    case actionTypes.SET_TECH_SUPPROT:
      return {
        ...state,
        techSupprot: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        amList: [],
        approvalList: [],
        allList: [],
        storeList: [],
        fssaiStoreList: [],
        partnerPaymentList: [],
        partnerPaymentLinkList: [],
        storeBrandMapping: [],
        salesSummary: {},
        salesInsights: [],
        storeStatusCount: [],
        outOfStock: [],
        payoutSchedules: [],
        partnerPayoutSchedules: [],
        hourlySaleData: [],
        gkInvestments: [],
        bloggerOrders: [],
        allRegionsSalesSummary: [],
        regionSalesSummary: [],
        branchSalesSummary: [],
        brandSalesSummary: [],
        channelSalesSummary: [],
        allBranchesSalesSummary: [],
        allBrandsSalesSummary: [],
        allChannelsSalesSummary: [],
        partnerInvoices: [],
        partnerPayoutTransactions: [],
        areaManagerOverview: {},
        areaManagerRegions: [],
        employeePayoutTransactions: [],
        allCPCRequests: [],
        techSupprot: [],
      };
    default:
      return state;
  }
};
