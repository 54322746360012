import React from "react";
import {useStyles} from "../styles";
import {useSelector} from "react-redux";
import logo from "../../../assets/header/employee-profile.jpg";
import {Chip, Stack} from "@mui/material";

function EmployeeInfo() {
  const classes = useStyles();
  const employeeDetails = useSelector((state) => state.employeeAuth.loginData);

  return (
    <div className={classes.restInfoRoot}>
      <div className={classes.restInfoFlex}>
        <div className={classes.restInfoLogo}>
          <img src={logo} height={72} width={72} alt={""} />
        </div>
        <div className={classes.restInfoText}>
          <div className={classes.restTitle}>{employeeDetails?.name}</div>
          <div className={classes.restAddress}>
            <Stack direction="row" flexWrap={'wrap'}>
              {employeeDetails?.role.length > 0 &&
                employeeDetails?.role.map((role, index) => (
                  <Chip
                    key={index}
                    size={"small"}
                    style={{ fontSize: 9, textTransform: "uppercase", margin: 2 }}
                    label={role}
                    variant="outlined"
                  />
                ))}
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(EmployeeInfo);
