import React from 'react';

function GlideIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <polygon
        fill="#d6e5e5"
        points="5.167,17.71 18.208,17.042 32.503,17.335 39.792,16.746 42.687,17.417 42.5,39.375 40.642,41.333 28,42.208 12.461,42.308 8.155,41.353 5.167,37.875 5.756,24.555"
      />
      <path
        fill="#d03827"
        d="M41.23,8.71L38.5,7.5l-8,1h-21l-2.79,2.35L5.5,14.5l0.44,3.21c8.48-0.96,13.01-0.39,21.53-0.3 c1.53,0.01,3.06,0.05,4.59-0.04c2.58-0.16,5.14-0.66,7.73-0.62c0.84,0.01,1.7,0.08,2.44,0.46l0.27-4.71L41.23,8.71z M15.15,12.49 c-0.2,0.58-0.71,1.03-1.29,1.24c-0.58,0.22-1.22,0.2-1.82,0.04c-0.45-0.12-0.88-0.33-1.2-0.66c-0.32-0.33-0.52-0.79-0.47-1.25 c0.04-0.46,0.37-0.89,0.82-1l3.31-0.36C14.95,10.92,15.36,11.9,15.15,12.49z M26.74,12.89c-0.23,0.63-0.78,1.1-1.41,1.32 c-0.63,0.21-1.33,0.19-1.97,0.01c-0.83-0.23-1.62-0.75-1.94-1.54c-0.32-0.8,0.01-1.87,0.81-2.16l3.52,0.26 c0.22,0.02,0.41,0.13,0.58,0.28C26.82,11.5,26.96,12.27,26.74,12.89z M38.37,12.54c-0.18,0.15-0.38,0.26-0.59,0.36 c-0.69,0.32-1.48,0.5-2.23,0.34c-0.76-0.16-1.45-0.71-1.63-1.46c-0.19-0.74,0.26-1.63,1.01-1.8l2.88-0.03 c0.51,0.15,0.92,0.6,1.04,1.13C38.96,11.6,38.77,12.18,38.37,12.54z"
      />
      <path d="M17.779,43.065c-2.123,0-4.282-0.104-6.48-0.388c-1.343-0.173-2.748-0.438-3.927-1.192 c-1.357-0.87-2.287-2.345-2.485-3.944c-0.089-0.708-0.035-1.423,0.012-2.053c0.21-2.793,0.477-10.736,0.303-13.724 c-0.027-0.471-0.066-0.987-0.106-1.529c-0.155-2.078-0.331-4.433-0.072-6.215C5.328,11.914,6.507,9.322,8.9,8.366 c1.165-0.464,2.439-0.466,3.563-0.468c3.16-0.003,5.746,0.001,8.332,0.005s5.174,0.008,8.328,0.005l0.036,0.001 c1.512,0.109,3.915,0.041,5.61-0.358l0.664-0.164c1.718-0.43,3.666-0.919,5.249,0.118c0.909,0.594,1.565,1.649,1.949,3.138 c0.579,2.247,0.476,4.585,0.377,6.847l-0.028,0.655c-0.158,3.837-0.109,13.767,0.145,17.601c0.104,1.558,0.245,3.691-1.043,5.13 c-1.419,1.587-3.853,1.518-5.623,1.471c-3.551-0.1-6.993,0.131-10.323,0.353C23.407,42.88,20.625,43.065,17.779,43.065z M15.025,8.896c-0.815,0-1.665,0.001-2.56,0.002C11.381,8.9,10.259,8.902,9.271,9.296c-1.997,0.797-2.993,3.038-3.258,4.868 c-0.243,1.674-0.072,3.971,0.079,5.997c0.041,0.548,0.08,1.069,0.107,1.545c0.177,3.023-0.092,11.041-0.303,13.856 c-0.046,0.612-0.094,1.245-0.018,1.854c0.162,1.309,0.922,2.515,2.032,3.227c1.013,0.648,2.288,0.884,3.516,1.042 c5.047,0.654,9.707,0.344,14.644,0.015c3.351-0.223,6.818-0.455,10.417-0.354c1.668,0.049,3.741,0.104,4.851-1.137 c0.932-1.041,0.909-2.607,0.79-4.397c-0.256-3.859-0.306-13.85-0.146-17.708l0.029-0.658c0.096-2.188,0.195-4.45-0.347-6.553 c-0.319-1.239-0.833-2.098-1.527-2.551c-1.219-0.799-2.866-0.386-4.459,0.016l-0.677,0.166c-1.789,0.424-4.3,0.498-5.894,0.385 c-3.152,0.003-5.732-0.001-8.313-0.005C18.941,8.9,17.089,8.896,15.025,8.896z" />
      <path
        fill="#febf10"
        d="M25.342,5.935c-0.833-0.277-1.751-0.42-2.589-0.155c-0.12,0.038-0.243,0.084-0.334,0.173 c-0.158,0.154-0.181,0.396-0.194,0.617c-0.052,0.937-0.076,1.875-0.072,2.814c0.002,0.506,0.013,1.017,0.137,1.507 c0.058,0.231,0.144,0.462,0.3,0.642c0.267,0.309,0.69,0.423,1.087,0.521c0.363,0.09,0.756,0.177,1.1,0.03 c0.497-0.212,0.681-0.813,0.764-1.347c0.182-1.172,0.156-2.365,0.129-3.55C25.663,6.788,25.636,6.205,25.342,5.935z"
      />
      <path d="M24.359,12.662c-0.301,0-0.584-0.068-0.8-0.122c-0.409-0.102-0.968-0.24-1.347-0.681c-0.243-0.279-0.349-0.616-0.406-0.845 c-0.137-0.546-0.15-1.104-0.152-1.629c-0.005-0.933,0.02-1.889,0.072-2.843c0.013-0.228,0.037-0.648,0.345-0.948 c0.162-0.159,0.359-0.235,0.53-0.29c0.822-0.262,1.796-0.209,2.898,0.156l0.179,0.105c0.464,0.424,0.482,1.194,0.493,1.608 c0.026,1.189,0.054,2.417-0.136,3.639c-0.053,0.339-0.213,1.369-1.062,1.731C24.771,12.632,24.561,12.662,24.359,12.662z M23.709,6.141c-0.291,0-0.56,0.039-0.805,0.117c-0.098,0.031-0.128,0.048-0.138,0.055c-0.017,0.026-0.032,0.124-0.042,0.286 c-0.052,0.934-0.075,1.869-0.07,2.782c0.002,0.484,0.013,0.953,0.122,1.39c0.052,0.204,0.113,0.343,0.192,0.435 c0.16,0.186,0.471,0.275,0.831,0.364c0.317,0.078,0.597,0.135,0.782,0.056c0.225-0.096,0.381-0.42,0.466-0.965 c0.176-1.134,0.149-2.317,0.124-3.462c-0.01-0.419-0.051-0.697-0.122-0.831C24.563,6.216,24.114,6.141,23.709,6.141z" />
      <path
        fill="#febf10"
        d="M36.516,5.484c-0.526-0.071-1.165-0.176-1.68-0.049c-0.133,0.033-0.269,0.075-0.368,0.17 c-0.209,0.201-0.166,0.54-0.108,0.824c0.267,1.295,0.534,2.591,0.8,3.886c0.027,0.132,0.055,0.267,0.121,0.385 c0.186,0.335,0.607,0.445,0.984,0.512c0.19,0.034,0.393,0.063,0.57-0.013c0.2-0.086,0.323-0.288,0.403-0.49 c0.202-0.513,0.198-1.081,0.192-1.632c-0.009-0.784-0.019-1.568-0.028-2.352c-0.004-0.297-0.009-0.604-0.13-0.876 S36.812,5.465,36.516,5.484z"
      />
      <path d="M36.574,11.749c-0.154,0-0.293-0.024-0.396-0.043c-0.305-0.054-1.014-0.181-1.336-0.763 c-0.105-0.19-0.145-0.386-0.173-0.529l-0.8-3.884c-0.055-0.261-0.18-0.871,0.25-1.285c0.182-0.174,0.402-0.247,0.594-0.295 c0.572-0.14,1.226-0.05,1.75,0.022l0.075,0.01c0.454,0,0.976,0.186,1.189,0.664c0.163,0.371,0.169,0.761,0.173,1.073l0.027,2.353 c0.007,0.566,0.015,1.209-0.227,1.821c-0.151,0.384-0.377,0.641-0.671,0.767C36.876,11.728,36.718,11.749,36.574,11.749z M35.395,5.876c-0.158,0-0.308,0.013-0.44,0.045c-0.103,0.026-0.135,0.042-0.146,0.049c0.005,0.003-0.017,0.08,0.04,0.359 l0.801,3.886c0.018,0.087,0.038,0.19,0.068,0.244c0.092,0.166,0.486,0.236,0.635,0.263c0.063,0.012,0.236,0.042,0.283,0.021 c0.002-0.001,0.063-0.028,0.137-0.216c0.169-0.429,0.162-0.943,0.156-1.442l-0.027-2.352c-0.003-0.244-0.007-0.497-0.087-0.68 c-0.01-0.021-0.122-0.083-0.266-0.069l-0.051,0.003l-0.171-0.023C36.023,5.921,35.694,5.876,35.395,5.876z" />
      <path
        fill="#febf10"
        d="M14.5,5.5c-0.813-0.042-1.619-0.221-2.399,0.001c-0.322,0.092-0.575,0.702-0.614,0.819 c-0.346,1.036-0.34,2.158-0.225,3.245c0.074,0.691,0.207,1.415,0.657,1.945c0.45,0.53,1.312,0.767,1.854,0.331 c0.414-0.333,0.507-0.917,0.554-1.446c0.109-1.24,0.162-1.65,0.174-2.895C14.503,7.185,14.814,5.516,14.5,5.5z"
      />
      <path d="M13.101,12.568c-0.589,0-1.188-0.294-1.563-0.735c-0.539-0.635-0.693-1.476-0.772-2.215 c-0.149-1.405-0.07-2.503,0.247-3.456c0.033-0.099,0.339-0.967,0.953-1.142c0.672-0.19,1.326-0.129,1.959-0.068 c0.2,0.019,0.4,0.038,0.602,0.049l0.218,0.011l0.204,0.201c0.259,0.309,0.226,0.842,0.095,1.901 C15.021,7.292,15.001,7.436,15,7.505c-0.011,1.097-0.052,1.557-0.135,2.474l-0.041,0.461c-0.037,0.418-0.114,1.288-0.738,1.791 C13.794,12.466,13.449,12.568,13.101,12.568z M12.972,5.893c-0.254,0-0.499,0.022-0.733,0.089 c-0.063,0.047-0.228,0.347-0.277,0.497c-0.271,0.813-0.335,1.776-0.202,3.033c0.066,0.617,0.178,1.247,0.541,1.674 c0.279,0.331,0.847,0.518,1.159,0.266c0.257-0.207,0.329-0.651,0.369-1.1l0.041-0.463C13.95,8.998,13.99,8.552,14,7.495 c0.001-0.089,0.021-0.274,0.05-0.503c0.029-0.237,0.088-0.718,0.092-1.016c-0.104-0.009-0.209-0.02-0.313-0.029 C13.534,5.92,13.247,5.893,12.972,5.893z" />
      <path d="M12.928,14.393c-0.332,0-0.675-0.046-1.02-0.14c-0.591-0.16-1.07-0.427-1.426-0.791c-0.447-0.458-0.677-1.077-0.614-1.655 c0.073-0.69,0.57-1.279,1.209-1.433l0.232,0.973c-0.227,0.054-0.419,0.298-0.447,0.566c-0.03,0.281,0.098,0.606,0.335,0.85 c0.23,0.235,0.558,0.412,0.973,0.525c0.537,0.146,1.078,0.137,1.522-0.024c0.472-0.173,0.841-0.525,0.988-0.943 c0.118-0.337-0.161-1.113-0.524-1.457l0.688-0.727c0.591,0.559,1.057,1.729,0.78,2.516c-0.245,0.697-0.839,1.276-1.589,1.551 C13.688,14.329,13.314,14.393,12.928,14.393z" />
      <path d="M24.365,14.862c-0.369,0-0.751-0.054-1.137-0.159c-1.098-0.301-1.928-0.972-2.274-1.841 c-0.223-0.558-0.206-1.226,0.045-1.788c0.221-0.491,0.598-0.855,1.063-1.025l0.342,0.939c-0.208,0.076-0.383,0.252-0.491,0.494 c-0.141,0.315-0.152,0.702-0.03,1.009c0.228,0.566,0.829,1.033,1.611,1.247c0.42,0.116,1.052,0.208,1.671-0.003 c0.517-0.178,0.938-0.566,1.103-1.016c0.165-0.456,0.049-1-0.275-1.292c-0.1-0.09-0.204-0.145-0.295-0.154l0.113-0.994 c0.301,0.034,0.595,0.175,0.851,0.405c0.624,0.562,0.85,1.539,0.546,2.376c-0.269,0.738-0.91,1.344-1.718,1.62 C25.137,14.802,24.759,14.862,24.365,14.862z" />
      <path d="M36.124,13.801c-0.234,0-0.464-0.023-0.684-0.07c-0.984-0.212-1.792-0.946-2.009-1.828 c-0.129-0.523-0.033-1.099,0.262-1.576c0.269-0.434,0.669-0.729,1.128-0.833l0.221,0.975c-0.195,0.044-0.372,0.181-0.498,0.384 c-0.152,0.248-0.206,0.551-0.142,0.813c0.128,0.519,0.629,0.956,1.247,1.088c0.713,0.152,1.439-0.082,1.922-0.307 c0.211-0.099,0.354-0.185,0.464-0.281c0.267-0.236,0.396-0.63,0.321-0.979c-0.076-0.35-0.355-0.655-0.697-0.761l0.295-0.955 c0.687,0.212,1.228,0.803,1.379,1.505c0.152,0.702-0.098,1.464-0.637,1.94c-0.237,0.208-0.5,0.344-0.703,0.438 C37.354,13.649,36.72,13.801,36.124,13.801z" />
      <path d="M6,18.205l-0.111-0.994c6.253-0.704,10.328-0.585,15.489-0.438c1.851,0.054,3.835,0.11,6.102,0.134l0.549,0.006 c1.314,0.016,2.673,0.03,3.997-0.047c1.006-0.06,2.031-0.175,3.023-0.287c1.549-0.175,3.152-0.353,4.75-0.333 c0.831,0.012,1.797,0.082,2.658,0.513l-0.447,0.895c-0.681-0.341-1.507-0.397-2.225-0.407c-1.541-0.015-3.106,0.155-4.624,0.326 c-1.005,0.114-2.045,0.231-3.077,0.292c-1.359,0.081-2.735,0.065-4.066,0.049l-0.548-0.006c-2.274-0.023-4.264-0.081-6.121-0.135 C16.228,17.628,12.183,17.509,6,18.205z" />
      <path d="M14.914,26.011c-0.751,0-1.502-0.01-2.249-0.031c-0.135-0.004-0.263-0.063-0.354-0.163c-0.091-0.1-0.138-0.232-0.13-0.367 l0.054-0.818c0.071-1.061,0.146-2.157,0.116-3.164c-0.006-0.225,0.137-0.425,0.351-0.492c0.79-0.246,1.941-0.563,3.046-0.557 c0.326,0.005,0.653,0.026,0.979,0.049c0.627,0.043,1.217,0.082,1.79-0.002c0.14-0.021,0.28,0.019,0.391,0.107 c0.108,0.09,0.175,0.221,0.182,0.361l0.207,4.137c0.005,0.106,0.013,0.296-0.096,0.482c-0.136,0.237-0.387,0.365-0.743,0.38 C17.283,25.984,16.098,26.011,14.914,26.011z M13.213,24.993c1.687,0.035,3.391,0.02,5.074-0.055l-0.171-3.433 c-0.495,0.024-0.98-0.009-1.457-0.041c-0.309-0.021-0.618-0.043-0.927-0.047c-0.785,0.003-1.681,0.2-2.376,0.403 c0.006,0.94-0.061,1.923-0.125,2.877L13.213,24.993z" />
      <path d="M27.542,25.219c-0.007,0-0.014,0-0.021,0c-0.538-0.023-1.043-0.05-1.533-0.076c-1.144-0.061-2.222-0.117-3.485-0.111 c0,0-0.001,0-0.002,0c-0.261,0-0.479-0.201-0.498-0.462c-0.054-0.691-0.064-1.389-0.075-2.085 c-0.011-0.678-0.021-1.354-0.072-2.025c-0.021-0.257,0.159-0.487,0.413-0.531c1.221-0.208,2.479-0.113,3.696-0.022 c0.535,0.041,1.067,0.081,1.591,0.095c0.272,0.007,0.489,0.23,0.487,0.503c-0.003,0.539-0.056,1.032-0.105,1.51 c-0.096,0.905-0.179,1.688,0.084,2.563c0.047,0.154,0.016,0.322-0.084,0.45C27.842,25.147,27.696,25.219,27.542,25.219z M22.969,24.032c1.091,0.007,2.057,0.059,3.071,0.112c0.284,0.015,0.573,0.03,0.871,0.045c-0.125-0.805-0.046-1.553,0.03-2.281 c0.032-0.303,0.065-0.611,0.084-0.929c-0.377-0.02-0.756-0.048-1.138-0.077c-1.003-0.076-2.035-0.155-3.007-0.05 c0.028,0.536,0.037,1.076,0.045,1.615C22.935,22.99,22.942,23.513,22.969,24.032z" />
      <path d="M35.995,25.532c-0.046,0-0.084-0.002-0.113-0.004c-0.484-0.028-0.972-0.087-1.458-0.146 c-1.024-0.125-1.992-0.244-2.936-0.069c-0.239,0.036-0.465-0.085-0.554-0.302c-0.626-1.525-0.909-2.983-0.841-4.335 c0.013-0.257,0.218-0.462,0.475-0.474c1.685-0.081,3.288-0.061,4.771-0.028c0.126,0.003,0.392,0.008,0.612,0.198 c0.269,0.229,0.279,0.571,0.284,0.717c0.038,1.097,0.214,2.187,0.521,3.236c0.133,0.454,0.064,0.799-0.202,1.023 C36.363,25.507,36.144,25.532,35.995,25.532z M32.52,24.226c0.685,0,1.362,0.083,2.025,0.163c0.409,0.051,0.818,0.101,1.228,0.13 c-0.311-1.086-0.49-2.211-0.534-3.345c-1.343-0.032-2.724-0.047-4.154,0.007c0.015,0.97,0.228,2.004,0.634,3.088 C31.985,24.238,32.253,24.226,32.52,24.226z" />
      <path d="M17.273,32.096c-0.563,0-1.139-0.008-1.713-0.015c-0.985-0.012-1.971-0.024-2.9,0c-0.005,0-0.01,0-0.014,0 c-0.236,0-0.441-0.166-0.489-0.399c-0.218-1.057-0.13-1.851-0.027-2.77c0.025-0.224,0.052-0.457,0.074-0.707 c0.001-0.004,0.001-0.008,0.001-0.012c0.052-0.445,0.536-0.863,0.997-0.863c0.024,0.006,0.04,0,0.06,0 c0.571,0,1.164-0.023,1.763-0.048c1.242-0.049,2.529-0.101,3.734,0.052c0.213,0.027,0.385,0.187,0.428,0.396 c0.278,1.376,0.03,2.682-0.209,3.943c-0.044,0.231-0.243,0.4-0.478,0.407C18.1,32.092,17.689,32.096,17.273,32.096z M13.859,31.066 c0.563,0,1.139,0.008,1.713,0.015c0.844,0.01,1.687,0.021,2.498,0.009c0.171-0.925,0.313-1.87,0.194-2.804 c-1.023-0.091-2.128-0.048-3.2-0.005c-0.633,0.025-1.285,0.047-1.862,0.049l-0.005-0.001c-0.023,0.244-0.049,0.474-0.073,0.694 c-0.082,0.733-0.149,1.336-0.057,2.049C13.328,31.068,13.593,31.066,13.859,31.066z" />
      <path d="M25.271,32.081c-0.854,0-1.708-0.02-2.554-0.06c-0.226-0.01-0.416-0.17-0.465-0.391c-0.213-0.959-0.274-2.032-0.333-3.071 c-0.021-0.351-0.04-0.693-0.064-1.021c-0.012-0.144,0.04-0.286,0.142-0.389c0.101-0.103,0.229-0.163,0.387-0.148 c0.914,0.055,1.763,0.092,2.611,0.128c0.853,0.037,1.706,0.073,2.627,0.129c0.258,0.016,0.462,0.226,0.47,0.483l0.102,3.309 c0.005,0.146,0.019,0.538-0.286,0.796c-0.22,0.183-0.478,0.192-0.602,0.197C26.629,32.068,25.949,32.081,25.271,32.081z M23.154,31.04c1.338,0.054,2.694,0.055,4.037,0.007l-0.086-2.816c-0.744-0.041-1.449-0.071-2.155-0.102 c-0.676-0.029-1.352-0.059-2.061-0.097c0.01,0.155,0.019,0.312,0.027,0.471C22.966,29.363,23.017,30.249,23.154,31.04z" />
      <path d="M35.722,32.29c-0.011,0-0.021,0-0.032-0.001c-0.632-0.041-1.527-0.09-2.427-0.139c-0.935-0.051-1.874-0.102-2.53-0.145 c-0.107-0.006-0.342-0.02-0.54-0.193c-0.274-0.239-0.261-0.583-0.255-0.729c0.05-1.202,0.065-2.455-0.155-3.477 c-0.032-0.154,0.009-0.314,0.112-0.434c0.103-0.118,0.251-0.19,0.413-0.171c0.669,0.049,1.667,0.056,2.633,0.062 c0.977,0.006,1.985,0.013,2.684,0.063c0.097,0.008,0.294,0.022,0.466,0.168c0.264,0.22,0.253,0.549,0.25,0.656l-0.118,3.855 c-0.004,0.135-0.063,0.264-0.164,0.354C35.966,32.244,35.846,32.29,35.722,32.29z M30.941,31.017 c0.677,0.043,1.528,0.09,2.375,0.136c0.68,0.036,1.357,0.073,1.921,0.106l0.097-3.148c-0.671-0.036-1.548-0.042-2.399-0.047 c-0.716-0.005-1.449-0.009-2.065-0.031C30.993,28.976,30.98,30.013,30.941,31.017z M35.58,28.126h0.01H35.58z" />
      <path d="M18.208,39.119c-0.168,0-0.378-0.018-0.646-0.043c-0.245-0.025-0.545-0.035-0.824-0.044 c-0.268-0.008-0.519-0.017-0.695-0.035c-0.983-0.099-2.171-0.022-3.218,0.046l-0.294,0.019c-0.221,0.022-0.429-0.123-0.503-0.334 c-0.236-0.676-0.116-1.451-0.001-2.2c0.099-0.64,0.201-1.301,0.035-1.743c-0.054-0.146-0.037-0.309,0.046-0.44 c0.082-0.133,0.223-0.218,0.378-0.232l1.277-0.097c1.583-0.06,3.219-0.12,4.776,0.051c0.252,0.028,0.443,0.24,0.444,0.494 c0.003,0.527,0.001,1.043,0,1.559c-0.002,0.514-0.003,1.026,0,1.551c0.008,0.942-0.118,1.135-0.159,1.198 C18.699,39.056,18.531,39.119,18.208,39.119z M17.941,38.418L17.941,38.418L17.941,38.418z M14.967,37.95 c0.403,0,0.801,0.015,1.177,0.053c0.16,0.016,0.386,0.022,0.626,0.03c0.305,0.01,0.631,0.021,0.892,0.048 c0.12,0.012,0.224,0.02,0.311,0.025c0.009-0.157,0.014-0.332,0.013-0.431c-0.003-0.528-0.002-1.044,0-1.56 c0.001-0.365,0.002-0.73,0.002-1.1c-1.357-0.109-2.792-0.057-4.185-0.002l-0.665,0.041c0.049,0.523-0.037,1.08-0.121,1.624 c-0.073,0.473-0.147,0.958-0.115,1.356C13.563,37.993,14.275,37.95,14.967,37.95z" />
      <path d="M23.282,39.038c-0.196,0-0.392-0.002-0.584-0.006c-0.142-0.004-0.25-0.059-0.325-0.103 c-0.049-0.008-0.098-0.023-0.144-0.046c-0.182-0.091-0.291-0.283-0.276-0.486c0.084-1.107-0.034-3.067-0.317-4.034 c-0.046-0.156-0.013-0.325,0.089-0.452c0.101-0.127,0.256-0.205,0.421-0.188c0.364,0.022,0.76,0.051,1.173,0.08 c1.399,0.1,2.985,0.209,4.198,0.15c0.118-0.015,0.273,0.043,0.372,0.139c0.1,0.097,0.155,0.229,0.153,0.367 c-0.004,0.288-0.017,0.63-0.03,0.997c-0.035,0.98-0.079,2.201,0.025,2.953c0.021,0.146-0.024,0.292-0.121,0.401 c-0.097,0.108-0.226,0.173-0.384,0.168c-0.778-0.016-1.585,0.006-2.395,0.026C24.515,39.021,23.891,39.038,23.282,39.038z M22.782,38.811L22.782,38.811L22.782,38.811z M22.974,38.036c0.688,0.004,1.416-0.013,2.139-0.03 c0.632-0.016,1.265-0.042,1.881-0.032c-0.045-0.77-0.011-1.706,0.02-2.553c0.006-0.156,0.012-0.307,0.016-0.451 c-1.172,0.014-2.548-0.081-3.781-0.169c-0.169-0.012-0.335-0.023-0.496-0.035C22.927,35.752,23.003,37.065,22.974,38.036z" />
      <path d="M30.565,38.528c-0.079,0-0.157-0.021-0.232-0.062c-0.137-0.071-0.215-0.208-0.242-0.36 c-0.113-0.623-0.096-1.016-0.08-1.362c0.015-0.322,0.027-0.627-0.065-1.14c-0.04-0.224,0.164-1.425,0.431-1.689 c0.088-0.087,0.204-0.138,0.328-0.144l0.349-0.018c1.448-0.068,2.814-0.136,4.263,0.053c0.161,0.021,0.404,0.054,0.625,0.206 c0.288,0.199,0.406,0.502,0.482,0.772c0.302,1.071,0.306,2.169,0.31,3.23c0.001,0.135-0.053,0.264-0.148,0.358 c-0.097,0.094-0.216,0.137-0.36,0.144l-4.013-0.07c-0.961-0.017-1.376,0.019-1.458,0.039C30.69,38.515,30.628,38.528,30.565,38.528 z M31.722,37.442c0.15,0,0.318,0.002,0.507,0.005l3.501,0.062c-0.01-0.832-0.05-1.673-0.269-2.452 c-0.049-0.17-0.08-0.213-0.091-0.223c-0.026-0.015-0.133-0.028-0.185-0.035c-1.357-0.179-2.682-0.113-4.084-0.046l-0.021,0.001 c-0.066,0.256-0.142,0.629-0.146,0.736c0.107,0.56,0.09,0.952,0.074,1.299c-0.01,0.21-0.019,0.412,0.002,0.669 C31.189,37.448,31.42,37.442,31.722,37.442z" />
    </svg>
  );
}

export default GlideIcon;
