import React from "react";

import {setReloadAPI} from "../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";

const Notifications = () => {
  const dispatch = useDispatch();
  const [setAnchorElUser] = React.useState(null);

  const reloadAPI = useSelector((state) => state.misc.reloadAPI);

  React.useEffect(() => {
    if (reloadAPI) {
      dispatch(setReloadAPI(false));
    } else {
      // dispatch(fetchEmpNotifications());
    }
  }, [dispatch, reloadAPI]);

  return (
    <></>
    // <>
    //   <Tooltip title="Notifications">
    //     <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color: "white" }}>
    //       {/* <Avatar alt="Notifications" src={notificationIcon} /> */}
    //       <Badge badgeContent={"10+"} color="error">
    //         <NotificationsIcon />
    //       </Badge>
    //     </IconButton>
    //   </Tooltip>
    //   <Menu
    //     sx={{ mt: "30px", maxWidth: "500px" }}
    //     id="menu-appbar"
    //     anchorEl={anchorElUser}
    //     anchorOrigin={{
    //       vertical: "top",
    //       horizontal: "right",
    //     }}
    //     keepMounted
    //     transformOrigin={{
    //       vertical: "top",
    //       horizontal: "right",
    //     }}
    //     open={Boolean(anchorElUser)}
    //     onClose={handleCloseUserMenu}
    //   >
    //     {notifications &&
    //       notifications.map((notification, index) => (
    //         <>
    //           {index > 0 && <Divider />}
    //           <MenuItem>
    //             <Box direction="column" sx={{ maxWidth: "100%" }}>
    //               <Stack
    //                 spacing={3}
    //                 direction="row"
    //                 justifyContent="space-between"
    //                 alignItems="center"
    //                 sx={{ minWidth: "300px" }}
    //               >
    //                 <Typography variant="subtitle1">
    //                   {notification?.title}
    //                 </Typography>
    //                 <IconButton size="small" aria-label="close">
    //                   <CloseIcon
    //                     fontSize="small"
    //                     onClick={() =>
    //                       dispatch(
    //                         markEmpNotificationAsRead(
    //                           notification?._id,
    //                           refreshNotifications
    //                         )
    //                       )
    //                     }
    //                   />
    //                 </IconButton>
    //               </Stack>
    //               <Typography
    //                 sx={{
    //                   color: "#00000099",
    //                   maxWidth: "100%",
    //                   whiteSpace: "normal",
    //                 }}
    //                 variant="body2"
    //               >
    //                 {notification?.message}
    //               </Typography>
    //               <Typography variant="caption">
    //                 {moment(notification?.createdAt).format("DD/MM/YYYY")}
    //               </Typography>

    //               <Stack
    //                 spacing={2}
    //                 sx={{ mt: 4 }}
    //                 direction="row"
    //                 justifyContent="space-between"
    //                 alignItems="center"
    //               >
    //                 {notification?.actions?.map((action) => (
    //                   <Button
    //                     color="primary"
    //                     variant="contained"
    //                     component="span"
    //                     size="small"
    //                     sx={{ textTransform: "none" }}
    //                     onClick={(e) => dispatch(push(action?.link))}
    //                     disabled={!action?.link}
    //                   >
    //                     {action?.text}
    //                   </Button>
    //                 ))}
    //               </Stack>
    //             </Box>
    //           </MenuItem>
    //         </>
    //       ))}
    //   </Menu>
    // </>
  );
};

export default Notifications;
