import React from "react";
import Drawer from "@mui/material/Drawer";
import {useStyles} from "../styles";
import {useRouteMatch} from "react-router";
import MenuSelection from "../../MenuSelection";
import DashboardIcon from "../../../assets/header/svgs/DashboardIcon";
import ManageItemsIcon from "../../../assets/header/svgs/ManageItemsIcon";
import LogoutIcon from "../../../assets/header/svgs/LogoutIcon";
import {useDispatch, useSelector} from "react-redux";
import {push} from "connected-react-router";
import {fetchMerchantDetails, merchantLogout} from "../../../redux/actions";
// import RestInfo from "./RestInfo";
import PreferencesIcon from "../../../assets/header/svgs/PreferencesIcon";
// import SalesReportIcon from "../../../assets/header/svgs/SalesReportIcon";
import AnalyticsIcon from "../../../assets/header/svgs/AnalyticsIcon";
// import ManageTableIcon from "../../../assets/header/svgs/ManageTableIcon";
// import ProfileIcon from "../../../assets/header/svgs/ProfileIcon";
// import EmployeeIcon from "../../../assets/header/svgs/EmployeeIcon";
// import CrmIcon from "../../../assets/header/svgs/CrmIcon";
import SubscriptionIcon from "../../../assets/header/svgs/SubscriptionIcon";
// import ActiveTableIcon from "../../../assets/header/svgs/ActiveTableIcon";
// import RetentionIcon from "../../../assets/header/svgs/RetentionIcon";
import ExpensesIcon from "../../../assets/header/svgs/ExpensesIcon";
import PaymentIcon from "../../../assets/header/svgs/PaymentIcon";
// import SocialVoucherIcon from "../../../assets/header/svgs/SocialVoucherIcon";
// import MapIcon from "../../../assets/header/svgs/MapIcon";
// import LoyaltyIcon from "../../../assets/header/svgs/LoyaltyIcon";
// import SMSCampaignIcon from "../../../assets/header/svgs/SMSCampaignIcon";
// import WhatsappIcon from "../../../assets/header/svgs/WhatsappIcon";
// import LeadGenIcon from "../../../assets/header/svgs/LeadGenIcon";
import GlideIcon from "../../../assets/header/svgs/GlideIcon";

// import DesignIcon from '../svgs/DesignIcon';

function MenuDrawer({ open, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const agreementLink = useSelector(
    (state) => state.merchantAuth?.loginData?.agreement_link
  );

  const handleAgreementDownload = () => {
    if (agreementLink) {
      window.open(agreementLink, "_blank");
    }
  };

  let menus = [
    {
      title: "",
      elements: [
        {
          label: "Home",
          active: useRouteMatch("/merchant/home"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/home"));
          },
          icon: <DashboardIcon />,
        },
      ],
    },
    {
      title: "",
      elements: [
        {
          label: "Payments",
          active: useRouteMatch("/merchant/payment-invoice-report"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/payment-invoice-report"));
          },
          icon: <AnalyticsIcon />,
        },
        {
          label: "Payout Reports",
          active: useRouteMatch("/merchant/payout-reports"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/payout-reports"));
          },
          icon: <ExpensesIcon />,
        },
        {
          label: "Brand Wise Payout",
          active: useRouteMatch("/merchant/brand-wise-payout"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/brand-wise-payout"));
          },
          icon: <ExpensesIcon />,
        },
        {
          label: "Payout Transactions",
          active: useRouteMatch("/merchant/payout-transactions"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/payout-transactions"));
          },
          icon: <ExpensesIcon />,
        },

        // {
        //   label: "Paid Online",
        //   active: useRouteMatch("/merchant/paid-online"),
        //   handleMenu: () => {
        //     handleClose();
        //     dispatch(push("/merchant/paid-online"));
        //   },
        //   icon: <ExpensesIcon />,
        // },
        {
          label: "My Tickets",
          active: useRouteMatch("/merchant/my-tickets"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/my-tickets"));
          },
          visible: true,
          icon: <ManageItemsIcon />,
        },
        {
          label: "Store timing",
          active: useRouteMatch("/merchant/preference/timing"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/preference/timing"));
            dispatch(fetchMerchantDetails());
          },
          icon: <PreferencesIcon />,
        },
        {
          label: "Contact details",
          active: useRouteMatch("/merchant/preference/contact"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/preference/contact"));
            dispatch(fetchMerchantDetails());
          },
          icon: <PreferencesIcon />,
        },
        // {
        //   label: "Reports",
        //   active: useRouteMatch("/reports"),
        //   handleMenu: () => {
        //     handleClose();
        //     dispatch(push("/reports"));
        //   },
        //   icon: <SalesReportIcon />,
        // },
        // {
        //   label: "Active Tables",
        //   active: useRouteMatch("/active-tables"),
        //   handleMenu: () => {
        //     handleClose();
        //     dispatch(push("/active-tables"));
        //   },
        //   icon: <ActiveTableIcon />,
        // },
      ],
    },
    {
      title: "",
      elements: [
        {
          label: "Training documents",
          active: useRouteMatch("/merchant/training"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/training"));
          },
          icon: <GlideIcon />,
        },
        {
          label: "Download agreement",
          active: false,
          handleMenu: () => {
            handleClose();
            handleAgreementDownload();
          },
          icon: <SubscriptionIcon />,
        },
        {
          label: "Change Documents",
          active: useRouteMatch("/merchant/bank-details"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/bank-details"));
          },
          icon: <GlideIcon />,
        },
        {
          label: "Change GST",
          active: useRouteMatch("/merchant/gst-upload"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/gst-upload"));
          },
          icon: <GlideIcon />,
        },
      ],
    },
    {
      title: "",
      elements: [
        {
          label: "Food Cost",
          active: useRouteMatch("/merchant/food-cost"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/food-cost"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "Restaurant Health",
          active: useRouteMatch("/merchant/restaurant-health"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/restaurant-health"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "ZS Analytics",
          active: useRouteMatch("/merchant/restaurant-zs-analytics"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/restaurant-zs-analytics"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "Sales Analytics",
          active: useRouteMatch("/merchant/restaurant-sales-analytics"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/restaurant-sales-analytics"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "Daily Reports",
          active: useRouteMatch("/merchant/restaurant-daily-report"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/restaurant-daily-report"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "GK Investments",
          active: useRouteMatch("/merchant/gk-investments"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/gk-investments"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "Sales Forecast",
          active: useRouteMatch("/merchant/forecast"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/forecast"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "Rupifi Credit",
          active: useRouteMatch("/merchant/rupifi/credit"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/rupifi/credit"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "Blogger Orders",
          active: useRouteMatch("/merchant/blogger/orders"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/blogger/orders"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "Invoices",
          active: useRouteMatch("/merchant/invoices"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/invoices"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "IGCC Metrics",
          active: useRouteMatch("/merchant/igcc"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/igcc"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "Daily Ops",
          active: useRouteMatch("/merchant/daily-ops"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/daily-ops"));
          },
          icon: <ManageItemsIcon />,
        },
        {
          label: "Raw material purchase report",
          active: useRouteMatch("/merchant/raw-material-purchase-report"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/raw-material-purchase-report"));
          },
          icon: <PaymentIcon />,
        },
        {
          label: "Subscription fee",
          active: useRouteMatch("/merchant/subscription-fee-report"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/subscription-fee-report"));
          },
          icon: <PaymentIcon />,
        },
        {
          label: "Onboarding fee",
          active: useRouteMatch("/merchant/onboarding-fee-report"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/onboarding-fee-report"));
          },
          icon: <PaymentIcon />,
        },
        {
          label: "POS charges",
          active: useRouteMatch("/merchant/pos-charges-report"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/pos-charges-report"));
          },
          icon: <PaymentIcon />,
        },
        {
          label: "Tax report",
          active: useRouteMatch("/merchant/tax-report"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/tax-report"));
          },
          icon: <PaymentIcon />,
        },
        {
          label: "Adjustment report",
          active: useRouteMatch("/merchant/adjustment-report"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/adjustment-report"));
          },
          icon: <PaymentIcon />,
        },
        {
          label: "Ledger report",
          active: useRouteMatch("/merchant/ledger-report"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/ledger-report"));
          },
          icon: <PaymentIcon />,
        },
        {
          label: "PO credit status",
          active: useRouteMatch("/merchant/po-credit-status"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/merchant/po-credit-status"));
          },
          icon: <PaymentIcon />,
        },
      ],
    },
    // {
    //   title: "Settings",
    //   elements: [
    //     {
    //       label: "Menu",
    //       active: useRouteMatch("/menu"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/menu"));
    //       },
    //       icon: <ManageItemsIcon />,
    //     },
    //     {
    //       label: "Preferences",
    //       active: useRouteMatch("/preferences"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/preferences"));
    //       },
    //       icon: <PreferencesIcon />,
    //     },
    //     {
    //       label: "Tables",
    //       active: useRouteMatch("/tables"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/tables"));
    //       },
    //       icon: <ManageTableIcon />,
    //     },
    //     {
    //       label: "Employees",
    //       active: useRouteMatch("/employees"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/employees"));
    //       },
    //       icon: <EmployeeIcon />,
    //     },
    //     {
    //       label: "Profile",
    //       active: useRouteMatch("/profile"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/profile"));
    //       },
    //       icon: <ProfileIcon />,
    //     },
    //   ],
    // },
    // {
    //   title: "Growth Hacking",
    //   elements: [
    //     {
    //       label: "Customer segments",
    //       active: useRouteMatch("/crm"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/crm"));
    //       },
    //       icon: <CrmIcon />,
    //     },
    //     {
    //       label: "Lead gen",
    //       active: useRouteMatch("/lead-gen"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/lead-gen"));
    //       },
    //       icon: <LeadGenIcon />,
    //     },
    //     {
    //       label: "Social Vouchers",
    //       active: useRouteMatch("/social-vouchers"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/social-vouchers"));
    //       },
    //       icon: <SocialVoucherIcon />,
    //     },
    //     {
    //       label: "Map my customers",
    //       active: useRouteMatch("/map-my-customers"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/map-my-customers"));
    //       },
    //       icon: <MapIcon />,
    //     },
    //     {
    //       label: "Loyalty",
    //       active: useRouteMatch("/loyalty"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/loyalty"));
    //       },
    //       icon: <LoyaltyIcon />,
    //     },
    //   ],
    // },
    // {
    //   title: "Marketing automation",
    //   elements: [
    //     {
    //       label: "FB/Insta",
    //       active: useRouteMatch("/retention"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/retention"));
    //       },
    //       icon: <RetentionIcon />,
    //     },
    //     {
    //       label: "SMS",
    //       active: useRouteMatch("/sms"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/sms"));
    //       },
    //       icon: <SMSCampaignIcon />,
    //     },
    //     {
    //       label: "Whatsapp",
    //       active: useRouteMatch("/whatsapp"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/whatsapp"));
    //       },
    //       icon: <WhatsappIcon />,
    //     },
    //     {
    //       label: "Glide",
    //       active: useRouteMatch("/glide"),
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/glide"));
    //       },
    //       icon: <GlideIcon />,
    //     },
    //   ],
    // },
    // {
    //   title: "Extra",
    //   elements: [
    // {
    //   label: ('JukeBox'),
    //   active: useRouteMatch('/jukebox'),
    //   handleMenu: () => {
    //     handleClose();
    //     dispatch(push('/jukebox'));
    //   },
    //   icon: <JukeBoxIcon />,
    // },
    // {
    //   label: "Payments",
    //   active: useRouteMatch("/payments"),
    //   handleMenu: () => {
    //     handleClose();
    //     dispatch(push("/payments"));
    //   },
    //   icon: <PaymentIcon />,
    // },
    // {
    //   label: "Expenses",
    //   active: useRouteMatch("/expenses"),
    //   handleMenu: () => {
    //     handleClose();
    //     dispatch(push("/expenses"));
    //   },
    //   icon: <ExpensesIcon />,
    // },
    // {
    //   label: "Recurring",
    //   active: useRouteMatch("/recurring"),
    //   handleMenu: () => {
    //     handleClose();
    //     dispatch(push("/recurring"));
    //   },
    //   icon: <SubscriptionIcon />,
    // },
    // {
    //   label: ('Design'),
    //   active: useRouteMatch('/design'),
    //   handleMenu: () => {
    //     handleClose();
    //     dispatch(push('/design'));
    //   },
    //   icon: <DesignIcon />,
    // },
    //   ],
    // },
    {
      title: "",
      elements: [
        {
          label: "Logout",
          active: false,
          handleMenu: () => {
            handleClose();
            dispatch(merchantLogout());
          },
          icon: <LogoutIcon />,
        },
      ],
    },
  ];

  return (
    <Drawer open={open} onClose={handleClose}>
      <div className={classes.drawerRoot}>
        {/* <RestInfo /> */}
        {menus.map((subMenu, idx) => (
          <div key={idx}>
            {subMenu.title && (
              <div
                style={{ margin: "16px 16px 8px", fontSize: 18, opacity: 0.6 }}
              >
                <fieldset
                  style={{
                    borderTop: "0.5px solid lightgray",
                    borderLeft: "none",
                    borderBottom: "none",
                    borderRight: "none",
                    padding: 0,
                  }}
                >
                  <legend>{subMenu.title}&nbsp;</legend>
                </fieldset>
              </div>
            )}
            {subMenu.elements.map((menu, index) => (
              <MenuSelection
                key={index}
                title={subMenu.title}
                active={menu.active}
                label={menu.label}
                handleClick={menu.handleMenu}
              >
                {menu.icon}
              </MenuSelection>
            ))}
          </div>
        ))}
        <div className={classes.bottomArea}>
          <div className={classes.version}>
            V-{window.localStorage.getItem("appVersion")}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default MenuDrawer;
