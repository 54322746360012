import * as actionTypes from "../actionTypes";

let initialState = {
  paymentInvoices: [],
  paymentLinks: [],
  paidOnline: [],
  financeReport: [],
  taxReport: [],
  adjustmentReport: [],
  ledgerReport: {},
  ledgerSummary: {},
};

export const merchantReportsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MERCHANT_PAYMENT_INVOICE:
      return {
        ...state,
        paymentInvoices: action.payload,
      };
    case actionTypes.SET_MERCHANT_PAYMENT_LINK:
      return {
        ...state,
        paymentLinks: action.payload,
      };
    case actionTypes.SET_MERCHANT_PAID_ONLINE:
      return {
        ...state,
        paidOnline: action.payload,
      };
    case actionTypes.SET_MERCHANT_FINANCE_REPORT:
      return {
        ...state,
        financeReport: action.payload,
      };
    case actionTypes.SET_MERCHANT_TAX_REPORT:
      return {
        ...state,
        taxReport: action.payload,
      };
    case actionTypes.SET_MERCHANT_ADJUSTMENT_REPORT:
      return {
        ...state,
        adjustmentReport: action.payload,
      };
    case actionTypes.SET_MERCHANT_LEDGER_REPORT:
      return {
        ...state,
        ledgerReport: action.payload,
      };
    case actionTypes.SET_MERCHANT_LEDGER_SUMMARY:
      return {
        ...state,
        ledgerSummary: action.payload,
      };
    case actionTypes.SET_MERCHANT_LOGOUT:
      return {
        paymentInvoices: [],
        paymentLinks: [],
        paidOnline: [],
        financeReport: [],
        taxReport: [],
        adjustmentReport: [],
        ledgerReport: {},
        ledgerSummary: {},
      };

    default:
      return state;
  }
};
