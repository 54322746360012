import { toast } from "react-toastify";
import { setEmployeeLoading, setReloadAPI } from ".";
import { employee_http } from "../../https/employee_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";
import fileDownload from "js-file-download";
import moment from "moment";

export const fetchRazorpayRecon = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/partner-payments/all?&from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_RAZORPAY_RECON,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchCancelledPayouts = (month) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/cancelled-orders/data?month=${moment(month).format(
          "MMM-YYYY"
        )}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_CANCELLED_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadCancelledOrders = (file, type, month) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    formData.append("date", moment(month).format("MMM-YYYY"));
    employee_http
      .post(`${API_URL}/cancelled-orders/`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(`
          Uploaded
        `);
        await dispatch(setEmployeeLoading(false));
        await dispatch(setReloadAPI(false));
      })
      .catch(() => {
        toast.error("Error in uploading data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadAggregatorReconList = (file, type) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = ``;
    switch (type) {
      case "indus":
        url = `${API_URL}/sales-bank-utr/excel/indus`;
        break;
      case "hdfc":
        url = `${API_URL}/sales-bank-utr/excel/hdfc`;
        break;
      case "zomato":
        url = `${API_URL}/sales-sync/excel/zomato`;
        break;
      case "swiggy":
        url = `${API_URL}/sales-sync/excel/swiggy`;
        break;
      default:
        break;
    }

    let formData = new FormData();
    formData.append("file", file, {
      "Content-Type": "multipart/form-data",
    });
    employee_http
      .post(url, formData)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_AGGREGATOR_RECON_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadAlternateRistaSales = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file, {
      "Content-Type": "multipart/form-data",
    });
    employee_http
      .post(`${API_URL}/rista-sales/upload`, formData)
      .then(async (res) => {
        await dispatch(setEmployeeLoading(false));
        toast.success("Upload in progress... Check status in Upload Status");
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadOverallSales = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file, {
      "Content-Type": "multipart/form-data",
    });
    employee_http
      .post(`${API_URL}/alternate-overall-sales/upload`, formData)
      .then(async (res) => {
        await dispatch(setEmployeeLoading(false));
        toast.success("Upload in progress... Check status in Upload Status");
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAggregatorReconList = (
  fromDate,
  toDate,
  platform,
  utr_type
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sales-bank-utr/utr-amount?fromDate=${fromDate}&toDate=${toDate}`;
    if (platform === "swiggy") {
      url += `&platform=${platform}&utr_type=${utr_type}`;
    } else if (platform === "zomato") {
      url += `&platform=${platform}`;
    } else {
      url += `&bank=${platform}`;
    }
    employee_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_AGGREGATOR_RECON_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadAggregatorReconList = (
  fromDate,
  toDate,
  platform,
  utr_type
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/sales-bank-utr/utr-amount/excel?fromDate=${fromDate}&toDate=${toDate}`;
    if (platform === "swiggy") {
      url += `&platform=${platform}&utr_type=${utr_type}`;
    } else if (platform === "zomato") {
      url += `&platform=${platform}`;
    } else {
      url += `&bank=${platform}`;
    }
    employee_http
      .get(url, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `aggregator_recon_${new Date()}.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
