import React from 'react';

function LoyaltyIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#eddc67"
        d="M89,31v38c0,4.418-3.582,8-8,8H19c-4.418,0-8-3.582-8-8V31c0-4.418,3.582-8,8-8h62 C85.418,23,89,26.582,89,31z"
      />
      <path
        fill="#f15b6c"
        d="M42.5,37c-2.967,0-5.5,2-7.5,4.83C33,39,30.542,37,27.5,37c-4.142,0-7.5,3.358-7.5,7.5 C20,55,35.038,63,35.038,63S50,55,50,44.5C50,40.358,46.642,37,42.5,37z"
      />
      <g>
        <path
          fill="#1f212b"
          d="M81,78H19c-4.963,0-9-4.038-9-9V31c0-4.962,4.037-9,9-9h62c4.963,0,9,4.038,9,9v38 C90,73.962,85.963,78,81,78z M19,24c-3.859,0-7,3.14-7,7v38c0,3.86,3.141,7,7,7h62c3.859,0,7-3.14,7-7V31c0-3.86-3.141-7-7-7H19z"
        />
        <circle cx="57" cy="68" r="1" fill="#1f212b" />
        <circle cx="61" cy="68" r="1" fill="#1f212b" />
        <circle cx="65" cy="68" r="1" fill="#1f212b" />
        <circle cx="69" cy="68" r="1" fill="#1f212b" />
        <circle cx="73" cy="68" r="1" fill="#1f212b" />
        <circle cx="77" cy="68" r="1" fill="#1f212b" />
        <circle cx="81" cy="68" r="1" fill="#1f212b" />
        <path
          fill="#1f212b"
          d="M69.5,35h-13c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h13c0.276,0,0.5,0.224,0.5,0.5 S69.776,35,69.5,35z"
        />
        <path
          fill="#1f212b"
          d="M81.5,35h-9c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h9c0.276,0,0.5,0.224,0.5,0.5 S81.776,35,81.5,35z"
        />
        <path
          fill="#1f212b"
          d="M75.5,39h-19c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h19c0.276,0,0.5,0.224,0.5,0.5 S75.776,39,75.5,39z"
        />
        <path
          fill="#1f212b"
          d="M35.038,64c-0.162,0-0.323-0.039-0.47-0.117C33.933,63.545,19,55.475,19,44.5 c0-4.687,3.813-8.5,8.5-8.5c2.723,0,5.242,1.403,7.501,4.177C37.247,37.438,39.819,36,42.5,36c4.687,0,8.5,3.813,8.5,8.5 c0,10.973-14.858,19.044-15.49,19.382C35.362,63.961,35.2,64,35.038,64z M27.5,38c-3.584,0-6.5,2.916-6.5,6.5 c0,8.76,11.541,15.908,14.037,17.352C37.523,60.406,49,53.259,49,44.5c0-3.584-2.916-6.5-6.5-6.5c-2.95,0-5.263,2.396-6.683,4.407 c-0.375,0.531-1.259,0.531-1.634,0C32.087,39.441,29.901,38,27.5,38z"
        />
      </g>
    </svg>
  );
}

export default LoyaltyIcon;
