// import { toast } from "react-toastify";
import {setEmployeeLoading} from ".";
import {employee_http} from "../../https/employee_http";
import {API_URL} from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchAllProperty = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/property`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_PROPERTY_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createNewProperty = (data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/property`, data)
      .then(async (res) => {
        await dispatch(fetchAllProperty());
        await dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadPropertyBanner = (file, id, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/property/banner/${id}`, formData, {
        "Content-Type": "multipart/form-data"
      })
      .then(async (res) => {
        await dispatch(fetchAllProperty());
        await dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
}

export const deletePropertyBanner = (url, id, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/property/banner/delete/${id}`, {
        url
      })
      .then(async (res) => {
        await dispatch(fetchAllProperty());
        await dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
}

export const deleteProperty = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .delete(`${API_URL}/property/${id}`)
      .then(async (res) => {
        await dispatch(fetchAllProperty());
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
}
