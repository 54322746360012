import * as actionTypes from "../actionTypes";
import axios from "axios";
import {API_URL} from "../../utils/constants";
import {push} from "connected-react-router";
import {employee_http} from "../../https/employee_http";
import {toast} from "react-toastify";

export const setDistributorLoginError = (err) => ({
  type: actionTypes.SET_DISTRIBUTOR_LOGIN_ERROR,
  payload: err,
});

export const distributorLogin = (user, pwd, redirectURL) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.DISTRIBUTOR_LOGIN_LOADING, payload: true });
    setTimeout(() => {
      dispatch({ type: actionTypes.DISTRIBUTOR_LOGIN_LOADING, payload: false });
    }, 5000);
    axios
      .post(`${API_URL}/distributor/auth/login`, {
        username: user,
        password: pwd,
      })
      .then(async (res) => {
        await window.localStorage.setItem(
          "distributor_auth_token",
          res.data.token
        );
        await window.localStorage.setItem(
          "distributor_refresh_token",
          res.data.refreshToken
        );
        await dispatch({
          type: actionTypes.SET_DISTRIBUTOR_LOGIN_DATA,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.DISTRIBUTOR_LOGIN_LOADING,
          payload: false,
        });
        await dispatch(push(redirectURL));
      })
      .catch(() => {
        dispatch({
          type: actionTypes.DISTRIBUTOR_LOGIN_LOADING,
          payload: false,
        });
        dispatch(setDistributorLoginError(true));
      });
  };
};

export const distributorByPassLogin = (token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.DISTRIBUTOR_LOGIN_LOADING, payload: true });
    axios
      .post(
        `${API_URL}/distributor/app/login-by-pass`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        await window.localStorage.setItem(
          "distributor_auth_token",
          res.data.token
        );
        await window.localStorage.setItem("app_login", true);
        await window.localStorage.setItem(
          "distributor_refresh_token",
          res.data.refreshToken
        );
        await dispatch({
          type: actionTypes.SET_DISTRIBUTOR_LOGIN_DATA,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.DISTRIBUTOR_LOGIN_LOADING,
          payload: false,
        });
        await dispatch(push("/distributor/home"));
      })
      .catch(() => {
        dispatch({
          type: actionTypes.DISTRIBUTOR_LOGIN_LOADING,
          payload: false,
        });
        dispatch(push("/distributor-login"));
      });
  };
};

export const updateCreds = async (username, password, supplierId) => {
  console.log(username, password, supplierId);
  await employee_http.post(`${API_URL}/distributor/auth/createCreds`, {
    username,
    password,
    _id: supplierId,
  });
  toast.success("Added");
};
