import React from "react";
import Drawer from "@mui/material/Drawer";
import { useStyles } from "../styles";
import { useRouteMatch } from "react-router";
import MenuSelection from "../../MenuSelection";
import DashboardIcon from "../../../assets/header/svgs/DashboardIcon";
import ManageItemsIcon from "../../../assets/header/svgs/ManageItemsIcon";
import LogoutIcon from "../../../assets/header/svgs/LogoutIcon";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { fetchMerchantDetails, merchantLogout } from "../../../redux/actions";
// import RestInfo from "./RestInfo";
import PreferencesIcon from "../../../assets/header/svgs/PreferencesIcon";
// import SalesReportIcon from "../../../assets/header/svgs/SalesReportIcon";
import AnalyticsIcon from "../../../assets/header/svgs/AnalyticsIcon";
// import ManageTableIcon from "../../../assets/header/svgs/ManageTableIcon";
// import ProfileIcon from "../../../assets/header/svgs/ProfileIcon";
// import EmployeeIcon from "../../../assets/header/svgs/EmployeeIcon";
// import CrmIcon from "../../../assets/header/svgs/CrmIcon";
import SubscriptionIcon from "../../../assets/header/svgs/SubscriptionIcon";
// import ActiveTableIcon from "../../../assets/header/svgs/ActiveTableIcon";
// import RetentionIcon from "../../../assets/header/svgs/RetentionIcon";
import ExpensesIcon from "../../../assets/header/svgs/ExpensesIcon";
import PaymentIcon from "../../../assets/header/svgs/PaymentIcon";
// import SocialVoucherIcon from "../../../assets/header/svgs/SocialVoucherIcon";
// import MapIcon from "../../../assets/header/svgs/MapIcon";
// import LoyaltyIcon from "../../../assets/header/svgs/LoyaltyIcon";
// import SMSCampaignIcon from "../../../assets/header/svgs/SMSCampaignIcon";
// import WhatsappIcon from "../../../assets/header/svgs/WhatsappIcon";
// import LeadGenIcon from "../../../assets/header/svgs/LeadGenIcon";
import GlideIcon from "../../../assets/header/svgs/GlideIcon";
import { userLogout } from "../../../redux/actions/userAuthActions";
import { useLocation } from "react-router";

// import DesignIcon from '../svgs/DesignIcon';

function MenuDrawer({ open, handleClose }) {
  const userDetails = useSelector((state) => state.userAuth.loginData);

  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const agreementLink = useSelector(
    (state) => state.merchantAuth?.loginData?.agreement_link
  );

  const handleAgreementDownload = () => {
    if (agreementLink) {
      window.open(agreementLink, "_blank");
    }
  };

  let menus = [
    {
      title: "config",
      visible: true,
      icon: PreferencesIcon,
      active:
        location.pathname === "/user/brands" ||
        location.pathname === "/user/mapping" ||
        location.pathname === "/user/wallet" ||
        location.pathname === "/user/business-report" ||
        location.pathname === "/user/oos" ||
        location.pathname === "/user/poor-rating" ||
        location.pathname === "/user/order-level-data" ||
        location.pathname === "/user/sale-dashboard" ||
        location.pathname === "/user/location",
      elements: [
        {
          label: "Brands",
          active: location.pathname === "/user/brands",
          handleMenu: () => {
            dispatch(push("/user/brands"));
          },
          visible: true,
        },
        {
          label: "Locations",
          active: location.pathname === "/user/location",
          handleMenu: () => {
            dispatch(push("/user/location"));
          },
          visible: true,
        },
        {
          label: "Mapping",
          active: location.pathname === "/user/mapping",
          handleMenu: () => {
            dispatch(push("/user/mapping"));
          },
          visible: true,
        },
        {
          label: "Wallet",
          active: location.pathname === "/user/wallet",
          handleMenu: () => {
            dispatch(push("/user/wallet"));
          },
          visible: true,
        },
        {
          label: "Poor Rating",
          active: location.pathname === "/user/poor-rating",
          handleMenu: () => {
            dispatch(push("/user/poor-rating"));
          },
          visible: true,
        },
        {
          label: "Business Report",
          active: location.pathname === "/user/business-report",
          handleMenu: () => {
            dispatch(push("/user/business-report"));
          },
          visible: true,
        },
        {
          label: "Sale Dashboard",
          active: location.pathname === "/user/sale-dashboard",
          handleMenu: () => {
            dispatch(push("/user/sale-dashboard"));
          },
          visible: true,
        },
        {
          label: "OOS",
          active: location.pathname === "/user/oos",
          handleMenu: () => {
            dispatch(push("/user/oos"));
          },
          visible: userDetails?.POS === "Rista",
        },
        {
          label: "Order Level Data",
          active: location.pathname === "/user/order-level-data",
          handleMenu: () => {
            dispatch(push("/user/order-level-data"));
          },
          visible: true,
        },
      ],
    },
    {
      title: "",
      elements: [
        {
          label: "Logout",
          active: false,
          handleMenu: () => {
            handleClose();
            dispatch(userLogout());
          },
          icon: <LogoutIcon />,
        },
      ],
    },
  ];

  return (
    <Drawer open={open} onClose={handleClose}>
      <div className={classes.drawerRoot}>
        {/* <RestInfo /> */}
        {menus.map((subMenu, idx) => (
          <div key={idx}>
            {subMenu.title && (
              <div
                style={{ margin: "16px 16px 8px", fontSize: 18, opacity: 0.6 }}
              >
                <fieldset
                  style={{
                    borderTop: "0.5px solid lightgray",
                    borderLeft: "none",
                    borderBottom: "none",
                    borderRight: "none",
                    padding: 0,
                  }}
                >
                  <legend>{subMenu.title}&nbsp;</legend>
                </fieldset>
              </div>
            )}
            {subMenu.elements.map((menu, index) => (
              <MenuSelection
                key={index}
                title={subMenu.title}
                active={menu.active}
                label={menu.label}
                handleClick={menu.handleMenu}
              >
                {menu.icon}
              </MenuSelection>
            ))}
          </div>
        ))}
        <div className={classes.bottomArea}>
          <div className={classes.version}>
            V-{window.localStorage.getItem("appVersion")}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default MenuDrawer;
