import React from 'react';

function SMSCampaignIcon({size}) {
  return (
    <svg
      width={size ? size : '32'}
      height={size ? size : '32'}
      viewBox="0 0 520 520"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#3f4751"
        d="M395,71.67v376.66c0,20.25-16.42,36.67-36.67,36.67H166.67c-20.25,0-36.67-16.42-36.67-36.67 c0-0.11,0-337.044,0-376.66C130,51.42,146.42,35,166.67,35h191.66C378.58,35,395,51.42,395,71.67z"
      />
      <path fill="#ecebed" d="M365,120v290H160c0-0.112,0-289.833,0-290H365z" />
      <path
        fill="#7d0"
        d="M240,170.75v88.5c0,8.7-7.05,15.75-15.75,15.75c-10.886,0-88.298,0-99.25,0l-45,30l10-30H65.75 c-8.7,0-15.75-7.05-15.75-15.75v-88.5c0-8.7,7.05-15.75,15.75-15.75c7.125,0,145.887,0,158.5,0C232.95,155,240,162.05,240,170.75z"
      />
      <path
        fill="#757d8c"
        d="M262.5,465c-9.66,0-17.5-7.83-17.5-17.5s7.84-17.5,17.5-17.5c9.67,0,17.5,7.83,17.5,17.5 S272.17,465,262.5,465z"
      />
      <path
        fill="#fd6814"
        d="M455,75c13.81,0,25,11.19,25,25s-11.19,25-25,25s-25-11.19-25-25S441.19,75,455,75z"
      />
      <circle cx="70" cy="420" r="30" fill="#fd6814" />
      <path fill="#bde1ff" d="M215,180v70H75v-70H215z" />
      <rect width="205" height="35" x="160" y="85" fill="#bcb7c6" />
      <rect width="155" height="75" x="185" y="310" fill="#bde1ff" />
      <circle cx="462.5" cy="357.5" r="7.5" />
      <circle cx="437.5" cy="187.5" r="7.5" />
      <circle cx="427.5" cy="452.5" r="7.5" />
      <path d="M195.1,201.71l-3.36,6.37c-0.1-0.13-0.43-0.36-0.99-0.71c-0.56-0.34-1.26-0.7-2.1-1.07c-0.84-0.38-1.76-0.7-2.76-0.98 c-1-0.28-2-0.43-3-0.43c-2.74,0-4.11,0.93-4.11,2.77c0,0.56,0.14,1.02,0.44,1.4c0.3,0.37,0.73,0.71,1.31,1 c0.58,0.3,1.3,0.57,2.18,0.82c0.87,0.25,1.88,0.53,3.04,0.85c1.59,0.43,3.02,0.91,4.3,1.42c1.28,0.52,2.36,1.16,3.25,1.92 c0.89,0.76,1.58,1.69,2.06,2.78c0.48,1.1,0.73,2.41,0.73,3.93c0,1.88-0.35,3.46-1.06,4.75c-0.7,1.3-1.63,2.34-2.78,3.14 c-1.15,0.79-2.48,1.37-3.98,1.73c-1.49,0.36-3.04,0.54-4.63,0.54c-1.21,0-2.46-0.1-3.74-0.28c-1.28-0.19-2.53-0.46-3.74-0.82 c-1.22-0.36-2.4-0.79-3.54-1.29c-1.13-0.5-2.19-1.08-3.15-1.73l3.37-6.69c0.12,0.15,0.52,0.45,1.21,0.89 c0.69,0.43,1.54,0.87,2.55,1.31c1.01,0.43,2.15,0.82,3.39,1.17c1.25,0.34,2.51,0.51,3.79,0.51c2.72,0,4.07-0.82,4.07-2.48 c0-0.62-0.2-1.14-0.61-1.54c-0.4-0.41-0.96-0.77-1.68-1.1c-0.72-0.33-1.57-0.63-2.55-0.91c-0.98-0.28-2.05-0.6-3.2-0.94 c-1.53-0.47-2.86-0.97-3.98-1.52c-1.12-0.54-2.05-1.18-2.78-1.89c-0.74-0.72-1.28-1.55-1.64-2.48c-0.36-0.94-0.54-2.03-0.54-3.28 c0-1.74,0.33-3.29,0.98-4.63c0.66-1.34,1.55-2.47,2.67-3.39s2.42-1.62,3.91-2.08c1.48-0.47,3.04-0.71,4.7-0.71 c1.15,0,2.29,0.11,3.41,0.33c1.13,0.22,2.2,0.5,3.23,0.84c1.03,0.35,1.99,0.74,2.88,1.17 C193.54,200.84,194.36,201.28,195.1,201.71z" />
      <polygon points="162.17,198.35 162.17,231.56 154.5,231.56 154.5,211.68 147.29,226.13 143.18,226.13 135.97,211.68 135.97,231.56 128.3,231.56 128.3,198.35 136.63,198.35 145.23,215.7 153.89,198.35" />
      <path d="M119.55,201.71l-3.37,6.37c-0.09-0.13-0.42-0.36-0.98-0.71c-0.56-0.34-1.26-0.7-2.11-1.07c-0.84-0.38-1.76-0.7-2.76-0.98 c-0.99-0.28-1.99-0.43-2.99-0.43c-2.74,0-4.12,0.93-4.12,2.77c0,0.56,0.15,1.02,0.45,1.4c0.29,0.37,0.73,0.71,1.31,1 c0.57,0.3,1.3,0.57,2.17,0.82c0.88,0.25,1.89,0.53,3.04,0.85c1.59,0.43,3.03,0.91,4.31,1.42c1.28,0.52,2.36,1.16,3.25,1.92 c0.89,0.76,1.57,1.69,2.06,2.78c0.48,1.1,0.72,2.41,0.72,3.93c0,1.88-0.35,3.46-1.05,4.75c-0.7,1.3-1.63,2.34-2.78,3.14 c-1.16,0.79-2.48,1.37-3.98,1.73s-3.04,0.54-4.63,0.54c-1.22,0-2.47-0.1-3.74-0.28c-1.28-0.19-2.53-0.46-3.75-0.82 c-1.21-0.36-2.39-0.79-3.53-1.29c-1.14-0.5-2.19-1.08-3.16-1.73l3.37-6.69c0.13,0.15,0.53,0.45,1.22,0.89 c0.68,0.43,1.53,0.87,2.55,1.31c1.01,0.43,2.14,0.82,3.39,1.17c1.25,0.34,2.51,0.51,3.79,0.51c2.71,0,4.07-0.82,4.07-2.48 c0-0.62-0.2-1.14-0.61-1.54c-0.41-0.41-0.97-0.77-1.68-1.1c-0.72-0.33-1.57-0.63-2.55-0.91c-0.99-0.28-2.05-0.6-3.21-0.94 c-1.53-0.47-2.85-0.97-3.97-1.52c-1.13-0.54-2.06-1.18-2.79-1.89c-0.73-0.72-1.28-1.55-1.64-2.48c-0.35-0.94-0.53-2.03-0.53-3.28 c0-1.74,0.32-3.29,0.98-4.63c0.65-1.34,1.54-2.47,2.67-3.39c1.12-0.92,2.42-1.62,3.9-2.08c1.48-0.47,3.05-0.71,4.7-0.71 c1.16,0,2.3,0.11,3.42,0.33c1.12,0.22,2.2,0.5,3.23,0.84c1.03,0.35,1.99,0.74,2.87,1.17C117.98,200.84,118.8,201.28,119.55,201.71 z" />
      <circle cx="92.5" cy="112.5" r="7.5" />
      <circle cx="92.5" cy="347.5" r="7.5" />
      <circle cx="77.5" cy="57.5" r="7.5" />
      <path d="M262.5,470c-12.407,0-22.5-10.094-22.5-22.5s10.093-22.5,22.5-22.5c12.406,0,22.5,10.094,22.5,22.5S274.906,470,262.5,470 z M262.5,435c-6.893,0-12.5,5.607-12.5,12.5s5.607,12.5,12.5,12.5s12.5-5.607,12.5-12.5S269.393,435,262.5,435z" />
      <path d="M277.5,65h-35c-2.761,0-5-2.239-5-5s2.239-5,5-5h35c2.762,0,5,2.239,5,5S280.262,65,277.5,65z" />
      <path d="M455,130c-16.542,0-30-13.458-30-30s13.458-30,30-30s30,13.458,30,30S471.542,130,455,130z M455,80 c-11.028,0-20,8.972-20,20s8.972,20,20,20s20-8.972,20-20S466.028,80,455,80z" />
      <path d="M70,455c-19.299,0-35-15.701-35-35s15.701-35,35-35s35,15.701,35,35S89.299,455,70,455z M70,395 c-13.785,0-25,11.215-25,25s11.215,25,25,25s25-11.215,25-25S83.785,395,70,395z" />
      <path d="M358.33,490H166.67c-22.977,0-41.67-18.693-41.67-41.67V275c0-2.762,2.239-5,5-5s5,2.238,5,5v173.33 c0,17.463,14.207,31.67,31.67,31.67h191.66c17.463,0,31.67-14.207,31.67-31.67V71.67C390,54.207,375.793,40,358.33,40H166.67 C149.207,40,135,54.207,135,71.67V155c0,2.761-2.239,5-5,5s-5-2.239-5-5V71.67C125,48.693,143.693,30,166.67,30h191.66 C381.307,30,400,48.693,400,71.67v376.66C400,471.307,381.307,490,358.33,490z" />
      <path d="M365,415H160c-2.761,0-5-2.238-5-5V275c0-2.762,2.239-5,5-5s5,2.238,5,5v130h195V90H165v65c0,2.761-2.239,5-5,5 s-5-2.239-5-5V85c0-2.761,2.239-5,5-5h205c2.762,0,5,2.239,5,5v325C370,412.762,367.762,415,365,415z" />
      <path d="M80,310c-1.072,0-2.142-0.344-3.036-1.026c-1.697-1.297-2.384-3.528-1.708-5.555L83.063,280H65.75 C54.309,280,45,270.691,45,259.25v-88.5C45,159.309,54.309,150,65.75,150h158.5c11.441,0,20.75,9.309,20.75,20.75v88.5 c0,11.441-9.309,20.75-20.75,20.75h-97.736l-43.74,29.16C81.932,309.722,80.965,310,80,310z M65.75,160 C59.822,160,55,164.822,55,170.75v88.5c0,5.928,4.822,10.75,10.75,10.75H90c1.607,0,3.117,0.772,4.056,2.076 c0.94,1.304,1.195,2.98,0.688,4.505l-5.392,16.175l32.875-21.916c0.821-0.548,1.786-0.84,2.773-0.84h99.25 c5.928,0,10.75-4.822,10.75-10.75v-88.5c0-5.928-4.822-10.75-10.75-10.75H65.75z" />
      <path d="M215,255H75c-2.761,0-5-2.239-5-5v-70c0-2.761,2.239-5,5-5h140c2.761,0,5,2.239,5,5v70C220,252.761,217.761,255,215,255z M80,245h130v-60H80V245z" />
      <path d="M340,390H185c-2.761,0-5-2.238-5-5v-75c0-2.762,2.239-5,5-5h155c2.762,0,5,2.238,5,5v75C345,387.762,342.762,390,340,390z M190,380h145v-65H190V380z" />
      <path d="M340,180h-65c-2.762,0-5-2.239-5-5s2.238-5,5-5h65c2.762,0,5,2.239,5,5S342.762,180,340,180z" />
      <path d="M340,200h-65c-2.762,0-5-2.239-5-5s2.238-5,5-5h65c2.762,0,5,2.239,5,5S342.762,200,340,200z" />
      <path d="M340,220h-65c-2.762,0-5-2.239-5-5s2.238-5,5-5h65c2.762,0,5,2.239,5,5S342.762,220,340,220z" />
      <path d="M340,240h-65c-2.762,0-5-2.239-5-5s2.238-5,5-5h65c2.762,0,5,2.239,5,5S342.762,240,340,240z" />
      <path d="M365,125H160c-2.761,0-5-2.239-5-5s2.239-5,5-5h205c2.762,0,5,2.239,5,5S367.762,125,365,125z" />
    </svg>
  );
}

export default SMSCampaignIcon;
