export const SET_EMPLOYEE_APPROVAL_LIST = "SET_EMPLOYEE_APPROVAL_LIST";
export const SET_EMPLOYEE_LIST = "SET_EMPLOYEE_LIST";
export const UPDATE_EMPLOYEE_LIST = "UPDATE_EMPLOYEE_LIST";
export const DELETE_EMPLOYEE_LIST = "DELETE_EMPLOYEE_LIST";

export const SET_EMPLOYEE_DEPARTMENT_LIST = "SET_EMPLOYEE_DEPARTMENT_LIST";
export const UPDATE_EMPLOYEE_DEPARTMENT_LIST =
  "UPDATE_EMPLOYEE_DEPARTMENT_LIST";
export const ADD_EMPLOYEE_DEPARTMENT_LIST = "ADD_EMPLOYEE_DEPARTMENT_LIST";
export const SET_EMPLOYEE_REGION_LIST = "SET_EMPLOYEE_REGION_LIST";
export const UPDATE_EMPLOYEE_REGION_LIST = "UPDATE_EMPLOYEE_REGION_LIST";
export const ADD_EMPLOYEE_REGION_LIST = "ADD_EMPLOYEE_REGION_LIST";
