import {toast} from "react-toastify";
import {setEmployeeLoading} from ".";
import {employee_http} from "../../https/employee_http";
import {API_URL} from "../../utils/constants";
import * as actionTypes from "../actionTypes";
import fileDownload from "js-file-download";

export const fetchEmployeeLeadGenList = (status, from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/lead-gen?status=${status}&from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_LEAD_GEN_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const approveOrRejectLeadGen = (id, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/lead-gen/${id}`, data)
      .then(async () => {
        await dispatch({
          type: actionTypes.DELETE_EMPLOYEE_LEAD_GEN_LIST,
          payload: id,
        });
        await dispatch(setEmployeeLoading(false));
        toast.success(data.approved ? "Approved !" : "Rejected");
      })
      .catch(() => {
        toast.error(
          data.approved ? "Unable to approve !" : "Unable to reject !"
        );
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadLeadGenQR = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/lead-gen/qr`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, "lead-gen-qr.png");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
