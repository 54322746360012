import React, { Suspense } from "react";
import ErrorBoundary from "../../components/ErrorBoundary";

const suspenseHoc = (WrapComponent) => {
  function HocContent() {
    return (
      <Suspense
        fallback={
          <div
            className={`loader loader-${
              window.localStorage.theme ? window.localStorage.theme : "light"
            }`}
            data-text="Optipro"
          >
            Optipro
          </div>
        }
      >
        <ErrorBoundary>
          <WrapComponent />
        </ErrorBoundary>
      </Suspense>
    );
  }
  return HocContent;
};

export default suspenseHoc;
