import * as actionTypes from "../actionTypes";
import { SET_EMPLOYEE_ANALYTICS_ITEM_INACCURATE_FOOD_COST } from "../actionTypes";

let initialState = {
  salesOverview: {},
  salesByRegion: {},
  salesByBrand: {},
  timeSlotAnalysis: {},
  itemStock: {},
  stateDetails: {},
  reportCard: {},
  outletStatus: {},
  itemFoodCostReport: {},
  dayWiseNormalized: {},
  financeReport: [],
  payoutScheduleHealth: {},
  ledgerDetails: {},
  ledgerSummary: {},
  oosAnalytics: [],
  taSalesAnalytics: {},
  temporarySales: {
    totalGrossAmount: 0,
    totalNetAmount: 0,
    sales: [],
  },
  temporaryMom: {},
  temporaryBrands: {},
  missingDatesDetails: {},
  getDailyVisibility: {},
  salesAnalytics: {},
  getSalesAnlytics: {},
  getCrossedKPTRWT: {},
  getAvgMFR: {},
  cocoStores: {},
  oosList: {},
  paymentMethods: {},
  paymentDetails: {},
  dailyOpsEveryDay: {},
  ratingByBrand: {},
  oosDailyData: {},
  netSaleResId: {},
  poorRatedOrders: {},
  itemFoodCostInaccurateReport: {},
  uploadSwiggyStores: {},
  uploadSwiggyBrands: {},
};

export const analyticsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEE_ANALYTICS_SALES_OVERVIEW:
      return {
        ...state,
        salesOverview: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_SALES_BY_REGION:
      return {
        ...state,
        salesByRegion: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_SALES_BY_BRAND:
      return {
        ...state,
        salesByBrand: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_TIMESLOT_ANALYSIS:
      return {
        ...state,
        timeSlotAnalysis: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_ITEM_STOCK:
      return {
        ...state,
        itemStock: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_STATES:
      return {
        ...state,
        stateDetails: action.payload,
      };
    case actionTypes.SET_REPORT_CARD:
      return {
        ...state,
        reportCard: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_PARTNER_SALES_OVERVIEW:
      return {
        ...state,
        partnerSalesOverview: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_IR_SALES_OVERVIEW:
      return {
        ...state,
        IRSalesOverview: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_TA_NORMALISED_SALES:
      return {
        ...state,
        taSalesAnalytics: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_OUTLET_STATUS:
      return {
        ...state,
        outletStatus: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_ITEM_FOOD_COST:
      return {
        ...state,
        itemFoodCostReport: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_ITEM_INACCURATE_FOOD_COST:
      return {
        ...state,
        itemFoodCostInaccurateReport: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_PARTNER_SALES_FORECAST:
      return {
        ...state,
        partnerSalesForecast: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_MERCHANT_SALES_FORECAST:
      return {
        ...state,
        merchantSalesForecast: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_PARTNER_ANALYTICS:
      return {
        ...state,
        partnerAnalyticsData: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_ANALYTICS_BRAND_SALES_OVERVIEW:
      return {
        ...state,
        brandSalesOverview: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_PARTNER_GKSCORES:
      return {
        ...state,
        partnerGkScores: action.payload,
      };
    case actionTypes.SET_AVG_MFR:
      return {
        ...state,
        getAvgMFR: action.payload,
      };
    case actionTypes.SET_RISTA_BRANDS:
      return {
        ...state,
        temporaryBrands: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_DAY_WISE_NORMALIZED:
      return {
        ...state,
        dayWiseNormalized: action.payload,
      };
    case actionTypes.SET_FINANCE_REPORT_DETAILS:
      return {
        ...state,
        financeReport: action.payload,
      };
    case actionTypes.SET_PAYOUT_SCHEDULE_HEALTH_DETAILS:
      return {
        ...state,
        payoutScheduleHealth: action.payload,
      };
    case actionTypes.SET_DAILY_OPS_DETAILS:
      return {
        ...state,
        dailyOpsDetails: action.payload,
      };
    case actionTypes.SET_DAILY_OPS_EVERYDAY:
      return {
        ...state,
        dailyOpsEveryDay: action.payload,
      };
    case actionTypes.SET_NETSALE_RESID:
      return {
        ...state,
        netSaleResId: action.payload,
      };
    case actionTypes.SET_BRAND_RATING: {
      return {
        ...state,
        ratingByBrand: action.payload,
      };
    }
    case actionTypes.SET_OOS_DATEWISE: {
      return {
        ...state,
        oosDailyData: action.payload,
      };
    }
    case actionTypes.SET_RCA_DETAILS:
      return {
        ...state,
        dailyRCADetails: action.payload,
      };
    case actionTypes.SET_DAILY_OPS_ITEM_DETAILS:
      return {
        ...state,
        dailyOpsItemDetails: action.payload,
      };
    case actionTypes.SET_LEDGER_DETAILS:
      return {
        ...state,
        ledgerDetails: action.payload,
      };
    case actionTypes.SET_TEMPORARY_SALES:
      return {
        ...state,
        temporarySales: action.payload,
      };
    case actionTypes.SET_TEMPORARY_MOM:
      return {
        ...state,
        temporaryMom: action.payload,
      };
    case actionTypes.SET_LEDGER_SUMMARY:
      return {
        ...state,
        ledgerSummary: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_OOS_ANALYSIS:
      return {
        ...state,
        oosAnalytics: action.payload,
      };
    case actionTypes.SET_DAILY_OPS_MISSING_DATES:
      return {
        ...state,
        missingDatesDetails: action.payload,
      };
    case actionTypes.SET_SALES_ANALYTICS:
      return {
        ...state,
        salesAnalytics: action.payload,
      };
    case actionTypes.SET_TOTAL_SALE_ANALYTICS:
      return {
        ...state,
        getSalesAnlytics: action.payload,
      };
    case actionTypes.SET_CROSSED_KPT_RWT:
      return {
        ...state,
        getCrossedKPTRWT: action.payload,
      };
    case actionTypes.SET_DAILY_VISIBILITY_DETAILS:
      return {
        ...state,
        getDailyVisibility: action.payload,
      };
    case actionTypes.FETCH_COCO_STORES:
      return {
        ...state,
        cocoStores: action.payload,
      };
    case actionTypes.FETCH_SWIGGY_UPLOAD_STORES:
      return {
        ...state,
        uploadSwiggyStores: action.payload,
      };
    case actionTypes.FETCH_SWIGGY_UPLOAD_BRANDS:
      return {
        ...state,
        uploadSwiggyBrands: action.payload,
      };
    case actionTypes.FETCH_OOS_DATA:
      return {
        ...state,
        oosList: action.payload,
      };
    case actionTypes.PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case actionTypes.PAYMENT_DETAILS:
      return {
        ...state,
        paymentDetails: action.payload,
      };
    case actionTypes.POOR_RATED_ORDERS:
      return {
        ...state,
        poorRatedOrders: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        salesOverview: {},
        salesByRegion: {},
        salesByBrand: {},
        timeSlotAnalysis: {},
        itemStock: {},
        stateDetails: {},
        reportCard: {},
        itemFoodCostReport: {},
        outletStatus: {},
        partnerSalesForecast: {},
        merchantSalesForecast: {},
        partnerAnalyticsData: {},
        brandSalesOverview: {},
        partnerGkScores: [],
        dayWiseNormalized: {},
        financeReport: [],
        payoutScheduleHealth: {},
        ledgerDetails: {},
        ledgerSummary: {},
        oosAnalytics: [],
      };
    default:
      return state;
  }
};
