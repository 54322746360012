import * as actionTypes from "../actionTypes";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { push } from "connected-react-router";
import { employee_http } from "../../https/employee_http";
import { toast } from "react-toastify";
import { setEmployeeLoading } from "./miscActions";
import { user_http } from "../../https/user_http";

export const setUserLoginError = (err) => ({
  type: actionTypes.SET_USER_LOGIN_ERROR,
  payload: err,
});

export const userLogin = (user, pwd, redirectURL) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_LOGIN_LOADING, payload: true });
    setTimeout(() => {
      dispatch({ type: actionTypes.USER_LOGIN_LOADING, payload: false });
    }, 5000);
    axios
      .post(`${API_URL}/client/auth/login`, {
        username: user,
        password: pwd,
      })
      .then(async (res) => {
        await window.localStorage.setItem("user_auth_token", res.data.token);
        await window.localStorage.setItem(
          "user_refresh_token",
          res.data.refreshToken
        );
        await dispatch({
          type: actionTypes.SET_USER_LOGIN_DATA,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.USER_LOGIN_LOADING,
          payload: false,
        });
        await dispatch(push(redirectURL));
      })
      .catch(() => {
        dispatch({
          type: actionTypes.USER_LOGIN_LOADING,
          payload: false,
        });
        dispatch(setUserLoginError(true));
      });
  };
};

export const userByPassLogin = (token) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_LOGIN_LOADING, payload: true });
    axios
      .post(
        `${API_URL}/user/app/login-by-pass`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res) => {
        await window.localStorage.setItem("user_auth_token", res.data.token);
        await window.localStorage.setItem("app_login", true);
        await window.localStorage.setItem(
          "user_refresh_token",
          res.data.refreshToken
        );
        await dispatch({
          type: actionTypes.SET_USER_LOGIN_DATA,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.USER_LOGIN_LOADING,
          payload: false,
        });
        await dispatch(push("/user/brands"));
      })
      .catch(() => {
        dispatch({
          type: actionTypes.USER_LOGIN_LOADING,
          payload: false,
        });
        dispatch(push("/user-login"));
      });
  };
};

export const updateCreds = async (username, password, supplierId) => {
  console.log(username, password, supplierId);
  await employee_http.post(`${API_URL}/user/auth/createCreds`, {
    username,
    password,
    _id: supplierId,
  });
  toast.success("Added");
};

export const userLogout = () => {
  return async (dispatch) => {
    window.localStorage.removeItem("user_auth_token");
    window.localStorage.removeItem("user_refresh_token");
    await dispatch({ type: actionTypes.SET_USER_LOGOUT });
  };
};
