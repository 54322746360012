import * as actionTypes from "../actionTypes";

let initialState = {
  allFlags: [],
};

export const flagsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FLAGS:
      return {
        ...state,
        allFlags: action.payload,
      };
    case actionTypes.UPDATE_FLAG:
      let update_all = [...state.allFlags];
      const update_all_index = update_all.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_all_index > -1) update_all[update_all_index] = action.payload;
      return {
        ...state,
        allFlags: update_all,
      };
    case actionTypes.ADD_NEW_FLAG:
      return {
        ...state,
        allFlags: [action.payload, ...state.allFlags],
      };
    default:
      return state;
  }
};
