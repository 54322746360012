import * as actionTypes from "../actionTypes";

let initialState = {
  loginErr: false,
  apiLoading: false,
  loginData: {},
};

export const userAuthReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_LOGIN_ERROR:
      return {
        ...state,
        loginErr: action.payload,
      };
    case actionTypes.USER_LOGIN_LOADING:
      return {
        ...state,
        apiLoading: action.payload,
      };
    case actionTypes.SET_USER_LOGIN_DATA:
      return {
        ...state,
        loginData: action.payload,
      };
    case actionTypes.SET_USER_DATA:
      return {
        ...state,
        loginData: { ...state.loginData, ...action.payload },
      };
    case actionTypes.SET_USER_LOGOUT:
      return {
        loginErr: false,
        apiLoading: false,
        loginData: {},
      };

    default:
      return state;
  }
};
