import * as actionTypes from "../actionTypes";

let initialState = {
  optiproBrands: [],
  optiproLocations: [],
  getResIds: [],
  getBrandWiseRating: [],
  fetchDailyOps: [],
  oosData: [],
  optiproOderLevelData: [],
};

export const userDetailsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_OPTIPRO_BRAND:
      return {
        ...state,
        optiproBrands: action.payload,
      };
    case actionTypes.GET_ALL_OPTIPRO_LOCATION:
      return {
        ...state,
        optiproLocations: action.payload,
      };
    case actionTypes.FETCH_OPTIPRO_IDMAPPING:
      return {
        ...state,
        getResIds: action.payload,
      };
    case actionTypes.FETCH_OPTIPRO_BRANDWISE_RATING:
      return {
        ...state,
        getBrandWiseRating: action.payload,
      };

    case actionTypes.SET_OPTIPRO_DAILY_OPS_ITEM_DETAILS:
      return {
        ...state,
        fetchDailyOps: action.payload,
      };
    case actionTypes.OPTIPRO_FETCH_OOS_DATA:
      return {
        ...state,
        oosData: action.payload,
      };
    case actionTypes.OPTIPRO_ITEM_INACCURATE_FOOD_COST:
      return {
        ...state,
        optiproOderLevelData: action.payload,
      };

    default:
      return state;
  }
};
