import React from "react";
import MerchantHeader from "../../components/MerchantHeader";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DistributorAppBar from "../../components/DistributorAppBar";

const userHeaderHoc = (WrapComponent) => {
  function HocContent() {
    const theme = useTheme();
    const isLargeDevice = useMediaQuery(theme.breakpoints.up("sm"));
    return (
      <>
        {isLargeDevice ? (
          <div>
            <DistributorAppBar />
          </div>
        ) : (
          <MerchantHeader />
        )}
        <div
          style={
            isLargeDevice ? { marginLeft: "100px", marginTop: "16px" } : {}
          }
        >
          <WrapComponent />
        </div>
      </>
    );
  }
  return HocContent;
};

export default userHeaderHoc;
