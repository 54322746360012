import * as actionTypes from "../actionTypes";

let initialState = {
  tempDetails: {},
};

export const editRequestReducers = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.SET_TEMP_DETAILS:
        return {
          ...state,
          tempDetails: action.payload
        }
   
    default:
      return state;
  }
};
