import {toast} from "react-toastify";
import {setEmployeeLoading} from ".";
import {employee_http} from "../../https/employee_http";
import {API_URL} from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const getCustomWhatsappLogout = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/wp/logout`, {})
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_CUSTOM_WHATSAPP_STATUS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getCustomWhatsappStatus = (softLoad) => {
  return (dispatch) => {
    if (!softLoad) {
      dispatch(setEmployeeLoading(true));
    }
    employee_http
      .get(`${API_URL}/wp/status`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_CUSTOM_WHATSAPP_STATUS,
          payload: res.data,
        });
        if (!softLoad) {
          await dispatch(setEmployeeLoading(false));
        }
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SET_CUSTOM_WHATSAPP_STATUS,
          payload: { status: "BACKEND_ERROR" },
        });
        if (!softLoad) {
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          }
          dispatch(setEmployeeLoading(false));
        }
      });
  };
};

export const initCustomWhatsapp = (cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/wp/init`)
      .then(async (res) => {
        setTimeout(() => {
          dispatch(getCustomWhatsappStatus());
        }, 10000);
        cb();
        toast.success(res.data.status);
        await dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setEmployeeLoading(false));
      });
  };
};
