import React from "react";
import Drawer from "@mui/material/Drawer";
import { useStyles } from "../styles";
import { useLocation } from "react-router";
import MenuSelection from "../../MenuSelection";
import DashboardIcon from "../../../assets/header/svgs/DashboardIcon";
import ManageItemsIcon from "../../../assets/header/svgs/ManageItemsIcon";
import LogoutIcon from "../../../assets/header/svgs/LogoutIcon";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import EmployeeInfo from "./EmployeeInfo";
import PreferencesIcon from "../../../assets/header/svgs/PreferencesIcon";
import SalesReportIcon from "../../../assets/header/svgs/SalesReportIcon";
import AnalyticsIcon from "../../../assets/header/svgs/AnalyticsIcon";
import ManageTableIcon from "../../../assets/header/svgs/ManageTableIcon";
import ProfileIcon from "../../../assets/header/svgs/ProfileIcon";
import EmployeeIcon from "../../../assets/header/svgs/EmployeeIcon";
import CrmIcon from "../../../assets/header/svgs/CrmIcon";
import SubscriptionIcon from "../../../assets/header/svgs/SubscriptionIcon";
// import ActiveTableIcon from "../../../assets/header/svgs/ActiveTableIcon";
import RetentionIcon from "../../../assets/header/svgs/RetentionIcon";
import ExpensesIcon from "../../../assets/header/svgs/ExpensesIcon";
import PaymentIcon from "../../../assets/header/svgs/PaymentIcon";
import SocialVoucherIcon from "../../../assets/header/svgs/SocialVoucherIcon";
import MapIcon from "../../../assets/header/svgs/MapIcon";
import LoyaltyIcon from "../../../assets/header/svgs/LoyaltyIcon";
import SMSCampaignIcon from "../../../assets/header/svgs/SMSCampaignIcon";
// import WhatsappIcon from "../../../assets/header/svgs/WhatsappIcon";
import LeadGenIcon from "../../../assets/header/svgs/LeadGenIcon";
import GlideIcon from "../../../assets/header/svgs/GlideIcon";
import { employeeLogout } from "../../../redux/actions";
import DesignIcon from "../../../assets/header/svgs/DesignIcon";

function MenuDrawer({ open, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const roles = useSelector((state) => state.employeeAuth.loginData?.role);
  const location = useLocation();

  let menus = [
    // {
    //   title: "",
    //   visible: true,
    //   elements: [
    //     {
    //       label: "Home",
    //       visible: true,
    //       active: location.pathname === "/employee/home",
    //       handleMenu: () => {
    //         handleClose();
    //         dispatch(push("/employee/home"));
    //       },
    //       icon: <DashboardIcon />,
    //     },
    //   ],
    // },
    {
      title: "Employee",
      visible: roles?.includes("hr") || roles?.includes("admin"),
      elements: [
        {
          label: "Employees",
          active: location.pathname === "/employee/employee-list",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/employee-list"));
          },
          visible: roles?.includes("hr") || roles?.includes("admin"),
          icon: <EmployeeIcon />,
        },
        {
          label: "Employee onboard",
          active: location.pathname === "/employee/employee-onboard",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/employee-onboard"));
          },
          visible: roles?.includes("hr"),
          icon: <ProfileIcon />,
        },
      ],
    },
    {
      title: "Admin",
      visible: roles?.includes("temporary"),
      elements: [
        {
          label: "Sales",
          active: location.pathname === "/employee/temporary/admin/sales",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/temporary/admin/sales"));
          },
          visible: roles?.includes("temporary"),
          icon: <EmployeeIcon />,
        },
        {
          label: "MoM",
          active: location.pathname === "/employee/temporary/admin/mom",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/temporary/admin/mom"));
          },
          visible: roles?.includes("temporary"),
          icon: <EmployeeIcon />,
        },
      ],
    },
    {
      title: "Sales",
      visible:
        roles?.includes("sales") ||
        roles?.includes("area manager") ||
        roles?.includes("sales head") ||
        roles?.includes("admin") ||
        roles?.includes("accounts") ||
        roles?.includes("crm"),

      elements: [
        {
          label: "Partner onboarding",
          active:
            location.pathname === "/employee/initiate-partner-on-boarding",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/initiate-partner-on-boarding"));
          },
          visible:
            roles?.includes("sales") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Partner Status",
          active: location.pathname === "/employee/partner-status",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner-status"));
          },
          visible:
            roles?.includes("sales") ||
            roles?.includes("sales head") ||
            roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Partner List",
          active: location.pathname === "/employee/partner-list",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner-list"));
          },
          visible: true,
          icon: <ManageTableIcon />,
        },
      ],
    },
    {
      title: "Area Manager",
      visible:
        roles?.includes("area manager") || roles?.includes("store manager"),
      elements: [
        {
          label: "Partner approval",
          active:
            location.pathname === "/employee/partner-approval-area-manager",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner-approval-area-manager"));
          },
          visible: roles?.includes("area manager"),
          icon: <LoyaltyIcon />,
        },
        {
          label: "Partner Status",
          active: location.pathname === "/employee/partner-status-area-manager",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner-status-area-manager"));
          },
          visible: roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "RCA",
          active: location.pathname === "/employee/partner/rca",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner/rca"));
          },
          visible:
            roles?.includes("area manager") || roles?.includes("store manager"),
          icon: <SalesReportIcon />,
        },
      ],
    },
    {
      title: "Operations",
      visible: roles?.includes("operations head"),
      elements: [
        {
          label: "Partner approval",
          active:
            location.pathname === "/employee/partner-approval-operations-head",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner-approval-operations-head"));
          },
          visible: roles?.includes("operations head"),
          icon: <LoyaltyIcon />,
        },
      ],
    },
    {
      title: "Marketing",
      visible: roles?.includes("admin"),
      elements: [
        {
          label: "Lead gen",
          active: location.pathname === "/employee/lead-gen",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/lead-gen"));
          },
          visible: roles?.includes("admin"),
          icon: <LeadGenIcon />,
        },
      ],
    },
    {
      title: "Aggregator",
      visible:
        roles?.includes("admin") ||
        roles?.includes("aggregator") ||
        roles?.includes("area manager"),
      elements: [
        {
          label: "Partner Onboarding/Training",
          active: location.pathname === "/employee/aggregator-onboarding",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/aggregator-onboarding"));
          },
          visible: roles?.includes("aggregator"),
          icon: <SocialVoucherIcon />,
        },
        {
          label: "FSSAI Expiry",
          active: location.pathname === "/employee/fssai-expiry",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/fssai-expiry"));
          },
          visible:
            roles?.includes("aggregator") || roles?.includes("area manager"),
          icon: <SocialVoucherIcon />,
        },
        {
          label: "Partner Discounts",
          active: location.pathname === "/employee/aggregator-discount",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/aggregator-discount"));
          },
          visible: roles?.includes("aggregator"),
          icon: <CrmIcon />,
        },
        {
          label: "Manage Mails",
          active: location.pathname === "/employee/manage-mails",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/manage-mails"));
          },
          visible: roles?.includes("aggregator"),
          icon: <SMSCampaignIcon />,
        },
        {
          label: "Launch Tracker",
          active: location.pathname === "/employee/launch-tracker",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/launch-tracker"));
          },
          visible: roles?.includes("aggregator"),
          icon: <SocialVoucherIcon />,
        },
        {
          label: "Pre-Launch Summary",
          active: location.pathname === "/employee/pre-launch-summary",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/pre-launch-summary"));
          },
          visible: roles?.includes("aggregator"),
          icon: <SocialVoucherIcon />,
        },
        // {
        //   label: "Rista Mapping",
        //   active: location.pathname === "/employee/rista-mapping",
        //   handleMenu: () => {
        //     handleClose();
        //     dispatch(push("/employee/rista-mapping"));
        //   },
        //   visible: roles?.includes("aggregator"),
        //   icon: <SMSCampaignIcon />,
        // },
        {
          label: "Aggregation mail details",
          active: location.pathname === "/employee/aggregation-mail-details",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/aggregation-mail-details"));
          },
          visible: roles?.includes("admin") || roles?.includes("aggregator"),
          icon: <SalesReportIcon />,
        },
        {
          label: "PO Details",
          active: location.pathname === "/employee/po-details",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/po-details"));
          },
          visible:
            roles?.includes("admin") ||
            roles?.includes("aggregator") ||
            roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "CPC Requests",
          active: location.pathname === "/employee/cpc-requests",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/cpc-requests"));
          },
          visible: roles?.includes("admin"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Partner Sales Forecast",
          active: location.pathname === "/employee/partner/forecast",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner/forecast"));
          },
          visible:
            roles?.includes("area manager") || roles?.includes("aggregator"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Learning Resources",
          active: location.pathname === "/employee/partner/learning-resources",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner/learning-resources"));
          },
          visible:
            roles?.includes("area manager") || roles?.includes("aggregator"),
          icon: <SalesReportIcon />,
        },
        {
          label: "ZS-Daily",
          active: location.pathname === "/employee/zs-daily",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/zs-daily"));
          },
          visible: roles?.includes("aggregator"),
          icon: <SalesReportIcon />,
        },
        {
          label: "CPC",
          active: location.pathname === "/employee/cpc",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/cpc"));
          },
          visible: roles?.includes("aggregator"),
          icon: <SalesReportIcon />,
        },
        {
          label: "New Brand Addition",
          active: location.pathname === "/employee/new-brand-addition",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/new-brand-addition"));
          },
          visible: roles?.includes("aggregator"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Whatsapp Messages",
          active: location.pathname === "/employee/wp-messages",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/wp-messages"));
          },
          visible: roles?.includes("aggregator"),
          icon: <SalesReportIcon />,
        },
      ],
    },
    {
      title: "ZS Metrics",
      visible: roles?.includes("aggregator"),
      elements: [
        {
          label: "ZS Upload",
          active: location.pathname === "/employee/zs-upload",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/zs-upload"));
          },
          visible: roles?.includes("aggregator"),
          icon: <PreferencesIcon />,
        },
        {
          label: "ZS Summary",
          active: location.pathname === "/employee/zs-summary",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/zs-summary"));
          },
          visible: roles?.includes("aggregator"),
          icon: <ManageTableIcon />,
        },
        {
          label: "IGCC Upload",
          active: location.pathname === "/employee/igcc-upload",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/igcc-upload"));
          },
          visible: roles?.includes("aggregator"),
          icon: <PreferencesIcon />,
        },
        {
          label: "ZS Partner Health",
          active: location.pathname === "/employee/zs-partner-health",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/zs-partner-health"));
          },
          visible: roles?.includes("aggregator"),
          icon: <ManageTableIcon />,
        },
        {
          label: "ZS Partner Health Details",
          active: location.pathname === "/employee/zs-partner-health-details",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/zs-partner-health-details"));
          },
          visible: roles?.includes("aggregator"),
          icon: <ManageTableIcon />,
        },
        {
          label: "DO WIP",
          active: location.pathname === "/employee/daily-ops/wip",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/daily-ops/wip"));
          },
          visible:
            roles?.includes("aggregator") || roles?.includes("store manager"),
          icon: <ManageTableIcon />,
        },
        {
          label: "ZS Partner KPT/RWT",
          active: location.pathname === "/employee/zs-partner-kpt-rwt",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/zs-partner-kpt-rwt"));
          },
          visible:
            roles?.includes("aggregator") || roles?.includes("store manager"),
          icon: <ManageTableIcon />,
        },
        {
          label: "Crossed KPT/RWT",
          active: location.pathname === "/employee/sales/crossed-kpt-rwt",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/crossed-kpt-rwt"));
          },
          visible:
            roles?.includes("admin") ||
            roles?.includes("aggregator") ||
            roles?.includes("area manager") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("accounts") ||
            roles?.includes("operations head"),

          icon: <ManageTableIcon />,
        },
        {
          label: "OOS",
          active: location.pathname === "/employee/optipro/oos",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/optipro/oos"));
          },
          visible:
            roles?.includes("admin") ||
            roles?.includes("aggregator") ||
            roles?.includes("area manager") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("accounts") ||
            roles?.includes("operations head"),
          icon: <ManageTableIcon />,
        },
        {
          label: "OOS-WIP",
          active: location.pathname === "/employee/optipro/oos-wip",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/optipro/oos-wip"));
          },
          visible:
            roles?.includes("admin") ||
            roles?.includes("aggregator") ||
            roles?.includes("area manager") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("accounts") ||
            roles?.includes("operations head"),
          icon: <ManageTableIcon />,
        },
        // {
        //   label: "OOS-WIP",
        //   active: location.pathname === "/employee/sales/payment",
        //   handleMenu: () => {
        //     handleClose();
        //     dispatch(push("/employee/sales/payment"));
        //   },
        //   visible:
        //     roles?.includes("admin") ||
        //     roles?.includes("aggregator") ||
        //     roles?.includes("area manager") ||
        //     roles?.includes("sales head") ||
        //     roles?.includes("store manager") ||
        //     roles?.includes("accounts") ||
        //     roles?.includes("operations head"),
        //   icon: <ManageTableIcon />,
        // },
      ],
    },
    {
      title: "Inventory",
      visible:
        roles?.includes("aggregator") ||
        roles?.includes("inventory") ||
        roles?.includes("admin") ||
        roles?.includes("HP Warehouse") ||
        roles?.includes("Mumbai GK CK"),
      elements: [
        // {
        //   label: "Configure stock",
        //   active: location.pathname === "/employee/inventory/configure-stock",
        //   handleMenu: () => {
        //     handleClose();
        //     dispatch(push("/employee/inventory/configure-stock"));
        //   },
        //   visible: roles?.includes("aggregator"),
        //   icon: <PreferencesIcon />,
        // },
        {
          label: "Product",
          active: location.pathname === "/employee/inventory/product",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/inventory/product"));
          },
          visible: roles?.includes("inventory"),
          icon: <PreferencesIcon />,
        },
        {
          label: "Suppliers",
          active: location.pathname === "/employee/inventory/suppliers",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/inventory/suppliers"));
          },
          visible: roles?.includes("inventory"),
          icon: <PreferencesIcon />,
        },
        {
          label: "Orders",
          active: location.pathname === "/employee/inventory/orders",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/inventory/orders"));
          },
          visible: roles?.includes("inventory"),
          icon: <PreferencesIcon />,
        },

        {
          label: "Delivery Notes",
          active: location.pathname === "/employee/inventory/delivery-notes",
          handleMenu: () => {
            dispatch(push("/employee/inventory/delivery-notes"));
          },
          visible:
            roles?.includes("inventory") || roles?.includes("HP Warehouse"),
          icon: <PreferencesIcon />,
        },
        {
          label: "WIP",
          active: location.pathname === "/employee/inventory/wip",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/inventory/wip"));
          },
          visible: roles?.includes("inventory"),
          icon: <PreferencesIcon />,
        },
        {
          label: "Inventory/DN/GRN",
          active: location.pathname === "/employee/inventory/inventory/dn/grn",
          handleMenu: () => {
            dispatch(push("/employee/inventory/inventory/dn/grn"));
          },
          visible: roles?.includes("inventory"),
          icon: <PreferencesIcon />,
        },
        {
          label: "PO Credit Approval",
          active:
            location.pathname === "/employee/inventory/payment-confirmation",
          handleMenu: () => {
            dispatch(push("/employee/inventory/payment-confirmation"));
          },
          visible: roles?.includes("admin"),
          icon: <PreferencesIcon />,
        },
      ],
    },
    {
      title: "Analytics",
      visible:
        roles?.includes("aggregator") ||
        roles?.includes("operations head") ||
        roles?.includes("admin") ||
        roles?.includes("accounts") ||
        roles?.includes("sales head") ||
        roles?.includes("area manager"),
      elements: [
        {
          label: "Sales Overview",
          active: location.pathname === "/employee/sales/overview",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/overview"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "Sales Summary",
          active: location.pathname === "/employee/sales-summary",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales-summary"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <ManageTableIcon />,
        },
        {
          label: "Sales Performance",
          active: location.pathname === "/employee/sales-performance",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales-performance"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <ManageTableIcon />,
        },
        {
          label: "Normalised Sale",
          active: location.pathname === "/employee/sales/normalised",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/normalised"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "TA Normalised",
          active: location.pathname === "/employee/sales/ta-normalised",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/ta-normalised"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "Day wise Sale",
          active: location.pathname === "/employee/sales/normalised/day-wise",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/normalised/day-wise"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "Partner Normalised",
          active: location.pathname === "/employee/sales/partner",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/partner/normalised"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "IR Normalised",
          active: location.pathname === "/employee/sales/ir/normalised",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/ir/normalised"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "Brand Normalised",
          active: location.pathname === "/employee/sales/brand/normalised",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/brand/normalised"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "Sales By Region",
          active: location.pathname === "/employee/sales/region",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/region"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Sales By Brand",
          active: location.pathname === "/employee/sales/sales-by-brand",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/sales-by-brand"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "Sales By State",
          active: location.pathname === "/employee/sales/sales-by-state",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/sales-by-state"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "Items in Stock",
          active: location.pathname === "/employee/item-stock/item-in-stock",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/item-stock/item-in-stock"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "OOS Analysis",
          active: location.pathname === "/employee/item-stock/oos-analysis",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/item-stock/oos-analysis"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "OOS Sale loss",
          active: location.pathname === "/employee/item-stock/oos-sale-loss",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/item-stock/oos-sale-loss"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Dashboard",
          active: location.pathname === "/employee/sales/review-analysis",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/review-analysis"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store head") ||
            roles?.includes("area manager"),
          icon: <ManageTableIcon />,
        },
        {
          label: "Reviews",
          active: location.pathname === "/employee/sales/reviews",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/reviews"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store head") ||
            roles?.includes("area manager"),
          icon: <ManageTableIcon />,
        },
        {
          label: "Logs",
          active: location.pathname === "/employee/sales/logs",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/logs"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store head") ||
            roles?.includes("area manager"),
          icon: <ManageTableIcon />,
        },
        {
          label: "Hourly sales",
          active: location.pathname === "/employee/sales/hourly-sales",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/hourly-sales"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <PaymentIcon />,
        },
        {
          label: "Timeslot Analysis",
          active: location.pathname === "/employee/sales/timeslot",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/timeslot"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <PaymentIcon />,
        },
        {
          label: "Restaurant Health",
          active: location.pathname === "/employee/sales/restaurant/health",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/restaurant/health"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("area manager"),
          icon: <PaymentIcon />,
        },
        {
          label: "Sales Analytic",
          active: location.pathname === "/employee/sales/restaurant/analytics",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/restaurant/analytics"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("area manager"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "Food Costing",
          active: location.pathname === "/employee/sales/food-costing",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/food-costing"));
          },
          visible:
            roles?.includes("sales head") ||
            roles?.includes("aggregator") ||
            roles?.includes("area manager") ||
            roles?.includes("operations head") ||
            roles?.includes("store manager") ||
            roles?.includes("Food quality/Customer feedback support"),
          icon: <AnalyticsIcon />,
        },
        {
          label: "Profit Center",
          active: location.pathname === "/employee/sales/profit-center",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/profit-center"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Review Management",
          active:
            location.pathname === "/employee/sales/restaurant-health/daily-ops",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/restaurant-health/daily-ops"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Payout Earnings",
          active: location.pathname === "/employee/payout-earnings",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/payout-earnings"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },

        {
          label: "Partner Analytics",
          active: location.pathname === "/employee/partner/analytics",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner/analytics"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Report Card",
          active: location.pathname === "/employee/partner/report-card",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner/report-card"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("store manager") ||
            roles?.includes("area manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Partner Ranking",
          active: location.pathname === "/employee/partner/gkscores",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner/gkscores"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Discount",
          active: location.pathname === "/employee/partner/discount",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner/discount"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("operations head") ||
            roles?.includes("admin") ||
            roles?.includes("accounts") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <ManageItemsIcon />,
        },
      ],
    },
    {
      title: "Marketing",
      visible:
        roles?.includes("marketing") ||
        roles?.includes("sales head") ||
        roles?.includes("sales") ||
        roles?.includes("area manager") ||
        roles?.includes("accounts"),
      elements: [
        {
          label: "Upload marketing leads",
          active: location.pathname === "/employee/marketing-leads-upload",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/marketing-leads-upload"));
          },
          visible: roles?.includes("marketing"),
          icon: <DesignIcon />,
        },
        {
          label: "Blogger Order Charges",
          active: location.pathname === "/employee/blogger-order-charges",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/blogger-order-charges"));
          },
          visible: roles?.includes("marketing"),
          icon: <DesignIcon />,
        },
        {
          label: "Marketing leads",
          active: location.pathname === "/employee/marketing-leads",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/marketing-leads"));
          },
          visible: roles?.includes("sales head"),
          icon: <DesignIcon />,
        },
        {
          label: "Marketing leads info",
          active: location.pathname === "/employee/marketing-leads-info",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/marketing-leads-info"));
          },
          visible: roles?.includes("sales"),
          icon: <DesignIcon />,
        },
        {
          label: "Marketing Stories",
          active: location.pathname === "/employee/marketing-stories",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/marketing-stories"));
          },
          visible: roles?.includes("marketing"),
          icon: <DesignIcon />,
        },
        {
          label: "Blogger Order",
          active: location.pathname === "/employee/blogger-order",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/blogger-order"));
          },
          visible:
            roles?.includes("marketing") ||
            roles?.includes("area manager") ||
            roles?.includes("accounts"),
          icon: <DesignIcon />,
        },
        {
          label: "Lead Gen",
          active: location.pathname === "/employee/lead-gens",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/lead-gens"));
          },
          visible:
            roles?.includes("marketing") ||
            roles?.includes("area manager") ||
            roles?.includes("accounts"),
          icon: <DesignIcon />,
        },
        {
          label: "Marketing Lead Report",
          active: location.pathname === "/employee/marketing-lead-report",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/marketing-lead-report"));
          },
          visible:
            roles?.includes("marketing") ||
            roles?.includes("sales") ||
            roles?.includes("sales head"),
          icon: <DesignIcon />,
        },
      ],
    },
    {
      title: "Accounts",
      visible:
        roles?.includes("accounts") ||
        roles?.includes("sales") ||
        roles?.includes("sales head") ||
        roles?.includes("admin") ||
        roles?.includes("area manager"),
      elements: [
        {
          label: "Manage partner payments",
          active: location.pathname === "/employee/partner-payments",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner-payments"));
          },
          visible:
            roles?.includes("accounts") ||
            roles?.includes("sales") ||
            roles?.includes("sales head") ||
            roles?.includes("purchase") ||
            roles?.includes("area manager"),
          icon: <PaymentIcon />,
        },
        {
          label: "Employee Bank details",
          active: location.pathname === "/employee/employee-bank-details",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/employee-bank-details"));
          },
          visible: roles?.includes("accounts"),
          icon: <RetentionIcon />,
        },
        {
          label: "Partner bank details",
          active: location.pathname === "/employee/partner-bank-details",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner-bank-details"));
          },
          visible: roles?.includes("accounts"),
          icon: <RetentionIcon />,
        },
        {
          label: "Payout Reports",
          active: location.pathname === "/employee/payout-reports",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/payout-reports"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Payout Summary",
          active: location.pathname === "/employee/payout-summary",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/payout-summary"));
          },
          visible:
            roles?.includes("accounts") || roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Deduction Summary",
          active: location.pathname === "/employee/payout-deduction-summary",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/payout-deduction-summary"));
          },
          visible:
            roles?.includes("accounts") || roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Payout Transactions",
          active: location.pathname === "/employee/payout-transactions",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/payout-transactions"));
          },
          visible:
            roles?.includes("accounts") || roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Payout Downloads",
          active: location.pathname === "/employee/payout-downloads",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/payout-downloads"));
          },
          visible:
            roles?.includes("accounts") || roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Cancelled Orders",
          active: location.pathname === "/employee/cancelled-payout",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/cancelled-payout"));
          },
          visible:
            roles?.includes("accounts") || roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Blogger Order Reco",
          active: location.pathname === "/employee/blogger-order-reco",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/blogger-order-reco"));
          },
          visible:
            roles?.includes("accounts") || roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Cash Back",
          active: location.pathname === "/employee/cashback",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/cashback"));
          },
          visible:
            roles?.includes("accounts") || roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Edit Request",
          active: location.pathname === "/employee/edit-request",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/edit-request"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Revenue Share",
          active: location.pathname === "/employee/revenue-share",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/revenue-share"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "New Brand Requests",
          active:
            location.pathname === "/employee/config/brand-addition-requests",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/config/brand-addition-requests"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },

        {
          label: "UPI Recon",
          active: location.pathname === "/employee/sales/payment",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales/payment"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Razorpay Recon",
          active: location.pathname === "/employee/razorpay-recon",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/razorpay-recon"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "ZS Finance",
          active: location.pathname === "/employee/zs-finance",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/zs-finance"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Alternate Rista",
          active: location.pathname === "/employee/alternate-rista",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/alternate-rista"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Aggregator Recon",
          active: location.pathname === "/employee/aggregator-recon",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/aggregator-recon"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Petty Cash Responses",
          active: location.pathname === "/employee/pettycash/responses",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/pettycash/responses"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "GK Investments",
          active: location.pathname === "/employee/gk_investments",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/gk_investments"));
          },
          visible:
            roles?.includes("marketing") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Rupifi GMV",
          active: location.pathname === "/employee/rupifi/gmv",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/rupifi/gmv"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "CPC",
          active: location.pathname === "/employee/cpc/approved",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/cpc/approved"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Config",
          active: location.pathname === "/employee/config/software-fees",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/config/software-fees"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Inventory",
          active: location.pathname === "/employee/account/inventory",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/account/inventory"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },

        {
          label: "Invoices",
          active: location.pathname === "/employee/invoice/payments",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/invoice/payments"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Finance Report upload",
          active: location.pathname === "/employee/finance-report-upload",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/finance-report-upload"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Tax Report upload",
          active: location.pathname === "/employee/tax-report-upload",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/tax-report-upload"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Adjustment Report upload",
          active: location.pathname === "/employee/adjustment-report-upload",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/adjustment-report-upload"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Ledger summary",
          active: location.pathname === "/employee/ledger-summary",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/ledger-summary"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Ledger upload/view",
          active: location.pathname === "/employee/ledger-upload-or-view",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/ledger-upload-or-view"));
          },
          visible: roles?.includes("accounts"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Estimated Fund Inflow",
          active: location.pathname === "/employee/estimated-fund-inflow",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/estimated-fund-inflow"));
          },
          visible:
            roles?.includes("accounts") ||
            roles?.includes("sales") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <PaymentIcon />,
        },
        {
          label: "New Brands",
          active: location.pathname === "/employee/new-brands",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/new-brands"));
          },
          visible:
            roles?.includes("accounts") ||
            roles?.includes("sales") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Dotpe Reco",
          active: location.pathname === "/employee/dot-pay-reco",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/dot-pay-reco"));
          },
          visible:
            roles?.includes("accounts") ||
            roles?.includes("sales") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "GK Margins",
          active: location.pathname === "/employee/gk-margins",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/gk-margins"));
          },
          visible:
            roles?.includes("accounts") ||
            roles?.includes("sales") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
        {
          label: "Inventory/DN/GRN",
          active: location.pathname === "/employee/Inventory-DN-GRM",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/Inventory-DN-GRM"));
          },
          visible:
            roles?.includes("accounts") ||
            roles?.includes("sales") ||
            roles?.includes("sales head") ||
            roles?.includes("area manager"),
          icon: <SalesReportIcon />,
        },
      ],
    },
    {
      title: "CRM",
      visible: roles?.includes("crm"),
      elements: [
        {
          label: "Tickets overview",
          active: location.pathname === "/employee/tickets-overview",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/tickets-overview"));
          },
          visible: roles?.includes("crm"),
          icon: <LoyaltyIcon />,
        },
        {
          label: "Delayed Reports",
          active: location.pathname === "/employee/delayed-reports",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/delayed-reports"));
          },
          visible: roles?.includes("crm"),
          icon: <LoyaltyIcon />,
        },
        {
          label: "Manage News",
          active: location.pathname === "/employee/news",
          handleMenu: () => {
            dispatch(push("/employee/news"));
          },
          visible: roles?.includes("crm"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "FAQ Bank (WIP)",
          active: location.pathname === "/employee/faq-bank",
          handleMenu: () => {
            dispatch(push("/employee/faq-bank"));
          },
          visible: roles?.includes("crm"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Ticket Category",
          active: location.pathname === "/employee/tickets-categories",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/tickets-categories"));
          },
          visible: roles?.includes("crm"),
          icon: <LoyaltyIcon />,
        },
        {
          label: "Dashboard Access",
          active: location.pathname === "/employee/dashboard-access",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/dashboard-access"));
          },
          visible: roles?.includes("crm"),
          icon: <LoyaltyIcon />,
        },
      ],
    },
    {
      title: "Tickets",
      visible: true,
      elements: [
        {
          label: "All Tickets",
          active: location.pathname === "/employee/all-tickets",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/all-tickets"));
          },
          visible: roles?.includes("crm") || roles?.includes("area manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "My Tickets",
          active: location.pathname === "/employee/my-tickets",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/my-tickets"));
          },
          visible: true,
          icon: <ManageItemsIcon />,
        },
        {
          label: "Assigned Tickets",
          active: location.pathname === "/employee/assigned-tickets",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/assigned-tickets"));
          },
          visible: true,
          icon: <SalesReportIcon />,
        },
        {
          label: "Ticket Category",
          active: location.pathname === "/employee/assigned-tickets",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/assigned-tickets"));
          },
          visible: true,
          icon: <SalesReportIcon />,
        },
        {
          label: "FAQs (WIP)",
          active: location.pathname === "/employee/faqs",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/faqs"));
          },
          visible: true,
          icon: <SalesReportIcon />,
        },
        {
          label: "Partner Tech Support",
          active: location.pathname === "/employee/tech-support",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/tech-support"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Support Tracker",
          active: location.pathname === "/employee/support-tracker",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/support-tracker"));
          },
          visible:
            roles?.includes("aggregator") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
      ],
    },
    {
      title: "WIP",
      visible: true,
      elements: [
        {
          label: "Reviews",
          active: location.pathname === "/employee/reviews",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/reviews"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },

        {
          label: "Daily Ops",
          active: location.pathname === "/employee/daily-ops",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/daily-ops"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Daily Ops-New",
          active: location.pathname === "/employee/daily-ops2",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/daily-ops2"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Live Tracking",
          active: location.pathname === "/employee/live-tracking",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/live-tracking"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Business Report",
          active: location.pathname === "/employee/business-report",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/business-report"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Sales Analytics",
          active: location.pathname === "/employee/sales-analytics",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/sales-analytics"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Partner Tech Support",
          active: location.pathname === "/employee/tech-support/wip",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/tech-support/wip"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Support Tracker",
          active: location.pathname === "/employee/support-tracker/wip",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/support-tracker/wip"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Poor Rated Reviews",
          active: location.pathname === "/employee/optipro/poor-review",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/optipro/poor-review"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Sales Dashboard",
          active: location.pathname === "/employee/optipro/sales-dashboard",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/optipro/sales-dashboard"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Hourly Sales",
          active: location.pathname === "/employee/hourly-sales",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/hourly-sales"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Visibility",
          active: location.pathname === "/employee/hourly-sales",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/optipro/visibility"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          icon: <ManageItemsIcon />,
        },
        {
          label: "Review summary",
          active: location.pathname === "/employee/optipro/review-summary",
          handleMenu: () => {
            dispatch(push("/employee/optipro/review-summary"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Reviews",
          active: location.pathname === "/employee/optipro/reviews",
          handleMenu: () => {
            dispatch(push("/employee/optipro/reviews"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Logs",
          active: location.pathname === "/employee/optipro/logs",
          handleMenu: () => {
            dispatch(push("/employee/optipro/logs"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Onboarding",
          active: location.pathname === "/employee/optipro/onboarding",
          handleMenu: () => {
            dispatch(push("/employee/optipro/onboarding"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Schedulers",
          active: location.pathname === "/employee/optipro/schedulers",
          handleMenu: () => {
            dispatch(push("/employee/optipro/schedulers"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Confirm Schedulers",
          active: location.pathname === "/employee/optipro/confirm-schedulers",
          handleMenu: () => {
            dispatch(push("/employee/optipro/confirm-schedulers"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Order Level Data",
          active: location.pathname === "/employee/optipro/order-level-data",
          handleMenu: () => {
            dispatch(push("/employee/optipro/order-level-data"));
          },
          visible:
            roles?.includes("crm") ||
            roles?.includes("aggregator") ||
            roles?.includes("accounts") ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
        },
        {
          label: "Crons",
          active: location.pathname === "/employee/optipro/cron",
          handleMenu: () => {
            dispatch(push("/employee/optipro/cron"));
          },
          visible:
            roles?.includes("tech"),
        },
      ],
    },
    {
      title: "Academy",
      visible: true,
      elements: [
        {
          label: "Traning Documents",
          active:
            location.pathname === "/employee/academy" ||
            roles?.includes("area manager") ||
            roles?.includes("store manager"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/academy"));
          },
          visible:
            roles?.includes("aggregator") || roles?.includes("area manager"),
          icon: <ManageItemsIcon />,
        },
      ],
    },
    {
      title: "Extras",
      visible: true,
      elements: [
        {
          label: "Property",
          visible: roles?.includes("admin"),
          active: location.pathname === "/employee/property",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/property"));
          },
          icon: <MapIcon />,
        },
        {
          label: "Department",
          visible: roles?.includes("admin"),
          active: location.pathname === "/employee/department",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/department"));
          },
          icon: <DesignIcon />,
        },
        {
          label: "Region",
          visible: roles?.includes("admin") || roles?.includes("aggregator"),
          active: location.pathname === "/employee/region",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/region"));
          },
          icon: <ExpensesIcon />,
        },
        {
          label: "Brands",
          visible: roles?.includes("admin") || roles?.includes("aggregator"),
          active: location.pathname === "/employee/brands",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/brands"));
          },
          icon: <ExpensesIcon />,
        },
        {
          label: "Cuisine",
          visible: roles?.includes("admin") || roles?.includes("aggregator"),
          active: location.pathname === "/employee/cuisine",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/cuisine"));
          },
          icon: <ExpensesIcon />,
        },
        {
          label: "Partner Buckets",
          visible: roles?.includes("admin") || roles?.includes("aggregator"),
          active: location.pathname === "/employee/partner-bucket",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/partner-bucket"));
          },
          icon: <ExpensesIcon />,
        },
        {
          label: "Document Uploader",
          visible: roles?.includes("admin") || roles?.includes("aggregator"),
          active: location.pathname === "/employee/document-uploader",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/document-uploader"));
          },
          icon: <SubscriptionIcon />,
        },
        {
          label: "Banners",
          visible: roles?.includes("admin"),
          active: location.pathname === "/employee/banners",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/banners"));
          },
          icon: <GlideIcon />,
        },
        {
          label: "Upload Status",
          visible: true,
          active: location.pathname === "/employee/upload-status",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/upload-status"));
          },
          icon: <SubscriptionIcon />,
        },
        {
          label: "Logs",
          visible: true,
          active: location.pathname === "/employee/logs",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/logs"));
          },
          icon: <SubscriptionIcon />,
        },
        {
          label: "Flags",
          visible: roles?.includes("admin"),
          active: location.pathname === "/employee/flags",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/flags"));
          },
          icon: <SubscriptionIcon />,
        },
        {
          label: "Scheduler Logs",
          visible: roles?.includes("admin"),
          active: location.pathname === "/employee/scheduler-logs",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/scheduler-logs"));
          },
          icon: <SubscriptionIcon />,
        },
      ],
    },
    {
      title: "JDs",
      visible: true,
      elements: [
        {
          label: "All JDs",
          visible: true,
          active: location.pathname === "/employee/jds",
          handleMenu: () => {
            handleClose();
            dispatch(push("/employee/jds"));
          },

          icon: <SubscriptionIcon />,
        },
      ],
    },
    {
      title: "",
      visible: true,
      elements: [
        {
          label: "Logout",
          active: false,
          visible: true,
          handleMenu: () => {
            handleClose();
            dispatch(employeeLogout());
          },
          icon: <LogoutIcon />,
        },
      ],
    },
  ];

  return (
    <Drawer open={open} onClose={handleClose}>
      <div className={classes.drawerRoot}>
        <EmployeeInfo />
        {menus
          .filter((a) => a.visible)
          .map((subMenu, idx) => (
            <div key={idx} style={{ marginBottom: 16 }}>
              {subMenu.title && (
                <div
                  style={{
                    margin: "0px 16px 8px",
                    fontSize: 18,
                    opacity: 0.6,
                  }}
                >
                  <fieldset
                    style={{
                      borderTop: "0.5px solid lightgray",
                      borderLeft: "none",
                      borderBottom: "none",
                      borderRight: "none",
                      padding: 0,
                    }}
                  >
                    <legend>{subMenu.title}&nbsp;</legend>
                  </fieldset>
                </div>
              )}
              {subMenu.elements
                .filter((a) => a.visible)
                .map((menu, index) => (
                  <MenuSelection
                    key={index}
                    title={subMenu.title}
                    active={menu.active}
                    label={menu.label}
                    handleClick={menu.handleMenu}
                  >
                    {menu.icon}
                  </MenuSelection>
                ))}
            </div>
          ))}
        <div className={classes.bottomArea}>
          <div className={classes.version}>
            V-{window.localStorage.getItem("appVersion")}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default MenuDrawer;
