import * as actionTypes from "../actionTypes";

let initialState = {
  loading: false,
  employeeLoading: false,
  merchantLoading: false,
  reloadAPI: false,
  brands: [],
  kptRwtData: [],
  states: [],
  banners: [],
  employeeCoc: {},
  learningResources: [],
  aggregatorMailData: {},
  notifications: [],
  news: [],
  partnerNews: [],
  uploadStatus: [],
  mailApprovalList: [],
  ticketCategory: [],
  allJDlist: [],
  allTags: [],
  allFAQs: [],
  suggestFAQs: [],
  allCuisines: [],
  allBloggerOrders: [],
  allLogs: [],
  addBrandRequest: [],
  allPartnerBucket: [],
  allLeadGen: [],
  allLeadGenCount: [],
  fetchReview: [],
  weeklyReview: [],
  brandwiseReview: [],
  reviewComments: [],
  leadGenStatusCounts: [],
  trendingWords: [],
  fetchGmailLogs: [],
  gmailLogDetails: [],
  checkBioCode: [],
  allEmp: [],
  reviewsCount: [],
  optiproSchedulers: [],
  schedulerConfirmation: [],
  bestSaleDate: [],
};

export const miscReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MISC_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.EMPLOYEE_LOADING:
      return {
        ...state,
        employeeLoading: action.payload,
      };
    case actionTypes.MERCHANT_LOADING:
      return {
        ...state,
        merchantLoading: action.payload,
      };
    case actionTypes.SET_RELOAD_API:
      return {
        ...state,
        reloadAPI: action.payload,
      };
    case actionTypes.SET_BRAND_LIST:
      return {
        ...state,
        brands: action.payload,
      };
    case actionTypes.SET_KPT_RWT:
      return {
        ...state,
        kptRwtData: action.payload,
      };
    case actionTypes.UPDATE_BRAND_LIST:
      let updateBrands = [...state.brands];
      const updateBrandsIndex = updateBrands.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateBrandsIndex > -1) {
        updateBrands[updateBrandsIndex] = action.payload;
      }
      return {
        ...state,
        brands: updateBrands,
      };
    case actionTypes.SET_STATE_LIST:
      return {
        ...state,
        states: action.payload,
      };
    case actionTypes.UPDATE_STATE_LIST:
      let updateStates = [...state.states];
      const updateStatesIndex = updateStates.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateStatesIndex > -1) {
        updateStates[updateStatesIndex] = action.payload;
      }
      return {
        ...state,
        states: updateStates,
      };
    case actionTypes.SET_BANNER_LIST:
      return {
        ...state,
        banners: action.payload,
      };
    case actionTypes.UPDATE_BANNER_LIST:
      let updateBanners = [...state.banners];
      const updateBannersIndex = updateBanners.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateBannersIndex > -1) {
        updateBanners[updateBannersIndex] = action.payload;
      }
      return {
        ...state,
        banners: updateBanners,
      };
    case actionTypes.ADD_NEW_BANNER_LIST:
      return {
        ...state,
        banners: [...state.banners, action.payload],
      };
    case actionTypes.DELETE_BANNER:
      let deleteBanners = [...state.banners];
      const deleteBannersIndex = deleteBanners.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteBannersIndex > -1) {
        deleteBanners.splice(deleteBannersIndex, 1);
      }
      return {
        ...state,
        banners: deleteBanners,
      };
    case actionTypes.SET_EMPLOYEE_COC:
      return {
        ...state,
        employeeCoc: action.payload,
      };
    case actionTypes.SET_AGGREGATOR_MAIL_DATA:
      return {
        ...state,
        aggregatorMailData: action.payload,
      };
    case actionTypes.SET_PETTY_CASH_PAYMENTS:
      return {
        ...state,
        partnerPettyCashPayments: action.payload,
      };
    case actionTypes.UPDATE_PETTY_CASH_PAYMENTS:
      let updatePettyCashPayments = [...state.partnerPettyCashPayments];
      const updatePettyCashPaymentsIndex = updatePettyCashPayments.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updatePettyCashPaymentsIndex > -1) {
        updatePettyCashPayments[updatePettyCashPaymentsIndex] = action.payload;
      }
      return {
        ...state,
        partnerPettyCashPayments: updatePettyCashPayments,
      };
    case actionTypes.SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case actionTypes.SET_PARTNER_NEWS_LIST:
      return {
        ...state,
        partnerNews: action.payload,
      };
    case actionTypes.SET_NEWS_LIST:
      return {
        ...state,
        news: action.payload,
      };
    case actionTypes.SET_TICKET_CATEGORY:
      return {
        ...state,
        ticketCategory: action.payload,
      };
    case actionTypes.GET_ALL_JD:
      return {
        ...state,
        allJDlist: action.payload,
      };
    case actionTypes.UPDATE_NEWS_LIST:
      let updateNews = [...state.news];
      const updateNewsIndex = updateNews.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateNewsIndex > -1) {
        updateNews[updateNewsIndex] = action.payload;
      }
      return {
        ...state,
        news: updateNews,
      };
    case actionTypes.ADD_NEW_NEWS:
      return {
        ...state,
        news: [{ ...action.payload }, ...state.news],
      };
    case actionTypes.DELETE_NEWS:
      let deleteNews = [...state.news];
      const deleteNewsIndex = deleteNews.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteNewsIndex > -1) {
        deleteNews.splice(deleteNewsIndex, 1);
      }
      return {
        ...state,
        news: deleteNews,
      };
    case actionTypes.SET_LEARNING_RESOURCES:
      return {
        ...state,
        learningResources: action.payload,
      };
    case actionTypes.SET_AGGREGATOR_MAIL_APPROVAL_DATA:
      return {
        ...state,
        mailApprovalList: action.payload,
      };
    case actionTypes.SET_UPLOAD_STATUS_LIST:
      return {
        ...state,
        uploadStatus: action.payload,
      };
    case actionTypes.SET_ALL_TAGS:
      return {
        ...state,
        allTags: action.payload,
      };
    case actionTypes.GET_ALL_CUISINES:
      return {
        ...state,
        allCuisines: action.payload,
      };
    case actionTypes.GET_ALL_FAQS:
      return {
        ...state,
        allFAQs: action.payload,
      };
    case actionTypes.GET_SUGGEST_FAQS:
      return {
        ...state,
        suggestFAQs: action.payload,
      };
    case actionTypes.GET_ALL_BLOGGER_ORDERS:
      return {
        ...state,
        allBloggerOrders: action.payload,
      };
    case actionTypes.GET_ALL_LOGS:
      return {
        ...state,
        allLogs: action.payload,
      };
    case actionTypes.GET_ALL_PARTNER_BUCKET:
      return {
        ...state,
        allPartnerBucket: action.payload,
      };
    case actionTypes.GET_ALL_LEAD_GEN:
      return {
        ...state,
        allLeadGen: action.payload,
      };
    case actionTypes.GET_ALL_LEAD_GEN_COUNT:
      return {
        ...state,
        allLeadGenCount: action.payload,
      };
    case actionTypes.GET_ALL_LEAD_GEN_STATUS_COUNTS:
      return {
        ...state,
        leadGenStatusCounts: action.payload,
      };
    case actionTypes.GET_ADDITIONAL_BRAND_REQUEST:
      return {
        ...state,
        addBrandRequest: action.payload,
      };
    case actionTypes.fetch_reviews:
      return {
        ...state,
        fetchReview: action.payload,
      };
    case actionTypes.fetch_weekly_review:
      return {
        ...state,
        weeklyReview: action.payload,
      };
    case actionTypes.fetch_brandwise_review:
      return {
        ...state,
        brandwiseReview: action.payload,
      };
    case actionTypes.fetch_review_comments:
      return {
        ...state,
        reviewComments: action.payload,
      };
    case actionTypes.fetch_reviews_count:
      return {
        ...state,
        reviewsCount: action.payload,
      };
    case actionTypes.treanding_words:
      return {
        ...state,
        trendingWords: action.payload,
      };
    case actionTypes.fetch_gmail_logs:
      return {
        ...state,
        fetchGmailLogs: action.payload,
      };
    case actionTypes.fetch_gmail_logs_details:
      return {
        ...state,
        gmailLogDetails: action.payload,
      };
    case actionTypes.check_bio_metric_code:
      return {
        ...state,
        checkBioCode: action.payload,
      };
    case actionTypes.FIND_ALL_EMPLOYEE:
      return {
        ...state,
        allEmp: action.payload,
      };
    case actionTypes.FETCH_OPTIPRO_SCHEDULERS:
      return {
        ...state,
        optiproSchedulers: action.payload,
      };
    case actionTypes.FETCH_OPTIPRO_SCHEDULERS_FOR_CONFIRMATION:
      return {
        ...state,
        schedulerConfirmation: action.payload,
      };
    case actionTypes.FETCH_BEST_SALE_DATES:
      return {
        ...state,
        bestSaleDate: action.payload,
      };
    default:
      return state;
  }
};
