export const SET_EMPLOYEE_ANALYTICS_SALES_OVERVIEW =
  "SET_EMPLOYEE_ANALYTICS_SALES_OVERVIEW";
export const SET_EMPLOYEE_ANALYTICS_SALES_BY_REGION =
  "SET_EMPLOYEE_ANALYTICS_SALES_BY_REGION";
export const SET_EMPLOYEE_ANALYTICS_TIMESLOT_ANALYSIS =
  "SET_EMPLOYEE_ANALYTICS_TIMESLOT_ANALYSIS";
export const SET_EMPLOYEE_ANALYTICS_SALES_BY_BRAND =
  "SET_EMPLOYEE_ANALYTICS_SALES_BY_BRAND";
export const SET_EMPLOYEE_ANALYTICS_ITEM_STOCK =
  "SET_EMPLOYEE_ANALYTICS_ITEM_STOCK";
export const SET_EMPLOYEE_ANALYTICS_STATES = "SET_EMPLOYEE_ANALYTICS_STATES";
export const SET_REPORT_CARD = "SET_REPORT_CARD";
export const SET_EMPLOYEE_ANALYTICS_PARTNER_SALES_OVERVIEW =
  "SET_EMPLOYEE_ANALYTICS_PARTNER_SALES_OVERVIEW";
export const SET_EMPLOYEE_ANALYTICS_IR_SALES_OVERVIEW =
  "SET_EMPLOYEE_ANALYTICS_IR_SALES_OVERVIEW";
export const SET_EMPLOYEE_ANALYTICS_TA_NORMALISED_SALES =
  "SET_EMPLOYEE_ANALYTICS_TA_NORMALISED_SALES";
export const SET_EMPLOYEE_ANALYTICS_ITEM_FOOD_COST =
  "SET_EMPLOYEE_ANALYTICS_ITEM_FOOD_COST";
export const SET_EMPLOYEE_ANALYTICS_ITEM_INACCURATE_FOOD_COST =
  "SET_EMPLOYEE_ANALYTICS_ITEM_INACCURATE_FOOD_COST";
export const SET_EMPLOYEE_ANALYTICS_OUTLET_STATUS =
  "SET_EMPLOYEE_ANALYTICS_OUTLET_STATUS";
export const SET_EMPLOYEE_ANALYTICS_PARTNER_SALES_FORECAST =
  "SET_EMPLOYEE_ANALYTICS_PARTNER_SALES_FORECAST";
export const SET_EMPLOYEE_OOS_ANALYSIS = "SET_EMPLOYEE_OOS_ANALYSIS";
export const SET_EMPLOYEE_ANALYTICS_MERCHANT_SALES_FORECAST =
  "SET_EMPLOYEE_ANALYTICS_MERCHANT_SALES_FORECAST";
export const SET_EMPLOYEE_PARTNER_ANALYTICS = "SET_EMPLOYEE_PARTNER_ANALYTICS";
export const SET_EMPLOYEE_ANALYTICS_BRAND_SALES_OVERVIEW =
  "SET_EMPLOYEE_ANALYTICS_BRAND_SALES_OVERVIEW";
export const SET_EMPLOYEE_PARTNER_GKSCORES = "SET_EMPLOYEE_PARTNER_GKSCORES";
export const SET_EMPLOYEE_DAY_WISE_NORMALIZED =
  "SET_EMPLOYEE_DAY_WISE_NORMALIZED";
export const SET_FINANCE_REPORT_DETAILS = "SET_FINANCE_REPORT_DETAILS";
export const SET_PAYOUT_SCHEDULE_HEALTH_DETAILS =
  "SET_PAYOUT_SCHEDULE_HEALTH_DETAILS";
export const SET_DAILY_OPS_DETAILS = "SET_DAILY_OPS_DETAILS";
export const SET_DAILY_OPS_EVERYDAY = "SET_DAILY_OPS_EVERYDAY";
export const SET_NETSALE_RESID = "SET_NETSALE_RESID";
export const SET_BRAND_RATING = "SET_BRAND_RATING";
export const SET_OOS_DATEWISE = "SET_OOS_DATEWISE";
export const SET_RCA_DETAILS = "SET_RCA_DETAILS";
export const SET_DAILY_OPS_ITEM_DETAILS = "SET_DAILY_OPS_ITEM_DETAILS";
export const SET_LEDGER_DETAILS = "SET_LEDGER_DETAILS";
export const SET_LEDGER_SUMMARY = "SET_LEDGER_SUMMARY";
export const SET_TEMPORARY_SALES = "SET_TEMPORARY_SALES";
export const SET_TEMPORARY_MOM = "SET_TEMPORARY_MOM";
export const SET_RISTA_BRANDS = "SET_RISTA_BRANDS";
export const SET_DAILY_OPS_MISSING_DATES = "SET_DAILY_OPS_MISSING_DATES";
export const SET_DAILY_VISIBILITY_DETAILS = "SET_DAILY_VISIBILITY_DETAILS";
export const SET_SALES_ANALYTICS = "SET_SALES_ANALYTICS";
export const SET_TOTAL_SALE_ANALYTICS = "SET_TOTAL_SALE_ANALYTICS";
export const SET_CROSSED_KPT_RWT = "SET_CROSSED_KPT_RWT";
export const SET_AVG_MFR = "SET_AVG_MFR";
export const FETCH_COCO_STORES = "FETCH_COCO_STORES";
export const FETCH_SWIGGY_UPLOAD_STORES = "FETCH_SWIGGY_UPLOAD_STORES";
export const FETCH_SWIGGY_UPLOAD_BRANDS = "FETCH_SWIGGY_UPLOAD_BRANDS";
export const FETCH_OOS_DATA = "FETCH_OOS_DATA";
export const PAYMENT_METHODS = "PAYMENT_METHODS";
export const PAYMENT_DETAILS = "PAYMENT_DETAILS";
export const POOR_RATED_ORDERS = "POOR_RATED_ORDERS";
