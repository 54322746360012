import React, {useState} from "react";
import {useStyles} from "./styles";
import MenuIcon from "../../assets/header/svgs/MenuIcon";
import IconButton from "@mui/material/IconButton";
import MenuDrawer from "./components/MenuDrawer";
import {push} from "connected-react-router";
import {useDispatch, useSelector} from "react-redux";
import HotMenu from "./components/HotMenu";
import CustomWhatsapp from "./components/CustomWhatsapp";
import {WHATSAPP_ACCESS} from "../../utils/constants";

function EmployeeHeader() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const username = useSelector(
    (state) => state.employeeAuth.loginData?.username
  );

  return (
    <div className={classes.root}>
      <div className={classes.leftDiv}>
        <div className={classes.flexBox}>
          <IconButton onClick={() => setMenuOpen(true)}>
            <MenuIcon />
          </IconButton>
          <div
            className={classes.titleText}
            onClick={() => dispatch(push("/employee/home"))}
          >
            Employee Dashboard
          </div>
        </div>
      </div>
      <div className={classes.rightDiv}>
        <div className={classes.flexBox}>
          {WHATSAPP_ACCESS.includes(username) && <CustomWhatsapp />}
          <HotMenu />
        </div>
      </div>
      <MenuDrawer open={menuOpen} handleClose={() => setMenuOpen(false)} />
    </div>
  );
}

export default EmployeeHeader;
