import React from 'react';

function SocialVoucherIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <g data-name="07-Gift Voucher">
        <path
          fill="#ffac5a"
          d="M32.32,4.03a8,8,0,0,1-4.64,13.94c-.22.02-.45.03-.68.03A8,8,0,1,1,32.32,4.03Z"
        />
        <path
          fill="#ffc170"
          d="M30.99,5.523a6,6,0,0,1-3.48,10.454c-.165.016-.337.023-.51.023A6,6,0,1,1,30.99,5.523Z"
        />
        <path
          fill="#ff873c"
          d="M28,9H26a1,1,0,0,1,0-2h2a1,1,0,0,1,1,1h2a3,3,0,0,0-3-3V4H26V5a3,3,0,0,0,0,6h2a1,1,0,0,1,0,2H26a1,1,0,0,1-1-1H23a3,3,0,0,0,3,3v1h2V15a3,3,0,0,0,0-6Z"
        />
        <polygon
          fill="#00c8c8"
          points="11 57 23 57 25 57 25 63 9 63 9 57 11 57"
        />
        <polygon
          fill="#00a0aa"
          points="11 60 23 60 25 60 25 63 9 63 9 60 11 60"
        />
        <polygon
          fill="#00c8c8"
          points="41 57 53 57 55 57 55 63 39 63 39 57 41 57"
        />
        <path
          fill="#ffcdbe"
          d="M17,42V34.5a2.5,2.5,0,1,1,5,0V42a13.834,13.834,0,0,1,2,7,37.839,37.839,0,0,1-1,8H11L3,39l7-10V40l2,2Z"
        />
        <path fill="#faa68e" d="M8,31.857V40l2,2h2l-2-2V29Z" />
        <path
          fill="#faa68e"
          d="M22,42V34.5a2.495,2.495,0,0,0-3.5-2.289A2.474,2.474,0,0,1,20,34.5V42a13.834,13.834,0,0,1,2,7,37.839,37.839,0,0,1-1,8h2a37.839,37.839,0,0,0,1-8A13.834,13.834,0,0,0,22,42Z"
        />
        <path
          fill="#ffcdbe"
          d="M47,42h5l2-2V29l7,10L53,57H41a37.839,37.839,0,0,1-1-8,13.834,13.834,0,0,1,2-7V34.5a2.5,2.5,0,0,1,5,0Z"
        />
        <path fill="#faa68e" d="M56,40V31.857L54,29V40l-2,2h2Z" />
        <path
          fill="#faa68e"
          d="M44,42V34.5a2.474,2.474,0,0,1,1.5-2.289A2.494,2.494,0,0,0,42,34.5V42a13.834,13.834,0,0,0-2,7,37.839,37.839,0,0,0,1,8h2a37.839,37.839,0,0,1-1-8A13.834,13.834,0,0,1,44,42Z"
        />
        <path
          fill="#ff873c"
          d="M27,18A8,8,0,0,0,33.74,5.69,8.069,8.069,0,0,1,37,5h.18a7.991,7.991,0,0,1,7.51,5.81h.01A8.329,8.329,0,0,1,45,13a7.963,7.963,0,0,1-1.76,5H27Z"
        />
        <path
          fill="#fa732d"
          d="M38,10a7.92,7.92,0,0,0-1.26-4.31,8.15,8.15,0,0,1,1.783-.544A7.909,7.909,0,0,0,37.18,5H37a8.069,8.069,0,0,0-3.26.69A8,8,0,0,1,27,18h3A8,8,0,0,0,38,10Z"
        />
        <path
          fill="#ffac5a"
          d="M32.59,24A4.979,4.979,0,1,1,30,21.41,5.106,5.106,0,0,1,32.59,24Z"
        />
        <path
          fill="#ffcf94"
          d="M30.754,24.8A2.987,2.987,0,1,1,29.2,23.246,3.064,3.064,0,0,1,30.754,24.8Z"
        />
        <path fill="#f0f0f0" d="M30,30.59V42H26V30.59a5.083,5.083,0,0,0,4,0Z" />
        <path
          fill="#e4e4e4"
          d="M26,30.59V42h2V31A5.106,5.106,0,0,1,26,30.59Z"
        />
        <path fill="#f0f0f0" d="M10,24H23.41a5.083,5.083,0,0,0,0,4H10Z" />
        <path
          fill="#e4e4e4"
          d="M23.41,24H10v2H23A5.106,5.106,0,0,1,23.41,24Z"
        />
        <path fill="#f0f0f0" d="M27,18h3v3.41a5.083,5.083,0,0,0-4,0V18Z" />
        <path fill="#e4e4e4" d="M28,18H26v3.41A5.106,5.106,0,0,1,28,21Z" />
        <path fill="#f0f0f0" d="M54,28H32.59a5.083,5.083,0,0,0,0-4H54Z" />
        <path fill="#e4e4e4" d="M32.59,24A5.106,5.106,0,0,1,33,26H54V24Z" />
        <path
          fill="#bf947a"
          d="M54,29V40l-2,2H47V34.5a2.5,2.5,0,1,0-5,0V42H30V30.59A5.111,5.111,0,0,0,32.59,28H54Z"
        />
        <path
          fill="#b2876d"
          d="M34.59,28h-2A5.111,5.111,0,0,1,30,30.59V42h2V30.59A5.111,5.111,0,0,0,34.59,28Z"
        />
        <path
          fill="#b2876d"
          d="M46.5,32a2.5,2.5,0,0,0-1,.211A2.5,2.5,0,0,1,47,34.5V42h2V34.5A2.5,2.5,0,0,0,46.5,32Z"
        />
        <path
          fill="#bf947a"
          d="M26,30.59V42H22V34.5a2.5,2.5,0,0,0-5,0V42H12l-2-2V28H23.41A5.111,5.111,0,0,0,26,30.59Z"
        />
        <path
          fill="#b2876d"
          d="M17.5,32A2.5,2.5,0,0,0,15,34.5V42h2V34.5a2.5,2.5,0,0,1,1.5-2.289A2.5,2.5,0,0,0,17.5,32Z"
        />
        <path
          fill="#b2876d"
          d="M23.41,28h-2A5.111,5.111,0,0,0,24,30.59V42h2V30.59A5.111,5.111,0,0,1,23.41,28Z"
        />
        <path
          fill="#bf947a"
          d="M26,18v3.41A5.111,5.111,0,0,0,23.41,24H10V18Z"
        />
        <path
          fill="#bf947a"
          d="M54,18v6H32.59A5.111,5.111,0,0,0,30,21.41V18H54Z"
        />
        <path
          fill="#b2876d"
          d="M32,21.41V18H30v3.41A5.111,5.111,0,0,1,32.59,24h2A5.111,5.111,0,0,0,32,21.41Z"
        />
        <path
          fill="#b2876d"
          d="M24,21.41V18h2v3.41A5.111,5.111,0,0,0,23.41,24h-2A5.111,5.111,0,0,1,24,21.41Z"
        />
        <path fill="#8d5c4d" d="M27,18H10l9.06-7A8,8,0,0,0,27,18Z" />
        <path
          fill="#805142"
          d="M19.06,11l-1.715,1.325A8,8,0,0,0,25,18h2A8,8,0,0,1,19.06,11Z"
        />
        <path
          fill="#8d5c4d"
          d="M37.18,5H37a8.069,8.069,0,0,0-3.26.69,7.957,7.957,0,0,0-3.77-3.12L32,1Z"
        />
        <path
          fill="#805142"
          d="M35.955,4.075a4.33,4.33,0,0,1-3.526-.941A8.189,8.189,0,0,0,30.76,1.959l-.79.611a7.957,7.957,0,0,1,3.77,3.12A8.069,8.069,0,0,1,37,5h.18l-1.2-.928Z"
        />
        <path
          fill="#8d5c4d"
          d="M54,18H43.24A7.963,7.963,0,0,0,45,13a8.329,8.329,0,0,0-.3-2.19Z"
        />
        <path
          fill="#805142"
          d="M46.969,12.564,44.7,10.81A8.329,8.329,0,0,1,45,13a7.963,7.963,0,0,1-1.76,5h2A7.963,7.963,0,0,0,47,13C47,12.852,46.977,12.71,46.969,12.564Z"
        />
        <polygon
          fill="#00a0aa"
          points="41 60 53 60 55 60 55 63 39 63 39 60 41 60"
        />
        <rect width="6" height="2" x="48" y="8" fill="#19cffc" />
        <rect
          width="5.657"
          height="2"
          x="47.172"
          y="3"
          fill="#19cffc"
          transform="rotate(-45 50 4)"
        />
        <rect width="6" height="2" x="10" y="8" fill="#19cffc" />
        <rect
          width="2"
          height="5.657"
          x="13"
          y="1.172"
          fill="#19cffc"
          transform="rotate(-45 14 4)"
        />
        <path d="M61.819,38.427,55,28.685V18a.979.979,0,0,0-.4-.778l.01-.014-9.058-6.995a9.011,9.011,0,0,0-7.995-6.185L32.611.208a1,1,0,0,0-1.223,0l-1.6,1.238A8.988,8.988,0,0,0,18,10c0,.18.017.357.027.534L9.389,17.208l.01.014A.979.979,0,0,0,9,18V28.685L2.181,38.427a1,1,0,0,0-.1.979L9.461,56H9a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H25a1,1,0,0,0,1-1V57a1,1,0,0,0-1-1h-.757A37.379,37.379,0,0,0,25,49a14.693,14.693,0,0,0-1.343-6H40.343A14.693,14.693,0,0,0,39,49a37.379,37.379,0,0,0,.757,7H39a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H55a1,1,0,0,0,1-1V57a1,1,0,0,0-1-1h-.461l7.375-16.594A1,1,0,0,0,61.819,38.427ZM44.5,31A3.5,3.5,0,0,0,41,34.5V41H31V31.188A6.024,6.024,0,0,0,33.188,29H53V39.586L51.586,41H48V34.5A3.5,3.5,0,0,0,44.5,31Zm-25,0A3.5,3.5,0,0,0,16,34.5V41H12.414L11,39.586V29H22.812A6.024,6.024,0,0,0,25,31.188V41H23V34.5A3.5,3.5,0,0,0,19.5,31ZM53,19v4H33.188A6.024,6.024,0,0,0,31,20.812V19Zm-7.951-2A8.993,8.993,0,0,0,46,13.083L51.069,17ZM33.91,25H53v2H33.91a5.6,5.6,0,0,0,0-2ZM29,19v1.09a5.6,5.6,0,0,0-2,0V19Zm-4,1.812A6.024,6.024,0,0,0,22.812,23H11V19H25ZM22.09,27H11V25H22.09a5.6,5.6,0,0,0,0,2ZM24,26a4,4,0,1,1,4,4A4,4,0,0,1,24,26ZM44,13a6.9,6.9,0,0,1-1.287,4H32.644A8.953,8.953,0,0,0,35.172,6.252,6.971,6.971,0,0,1,44,13ZM32,2.264,34.663,4.32c-.189.051-.379.1-.566.165a9.082,9.082,0,0,0-2.284-2.077ZM27,3a7,7,0,1,1-7,7A7.008,7.008,0,0,1,27,3ZM21.356,17H12.93l5.5-4.252A9.017,9.017,0,0,0,21.356,17ZM24,62H10V58H24ZM11.65,56,4.144,39.11,9,32.172V40a1,1,0,0,0,.293.707l2,2A1,1,0,0,0,12,43h3l-1.8,2.4,1.6,1.2,3-4A1,1,0,0,0,18,42V34.5a1.5,1.5,0,0,1,3,0V42a1.009,1.009,0,0,0,.167.553A12.962,12.962,0,0,1,23,49a36.215,36.215,0,0,1-.8,7ZM27,41V31.91a5.6,5.6,0,0,0,2,0V41ZM54,62H40V58H54Zm-1.65-6H41.8a36.147,36.147,0,0,1-.8-7,12.957,12.957,0,0,1,1.832-6.445A1.006,1.006,0,0,0,43,42V34.5a1.5,1.5,0,0,1,3,0V42a1,1,0,0,0,.2.6l3,4,1.6-1.2L49,43h3a1,1,0,0,0,.707-.293l2-2A1,1,0,0,0,55,40V32.172l4.856,6.938Z" />
        <path d="M28,13H26a1,1,0,0,1-1-1H23a3,3,0,0,0,3,3v1h2V15a3,3,0,0,0,0-6H26a1,1,0,0,1,0-2h2a1,1,0,0,1,1,1h2a3,3,0,0,0-3-3V4H26V5a3,3,0,0,0,0,6h2a1,1,0,0,1,0,2Z" />
        <rect width="6" height="2" x="48" y="8" />
        <rect
          width="5.657"
          height="2"
          x="47.172"
          y="3"
          transform="rotate(-45 50 4)"
        />
        <rect width="6" height="2" x="10" y="8" />
        <rect
          width="2"
          height="5.657"
          x="13"
          y="1.172"
          transform="rotate(-45 14 4)"
        />
        <rect width="2" height="2" x="11" y="59" />
        <rect width="2" height="2" x="41" y="59" />
      </g>
    </svg>
  );
}

export default SocialVoucherIcon;
