export const SET_ESTIMATED_FUND_INFLOW_CONFIG =
  "SET_ESTIMATED_FUND_INFLOW_CONFIG";
export const SET_ESTIMATED_FUND_INFLOW_CONFIG_FOR_PARTNER =
  "SET_ESTIMATED_FUND_INFLOW_CONFIG_FOR_PARTNER";
export const UPDATE_ESTIMATED_FUND_INFLOW_CONFIG =
  "UPDATE_ESTIMATED_FUND_INFLOW_CONFIG";

export const SET_ESTIMATED_FUND_INFLOW_CALCULATION =
  "SET_ESTIMATED_FUND_INFLOW_CALCULATION";
export const SET_ESTIMATED_FUND_INFLOW_CALCULATION_TOTAL =
  "SET_ESTIMATED_FUND_INFLOW_CALCULATION_TOTAL";
