import React from "react";
import { Redirect, Route } from "react-router";
import { useSelector } from "react-redux";
import queryString from "query-string";

function UserAuthHoc({ component: Component, ...rest }) {
  const userData = useSelector((state) => state.userAuth.loginData);
  const accessible = userData?._id;
  let url = "/user-login";
  if (rest.location.pathname) {
    url += `?redirect=${rest.location.pathname}`;
    if (rest.location.search) {
      const data = queryString.parse(rest.location.search);
      url += `&redirect_search=${JSON.stringify(data)}`;
    }
  }
  return (
    <Route
      {...rest}
      render={(prop) =>
        Boolean(accessible) ? <Component {...prop} /> : <Redirect to={url} />
      }
    />
  );
}

export default UserAuthHoc;
