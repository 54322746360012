import * as actionTypes from "../actionTypes";

let initialState = {
  propertyList: [],
};

export const employeePropertyReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEE_PROPERTY_LIST:
      return {
        ...state,
        propertyList: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        propertyList: [],
      };
    default:
      return state;
  }
};
