import * as actionTypes from "../actionTypes";

const initialState = {
  baseUnits: ["litre", "kg", "piece"],
  items: [],
  semiProcessedItems: [],
  semiProcessedRecipe: {},
  inventoryItems: [],
  inventoryItemRecipe: {},
  products: [],
  brands: [],
  brandUniques: [],
  units: [],
  categories: [],
  suppliers: [],
  paymentTerms: [],
  cocos: [],
  purchaseOrders: [],
  supplierProducts: [],
  partnerProducts: [],
  saleOrders: [],
  deliveryNotes: [],
  merchantSaleOrders: [],
  statementData: [],
  paymentConfirmation: [],
  adminpaymentConfirmation: [],
  allcredit: [],
  allUpdateProductRequest: [],
  allInventoryDN: [],
  monthlyOrderCount: [],
};

export const stockReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INIT_STOCK_ITEM:
      return {
        ...state,
        items: action.payload,
      };
    case actionTypes.PAYMENT_CONFIRMATION_DATA:
      return {
        ...state,
        paymentConfirmation: action.payload,
      };
    case actionTypes.ADMIN_PAYMENT_CONFIRMATION:
      return {
        ...state,
        adminpaymentConfirmation: action.payload,
      };
    case actionTypes.ALL_CREDITS_PAYMENT:
      return {
        ...state,
        allcredit: action.payload,
      };
    case actionTypes.SET_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };
    case actionTypes.SET_ALL_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };
    case actionTypes.GET_ALL_PRODUCTS_REQUEST:
      return {
        ...state,
        allUpdateProductRequest: action.payload,
      };
    case actionTypes.SET_ALL_BRAND_UNIQUE:
      return {
        ...state,
        brandUniques: action.payload,
      };
    case actionTypes.SET_ALL_UNITS:
      return {
        ...state,
        units: action.payload,
      };
    case actionTypes.STATEMENT_DATA:
      return {
        ...state,
        statementData: action.payload,
      };
    case actionTypes.SET_ALL_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
      };
    case actionTypes.SET_ALL_SUPPLIER_PRODUCTS:
      return {
        ...state,
        supplierProducts: action.payload,
      };
    case actionTypes.SET_ALL_PARTNER_PRODUCTS:
      return {
        ...state,
        partnerProducts: action.payload,
      };
    case actionTypes.SET_ALL_PAYMENT_TERMS:
      return {
        ...state,
        paymentTerms: action.payload,
      };
    case actionTypes.SET_ALL_COCOS:
      return {
        ...state,
        cocos: action.payload,
      };
    case actionTypes.SET_ALL_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: action.payload,
      };
    case actionTypes.SET_ALL_SALE_ORDERS:
      return {
        ...state,
        saleOrders: action.payload,
      };
    case actionTypes.SET_ALL_Distibritor_PO:
      return {
        ...state,
        saleOrders: action.payload,
      };
    case actionTypes.SET_ALL_DISTRIBUTOR_DELIVERY_NOTES:
      return {
        ...state,
        deliveryNotes: action.payload,
      };
    case actionTypes.SET_ALL_DELIVERY_NOTES:
      return {
        ...state,
        deliveryNotes: action.payload,
      };
    case actionTypes.SET_ALL_INVENTORY_DN:
      return {
        ...state,
        allInventoryDN: action.payload,
      };
    case actionTypes.MONTHLY_ORDER_COUNT:
      return {
        ...state,
        monthlyOrderCount: action.payload,
      };
    case actionTypes.SET_ALL_MERCHANT_SALE_ORDERS:
      return {
        ...state,
        merchantSaleOrders: action.payload,
      };
    case actionTypes.ADD_SALE_ORDER:
      return {
        ...state,
        saleOrders: [...state.saleOrders, action.payload],
      };
    case actionTypes.SET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case actionTypes.ADD_NEW_PRODUCT:
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    case actionTypes.ADD_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrders: [...state.purchaseOrders, action.payload],
      };
    case actionTypes.ADD_MERCHANT_SALE_ORDER:
      return {
        ...state,
        merchantSaleOrders: [...state.merchantSaleOrders, action.payload],
      };
    case actionTypes.ADD_NEW_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };
    case actionTypes.ADD_NEW_BRAND:
      return {
        ...state,
        brands: [...state.brands, action.payload],
      };
    case actionTypes.ADD_NEW_UNIT:
      return {
        ...state,
        units: [...state.units, action.payload],
      };
    case actionTypes.ADD_NEW_SUPPLIER:
      return {
        ...state,
        suppliers: [...state.suppliers, action.payload],
      };
    case actionTypes.EDIT_SUPPLIER:
      return {
        ...state,
        suppliers: state.suppliers.map((supplier) => {
          if (supplier._id === action.payload._id) {
            return action.payload;
          } else {
            return supplier;
          }
        }),
      };
    case actionTypes.ADD_STOCK_ITEM:
      const addItem = [...state.items];
      addItem.push(action.payload);
      return {
        ...state,
        items: addItem,
      };
    case actionTypes.UPDATE_STOCK_ITEM:
      const updateItem = [...state.items];
      const updateItemIndex = updateItem.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateItemIndex > -1) {
        updateItem[updateItemIndex] = action.payload;
      }
      return {
        ...state,
        items: updateItem,
      };
    case actionTypes.DELETE_STOCK_ITEM:
      const deleteItem = [...state.items];
      const deleteItemIndex = deleteItem.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteItemIndex > -1) {
        deleteItem.splice(deleteItemIndex, 1);
      }
      return {
        ...state,
        items: deleteItem,
      };
    case actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM:
      return {
        ...state,
        semiProcessedItems: action.payload,
      };
    case actionTypes.ADD_STOCK_SEMI_PROCESSED_ITEM:
      const addSPItem = [...state.semiProcessedItems];
      addSPItem.push(action.payload);
      return {
        ...state,
        semiProcessedItems: addSPItem,
      };
    case actionTypes.UPDATE_STOCK_SEMI_PROCESSED_ITEM:
      const updateSPItem = [...state.semiProcessedItems];
      const updateSPItemIndex = updateSPItem.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateSPItemIndex > -1) {
        updateSPItem[updateSPItemIndex] = action.payload;
      }
      return {
        ...state,
        semiProcessedItems: updateSPItem,
      };
    case actionTypes.DELETE_STOCK_SEMI_PROCESSED_ITEM:
      const deleteSPItem = [...state.semiProcessedItems];
      const deleteSPItemIndex = deleteSPItem.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteSPItemIndex > -1) {
        deleteSPItem.splice(deleteSPItemIndex, 1);
      }
      return {
        ...state,
        semiProcessedItems: deleteSPItem,
      };
    case actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE:
      return {
        ...state,
        semiProcessedRecipe: action.payload,
      };
    case actionTypes.RESET_STOCK_SEMI_PROCESSED_ITEM_RECIPE:
      return {
        ...state,
        semiProcessedRecipe: {},
      };
    case actionTypes.INIT_STOCK_INVENTORY_ITEM:
      return {
        ...state,
        inventoryItems: action.payload,
      };
    case actionTypes.INIT_STOCK_ITEM_RECIPE:
      return {
        ...state,
        inventoryItemRecipe: action.payload,
      };
    case actionTypes.SET_MERCHANT_LOGOUT:
      return {
        baseUnits: ["litre", "kg", "piece"],
        items: [],
        semiProcessedItems: [],
        semiProcessedRecipe: {},
        inventoryItems: [],
        inventoryItemRecipe: {},
      };
    default:
      return state;
  }
};
