import * as actionTypes from "../actionTypes";

let initialState = {
  list: [],
};

export const employeeLeadGenReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEE_LEAD_GEN_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case actionTypes.DELETE_EMPLOYEE_LEAD_GEN_LIST:
      let delete_list = [...state.list];
      const delete_list_index = delete_list.findIndex(a => a._id === action.payload);
      if(delete_list_index > -1) {
        delete_list.splice(delete_list_index, 1)
      }
      return {
        ...state,
        list: delete_list,
      }
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        list: [],
      };
    default:
      return state;
  }
};
