/* eslint-disable camelcase,no-console,no-undef */
import axios from "axios";
import {API_URL} from "../utils/constants";
import {toast} from "react-toastify";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const instance = axios.create({
  baseURL: API_URL,
  contentType: "application/json",
  Authorization: getAuthToken(),
});

function getAuthToken() {
  if (localStorage.getItem("employee_auth_token")) {
    return `Bearer ${localStorage.getItem("employee_auth_token")}`;
  }
}

const parseError = (error) => {
  const originalRequest = error.config;
  if (
    error.response.data.message ===
    "Cannot destructure property 'type' of 'jwt.decode(...)' as it is null."
  ) {
    toast.info("please login again to continue");
    // setTimeout(() => {
    //   window.localStorage.clear();
    //   window.location.reload();
    // }, 1000);
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          originalRequest.headers["Authorization"] = "Bearer " + token;
          return axios(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise(function (resolve, reject) {
      axios
        .get(`${API_URL}/auth/token`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "employee_refresh_token"
            )}`,
          },
        })
        .then((res) => {
          localStorage.setItem("employee_auth_token", res.data.token);
          localStorage.setItem(
            "employee_refresh_token",
            res.data.newRefreshToken
          );
          instance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("employee_auth_token")}`;
          originalRequest.headers["Authorization"] = `Bearer ${res.data.token}`;
          processQueue(null, res.data.token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
          toast.error(err.response.data.message);
          setTimeout(() => {
            window.localStorage.clear();
            window.location.reload();
          }, 1000);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }
  return Promise.reject(error);
};

const parseConfig = (config) => {
  const token =
    localStorage && localStorage.getItem("employee_auth_token")
      ? localStorage.getItem("employee_auth_token")
      : "";
  const configuration = { ...config };
  configuration.headers.Authorization = `Bearer ${token}`;
  return configuration;
};

// request header
instance.interceptors.request.use(
  (config) => parseConfig(config),
  (error) => Promise.reject(error)
);

// response parse
instance.interceptors.response.use(
  (response) => response,
  (error) => parseError(error)
);

export const employee_http = instance;
