import * as React from "react";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import homeIcon from "../../../assets/menu/home.svg";
import saleIcon from "../../../assets/menu/sales.svg";
import metricsIcon from "../../../assets/menu/metrics.svg";
import financeIcon from "../../../assets/menu/finance.svg";
import ticketsIcon from "../../../assets/menu/tickets.svg";
import marketingIcon from "../../../assets/menu/marketing.svg";
import invoiceIcon from "../../../assets/menu/marketing.svg";

import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import {push} from "connected-react-router";
import {fetchMerchantDetails} from "../../../redux/actions";

const drawerWidth = 100;

const MenuDrawer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedMenu, setSelectedMenu] = React.useState({});
  const [tab, setTab] = React.useState(0);
  const [subTab, setSubTab] = React.useState(false);

  // Todo: remove if any issue in route
  // React.useEffect(() => {
  //   const currentMenuOption = getCurrentMenuOption();
  //   setSelectedMenu(currentMenuOption);
  //   const currentTabIndex = getCurrentMenuTab(currentMenuOption);
  //   setTab(currentTabIndex);
  //   if (currentMenuOption?.elements[currentTabIndex]?.items?.length > 0) {
  //     const currentSubTabIndex = getCurrentMenuSubTab(
  //       currentMenuOption,
  //       currentTabIndex
  //     );
  //     setSubTab(currentSubTabIndex);
  //     currentMenuOption?.elements[currentTabIndex]?.items[
  //       currentSubTabIndex
  //     ]?.handleMenu();
  //   } else {
  //     currentMenuOption?.elements[currentTabIndex].handleMenu();
  //   }
  // }, [location.pathname]);

  const agreementLink = useSelector(
    (state) => state.merchantAuth?.loginData?.agreement_link
  );

  const handleAgreementDownload = () => {
    if (agreementLink) {
      window.open(agreementLink, "_blank");
    }
  };

  const menuOptions = [
    {
      title: "Home",
      visible: true,
      icon: homeIcon,
      active: location.pathname === "/merchant/home",
      elements: [
        {
          label: "Home",
          active: location.pathname === "/merchant/home",
          handleMenu: () => {
            dispatch(push("/merchant/home"));
          },
          visible: true,
        },
      ],
    },
    {
      title: "Sales",
      visible: true,
      icon: saleIcon,
      active: location.pathname === "/merchant/forecast",
      elements: [
        {
          label: "Projections",
          active: location.pathname === "/merchant/forecast",
          handleMenu: () => {
            dispatch(push("/merchant/forecast"));
          },
          visible: true,
        },
      ],
    },
    {
      title: "Inventory",
      visible: true,
      icon: saleIcon,
      active:
        location.pathname === "/merchant/inventory" ||
        location.pathname === "/merchant/grm",
      elements: [
        {
          label: "Inventory",
          active: location.pathname === "/merchant/inventory",
          handleMenu: () => {
            dispatch(push("/merchant/inventory"));
          },
          visible: true,
        },
        {
          label: "GRN",
          active: location.pathname === "/merchant/grm",
          handleMenu: () => {
            dispatch(push("/merchant/grm"));
          },
          visible: true,
        },
      ],
    },
    {
      title: "Accounts",
      visible: true,
      icon: financeIcon,
      active:
        location.pathname === "/merchant/payment-invoice-report" ||
        location.pathname === "/merchant/rupifi/credit" ||
        location.pathname === "/merchant/payout-reports" ||
        location.pathname === "/merchant/brand-wise-payout" ||
        location.pathname === "/merchant/blogger/orders" ||
        location.pathname === "/merchant/food-cost" ||
        location.pathname === "/merchant/invoices" ||
        location.pathname === "/merchant/raw-material-purchase-report" ||
        location.pathname === "/merchant/subscription-fee-report" ||
        location.pathname === "/merchant/onboarding-fee-report" ||
        location.pathname === "/merchant/pos-charges-report" ||
        location.pathname === "/merchant/payout-transactions" ||
        location.pathname === "/merchant/tax-report" ||
        location.pathname === "/merchant/adjustment-report" ||
        location.pathname === "/merchant/po-credit-status" ||
        location.pathname === "/merchant/ledger-report",
      elements: [
        {
          label: "Payments",
          active:
            location.pathname === "/merchant/payment-invoice-report" ||
            location.pathname === "/merchant/rupifi/credit",
          visible: true,
          items: [
            {
              label: "Pay Online",
              active: location.pathname === "/merchant/payment-invoice-report",
              handleMenu: () => {
                dispatch(
                  push("/merchant/payment-invoice-report" + location?.search)
                );
              },
            },
            {
              label: "Credit Partnership",
              active: location.pathname === "/merchant/rupifi/credit",
              handleMenu: () => {
                dispatch(push("/merchant/rupifi/credit"));
              },
            },
          ],
        },
        {
          label: "Reports",
          active:
            location.pathname === "/merchant/payout-reports" ||
            location.pathname === "/merchant/brand-wise-payout" ||
            location.pathname === "/merchant/blogger/orders" ||
            location.pathname === "/merchant/raw-material-purchase-report" ||
            location.pathname === "/merchant/subscription-fee-report" ||
            location.pathname === "/merchant/onboarding-fee-report" ||
            location.pathname === "/merchant/pos-charges-report" ||
            location.pathname === "/merchant/payout-transactions" ||
            location.pathname === "/merchant/tax-report" ||
            location.pathname === "/merchant/adjustment-report" ||
            location.pathname === "/merchant/po-credit-status" ||
            location.pathname === "/merchant/ledger-report",
          visible: true,
          items: [
            {
              label: "Payout",
              active: location.pathname === "/merchant/payout-reports",
              handleMenu: () => {
                dispatch(push("/merchant/payout-reports"));
              },
            },
            {
              label: "Brand Wise Payout",
              active: location.pathname === "/merchant/brand-wise-payout",
              handleMenu: () => {
                dispatch(push("/merchant/brand-wise-payout"));
              },
            },
            {
              label: "Payout Transactions",
              active: location.pathname === "/merchant/payout-transactions",
              handleMenu: () => {
                dispatch(push("/merchant/payout-transactions"));
              },
            },
            {
              label: "Trial Orders",
              active: location.pathname === "/merchant/blogger/orders",
              handleMenu: () => {
                dispatch(push("/merchant/blogger/orders"));
              },
            },
            {
              label: "Raw material purchase report",
              active:
                location.pathname === "/merchant/raw-material-purchase-report",
              handleMenu: () => {
                dispatch(push("/merchant/raw-material-purchase-report"));
              },
            },
            {
              label: "Subscription fee",
              active: location.pathname === "/merchant/subscription-fee-report",
              handleMenu: () => {
                dispatch(push("/merchant/subscription-fee-report"));
              },
            },
            {
              label: "Onboarding fee",
              active: location.pathname === "/merchant/onboarding-fee-report",
              handleMenu: () => {
                dispatch(push("/merchant/onboarding-fee-report"));
              },
            },
            {
              label: "POS charges",
              active: location.pathname === "/merchant/pos-charges-report",
              handleMenu: () => {
                dispatch(push("/merchant/pos-charges-report"));
              },
            },
            {
              label: "Tax report",
              active: location.pathname === "/merchant/tax-report",
              handleMenu: () => {
                dispatch(push("/merchant/tax-report"));
              },
            },
            {
              label: "Adjustment report",
              active: location.pathname === "/merchant/adjustment-report",
              handleMenu: () => {
                dispatch(push("/merchant/adjustment-report"));
              },
            },
            {
              label: "Ledger report",
              active: location.pathname === "/merchant/ledger-report",
              handleMenu: () => {
                dispatch(push("/merchant/ledger-report"));
              },
            },
            {
              label: "PO credit status",
              active: location.pathname === "/merchant/po-credit-status",
              handleMenu: () => {
                dispatch(push("/merchant/po-credit-status"));
              },
            },
          ],
        },
        {
          label: "Profit",
          active: location.pathname === "/merchant/food-cost",
          handleMenu: () => {
            dispatch(push("/merchant/food-cost"));
          },
          visible: true,
        },
        {
          label: "Invoice",
          active: location.pathname === "/merchant/invoices",
          handleMenu: () => {
            dispatch(push("/merchant/invoices"));
          },
          visible: true,
        },
      ],
    },
    {
      title: "Academy",
      visible: true,
      icon: saleIcon,
      active: location.pathname === "/merchant/training",
      elements: [
        {
          label: "Training Documents",
          active: location.pathname === "/merchant/training",
          handleMenu: () => {
            dispatch(push("/merchant/training"));
          },
          visible: true,
        },
      ],
    },
    {
      title: "Restaurant Health",
      visible: true,
      icon: metricsIcon,
      active:
        location.pathname === "/merchant/restaurant-health" ||
        location.pathname === "/merchant/restaurant-zs-analytics" ||
        location.pathname === "/merchant/restaurant-sales-analytics" ||
        location.pathname === "/merchant/restaurant-daily-report" ||
        location.pathname === "/merchant/daily-ops" ||
        location.pathname === "/merchant/igcc",
      elements: [
        {
          label: "Daily Ops",
          active: location.pathname === "/merchant/daily-ops",
          handleMenu: () => {
            dispatch(push("/merchant/daily-ops"));
          },
          visible: true,
        },
        {
          label: "Health Metrics",
          active: location.pathname === "/merchant/restaurant-health",
          handleMenu: () => {
            dispatch(push("/merchant/restaurant-health"));
          },
          visible: true,
        },
        {
          label: "IGCC Metrics",
          active: location.pathname === "/merchant/igcc",
          handleMenu: () => {
            dispatch(push("/merchant/igcc"));
          },
          visible: true,
        },
        {
          label: "ZS Analytics",
          active: location.pathname === "/merchant/restaurant-zs-analytics",
          handleMenu: () => {
            dispatch(push("/merchant/restaurant-zs-analytics"));
          },
          visible: true,
        },
        {
          label: "Sales Analytics",
          active: location.pathname === "/merchant/restaurant-sales-analytics",
          handleMenu: () => {
            dispatch(push("/merchant/restaurant-sales-analytics"));
          },
          visible: true,
        },
        {
          label: "Daily Report",
          active: location.pathname === "/merchant/restaurant-daily-report",
          handleMenu: () => {
            dispatch(push("/merchant/restaurant-daily-report"));
          },
          visible: true,
        },
      ],
    },
    {
      title: "Marketing",
      visible: true,
      icon: marketingIcon,
      active: location.pathname === "/merchant/gk-investments",
      elements: [
        {
          label: "Spends by GK",
          active: location.pathname === "/merchant/gk-investments",
          handleMenu: () => {
            dispatch(push("/merchant/gk-investments"));
          },
          visible: true,
        },
      ],
    },
    {
      title: "Profile",
      visible: true,
      icon: invoiceIcon,
      active:
        location.pathname === "/merchant/bank-details" ||
        location.pathname === "/merchant/gst-upload" ||
        location.pathname === "/merchant/preference/contact" ||
        location.pathname === "/merchant/preference/timing",
      elements: [
        {
          label: "Bank Details",
          active: location.pathname === "/merchant/bank-details",
          handleMenu: () => {
            dispatch(push("/merchant/bank-details"));
          },
          visible: true,
        },
        {
          label: "GST Upload",
          active: location.pathname === "/merchant/gst-upload",
          handleMenu: () => {
            dispatch(push("/merchant/gst-upload"));
          },
          visible: true,
        },
        {
          label: "Download Agreement",
          active: false,
          handleMenu: () => {
            handleAgreementDownload();
          },
          visible: true,
        },
        {
          label: "Store timing",
          active: location.pathname === "/merchant/preference/timing",
          handleMenu: () => {
            dispatch(fetchMerchantDetails());
            dispatch(push("/merchant/preference/timing"));
          },
          visible: true,
        },
        {
          label: "Contact details",
          active: location.pathname === "/merchant/preference/contact",
          handleMenu: () => {
            dispatch(fetchMerchantDetails());
            dispatch(push("/merchant/preference/contact"));
          },
          visible: true,
        },
      ],
    },
    {
      title: "Help",
      visible: true,
      icon: ticketsIcon,
      active: location.pathname === "/merchant/my-tickets",
      elements: [
        {
          label: "My Tickets",
          active: location.pathname === "/merchant/my-tickets",
          handleMenu: () => {
            dispatch(push("/merchant/my-tickets"));
          },
          visible: true,
        },
      ],
    },
  ];

  const selectMenuOption = (menuOption) => {
    setSelectedMenu(menuOption);
    if (menuOption && menuOption?.elements?.length > 0) {
      let tabIndx = menuOption?.elements?.findIndex((a) => a.visible);
      selectTab(menuOption, tabIndx > 0 ? tabIndx : 0);
    }
  };

  const selectTab = (menuOption, tabIndx) => {
    setTab(tabIndx);
    if (menuOption && menuOption?.elements?.length > 0) {
      if (menuOption?.elements[tabIndx]?.items?.length > 0) {
        selectSubTab(menuOption, tabIndx, 0);
      } else {
        menuOption?.elements[tabIndx].handleMenu();
      }
    }
  };

  const selectSubTab = (menuOption, tabIndx, subTabIndx) => {
    setSubTab(subTabIndx);
    if (
      menuOption &&
      menuOption?.elements?.length > 0 &&
      menuOption?.elements[tabIndx]?.items?.length > 0
    ) {
      menuOption?.elements[tabIndx]?.items[subTabIndx]?.handleMenu();
    }
  };

  const getCurrentMenuOption = () => {
    return menuOptions.find((a) => a.active);
  };

  const getCurrentMenuTab = (menuOption) => {
    if (menuOption && menuOption?.elements?.length > 0) {
      return menuOption?.elements?.findIndex((a) => a.active);
    }
    return 0;
  };

  const getCurrentMenuSubTab = (menuOption, tabIndex) => {
    if (
      menuOption &&
      menuOption?.elements?.length > 0 &&
      menuOption?.elements[tabIndex]?.items?.length > 0
    ) {
      return menuOption?.elements[tabIndex]?.items?.findIndex((a) => a.active);
    }
    return 0;
  };

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={true}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#0DAA47",
            color: "white",
          },
        }}
      >
        <Toolbar />
        <Box>
          <List>
            {menuOptions.map(
              (obj, index) =>
                obj?.visible && (
                  <ListItem
                    key={obj?.title}
                    disablePadding
                    sx={obj?.active ? { background: "#00953b" } : {}}
                  >
                    <ListItemButton
                      onClick={() => selectMenuOption(obj)}
                      sx={{ display: "block", textAlign: "center", my: "4px" }}
                    >
                      <ListItemIcon sx={{ display: "block" }}>
                        <img src={obj?.icon} alt={""} />
                      </ListItemIcon>
                      <Typography textAlign="center" sx={{ fontSize: "12px" }}>
                        {obj?.title}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </Box>
      </Drawer>
      <Box
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          mt: "64px",
          height: "46px",
          display: location.pathname === "/merchant/home" ? "none" : "block",
        }}
      >
        <Paper elevation={1}>
          <Box sx={{ width: "100%" }}>
            <Box>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
                onChange={(event, newValue) =>
                  selectTab(selectedMenu, newValue)
                }
                aria-label="basic tabs example"
              >
                {selectedMenu?.elements?.map(
                  (section, index) =>
                    section?.visible && (
                      <Tab
                        sx={{ fontSize: "11px", color: "#777777" }}
                        key={index}
                        value={index}
                        label={section?.label}
                      />
                    )
                )}
              </Tabs>
            </Box>
          </Box>
        </Paper>
      </Box>
      {selectedMenu?.elements?.length > 0 &&
        selectedMenu?.elements[tab]?.items?.length > 0 &&
        selectedMenu?.elements[tab]?.visible && (
          <Box
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              mt: "4px",
              height: "46px",
            }}
          >
            <Paper elevation={0}>
              <Box sx={{ width: "100%", borderBottom: "2px solid #eeeeee" }}>
                <Box>
                  <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={subTab}
                    onChange={(event, newValue) =>
                      selectSubTab(selectedMenu, tab, newValue)
                    }
                  >
                    {selectedMenu?.elements[tab]?.items?.map((item, index) => (
                      <Tab
                        sx={{ fontSize: "11px", color: "#777777" }}
                        key={index}
                        value={index}
                        label={item?.label}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
            </Paper>
          </Box>
        )}
    </>
  );
};

export default MenuDrawer;
