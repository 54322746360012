import {employee_http} from "../../https/employee_http";
import {API_URL} from "../../utils/constants";
import {setEmployeeLoading} from "./miscActions";
import {toast} from "react-toastify";
import * as actionTypes from "../actionTypes";

export const uploadNewMarketingLeads = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/lead-management/upload`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong, please check employee list");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchAllMarketingLeads = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/lead-management?from=${from}&to=${to}&all=true`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ALL_MARKETING_LEADS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const assignToEmployee = (id, employeeId) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/lead-management/${id}`, { emp_id: employeeId })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_ALL_MARKETING_LEADS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const sendMarketingLeadsWhatsappMessage = (_id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/lead-management/send-whatsapp-message`, { _id })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MARKETING_LEADS_MSG_SEND,
          payload: res.data.data,
        });
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchMyMarketingLeads = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/lead-management?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MY_MARKETING_LEADS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateStatus = (id, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/lead-management/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_MY_MARKETING_LEADS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getLeadmanagemetReport = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/lead-management/report?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.MARKETING_LEADS_REPORT,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchLeadFunnelStats = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/lead-management/dashboard/funnel`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MARKETING_DASHBOARD_FUNNEL,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchLeadSourceStats = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/lead-management/dashboard/sources?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MARKETING_DASHBOARD_SOURCES,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchKeyLeadMetrics = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/lead-management/dashboard/key-leads?from=${from}&to=${to}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MARKETING_DASHBOARD_KEYLEADS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Something went wrong!");
        dispatch(setEmployeeLoading(false));
      });
  };
};
