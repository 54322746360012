import * as actionTypes from "../actionTypes";

let initialState = {
  approvalList: [],
  branchDiff: [],
};

export const employeeAreaManagerReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEE_AREA_MANAGER_APPROVAL_LIST:
      return {
        ...state,
        approvalList: action.payload,
      };
    case actionTypes.SET_PARTNER_BRANCH_DIFF_LIST:
      return {
        ...state,
        branchDiff: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        approvalList: [],
        branchDiff: [],
      };
    default:
      return state;
  }
};
