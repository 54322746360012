import * as actionTypes from "../actionTypes";

const initialState = {
  status: "",
};

export const whatsappReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOM_WHATSAPP_STATUS:
      return {
        ...state,
        status: action.payload?.status,
      };

    default:
      return state;
  }
};
