import { toast } from "react-toastify";
import { setEmployeeLoading } from ".";
import { user_http } from "../../https/user_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";
import fileDownload from "js-file-download";

export const createOptiproBrand = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .post(`${API_URL}/optipro/brand`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.GET_ALL_OPTIPRO_BRAND,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getAllOptiproBrand = (userID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .get(`${API_URL}/optipro/brand?userID=${userID}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.GET_ALL_OPTIPRO_BRAND,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getOutOfStockReportOptipro = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .post(`${API_URL}/optipro/orders/outOfStock`, id)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.OPTIPRO_FETCH_OOS_DATA,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadOOSDateReportOptipro = (date) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .get(`${API_URL}/optipro/orders/oos-report/${date}`, {
        responseType: "blob",
      })
      .then(async (res) => {
        fileDownload(res.data, `oos_report_${date}.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const downloadBlinkitOOSDateReportOptipro = (date) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .get(`${API_URL}/optipro/orders/oos-report-blinkit/${date}`, {
        responseType: "blob",
      })
      .then(async (res) => {
        fileDownload(res.data, `blinkit-status_${date}.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadOOSReportOptipro = (storeId, name) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/optipro/orders/downloadOOS/${storeId}`;
    user_http
      .get(url, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(response.data, `oos_report.xlsx`);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateOptiproBrand = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .put(`${API_URL}/optipro/brand`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.GET_ALL_OPTIPRO_BRAND,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createOptiproLocation = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .post(`${API_URL}/optipro/location`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.GET_ALL_OPTIPRO_LOCATION,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getAllOptiproLocation = (userID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .get(`${API_URL}/optipro/location?userID=${userID}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.GET_ALL_OPTIPRO_LOCATION,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateOptiproNotificationNumbers = (body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .post(`${API_URL}/optipro/location/notifications`, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.GET_ALL_OPTIPRO_LOCATION,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getAllOptiproAggregatorIds = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .post(`${API_URL}/optipro/idMapping`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_OPTIPRO_IDMAPPING,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateBrandIdMapping = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .put(`${API_URL}/optipro/idMapping`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_OPTIPRO_IDMAPPING,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadOptiproMapping = (userID) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .get(`${API_URL}/optipro/idMapping/download?userID=${userID}`, {
        responseType: "blob",
      })
      .then(async (res) => {
        fileDownload(res.data, `optipro_aggregator_Mapping.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadOptiproMapping = (userID, formData) => {
  return (dispatch) => {
    // dispatch(setEmployeeLoading(true));
    user_http
      .post(`${API_URL}/optipro/idMapping/upload?userID=${userID}`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        // fileDownload(res.data, `optipro_aggregator_Mapping.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
    toast.success("Flie uploading !!");
  };
};
export const createWalletOrder = async (client_id, amount, mode) => {
  const response = await user_http.post(
    `${API_URL}/client/pay-order`,
    {
      client_id,
      amount,
      mode,
    },
    {
      "Content-Type": "multipart/form-data",
    }
  );
  return response.data;
};

export const clientPoorRating = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    user_http
      .post(`${API_URL}/optipro/orders/poor-rating`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.FETCH_OPTIPRO_BRANDWISE_RATING,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchOptiproAverageKPTDetails = (query, body) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/optipro/orders/ops/details`;
    if (query.length > 0) {
      url += `?${query.join("&")}`;
    }
    user_http
      .post(url, body)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_OPTIPRO_DAILY_OPS_ITEM_DETAILS,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchOptiproSaleReport = async (date) => {
  try {
    console.log(date);
    const data = await user_http.post(
      `${API_URL}/optipro/orders/sale/dashboard`,
      date
    );
    // await API_URL.post(
    //   `${API_URL}/optipro/orders/sale/dashboard`,
    //   {
    //     date: date,
    //   },
    //   {
    //     "Content-Type": "application/json",
    //   }
    // );
    return data.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchItemFoodCostInaccurateAnalyticsOptipro = (
  from,
  to,
  store_id,
  userID,
  tab,
  aggregator
) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let url = `${API_URL}/optipro/orders/item/inaccurate-stats?from=${from}&to=${to}&tab=${tab}&userID=${userID}&aggregator=${aggregator}`;
    if (store_id) {
      url = url + `&store_id=${store_id}`;
    }
    user_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.OPTIPRO_ITEM_INACCURATE_FOOD_COST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
