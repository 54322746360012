import * as actionTypes from "../actionTypes";

let initialState = {
  allMarketingLeads: [],
  myMarketingLeads: [],
  marketingLeadsReport: [],
};

export const marketingLeadsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ALL_MARKETING_LEADS:
      return {
        ...state,
        allMarketingLeads: action.payload,
      };
    case actionTypes.UPDATE_ALL_MARKETING_LEADS:
      let update_all = [...state.allMarketingLeads];
      const update_all_index = update_all.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_all_index > -1) update_all[update_all_index] = action.payload;
      return {
        ...state,
        allMarketingLeads: update_all,
      };
    case actionTypes.SET_MARKETING_LEADS_MSG_SEND:
      let update_send = [...state.allMarketingLeads];
      const update_send_index = update_send.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_send_index > -1)
        update_send[update_send_index] = {
          ...action.payload,
        };
      return {
        ...state,
        allMarketingLeads: update_send,
      };
    case actionTypes.SET_MY_MARKETING_LEADS:
      return {
        ...state,
        myMarketingLeads: action.payload,
      };
    case actionTypes.UPDATE_MY_MARKETING_LEADS:
      let update_my = [...state.myMarketingLeads];
      const update_my_index = update_my.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_my_index > -1) update_my[update_my_index] = action.payload;
      return {
        ...state,
        myMarketingLeads: update_my,
      };
    case actionTypes.MARKETING_LEADS_REPORT:
      return {
        ...state,
        marketingLeadsReport: action.payload,
      };
    case actionTypes.SET_MARKETING_DASHBOARD_FUNNEL:
      return {
        ...state,
        leadsFunnelStats: action.payload,
      };
    case actionTypes.SET_MARKETING_DASHBOARD_SOURCES:
      return {
        ...state,
        leadSourceStats: action.payload,
      };
    case actionTypes.SET_MARKETING_DASHBOARD_KEYLEADS:
      return {
        ...state,
        keyLeadsStats: action.payload,
      };
    case actionTypes.SET_MERCHANT_LOGOUT:
      return {
        allMarketingLeads: [],
        myMarketingLeads: [],
        marketingLeadsReport: [],
        leadsFunnelStats: [],
        leadSourceStats: [],
        keyLeadsStats: []
      };
    default:
      return state;
  }
};
