import React from 'react';

function LeadGenIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <g data-name="&lt;Group&gt;">
        <path
          fill="#fedb41"
          d="M219.74,127.83c8.08-55.45,59.25-104.31,115.14-109.04a127.69,127.69,0,0,1,91.55,226.24V217.39a37.916,37.916,0,0,0-37.72-41.74h-9.22L364.9,227.1l-15.14-27.03h.01l7.3-23.3h-23.3l7.3,23.3-14,27.03h-.01l-13.41-51.45H303.26a37.918,37.918,0,0,0-37.73,41.74v27.35a127.511,127.511,0,0,1-42.98-66.8,129.017,129.017,0,0,1-3.38-45.53C219.33,130.88,219.52,129.35,219.74,127.83ZM381.2,113.17V98.57a36.5,36.5,0,0,0-72.99,0v14.6a36.5,36.5,0,1,0,72.99,0Z"
        />
        <path
          fill="#00acea"
          d="M388.71,175.65a37.916,37.916,0,0,1,37.72,41.74v27.64a125.963,125.963,0,0,1-61.53,26.93,127.33,127.33,0,0,1-99.37-27.22V217.39a37.918,37.918,0,0,1,37.73-41.74h10.39l13.41,51.45h.01l14-27.03h8.69L364.9,227.1l14.59-51.45Z"
        />
        <path
          fill="#00acea"
          d="M381.2,98.57v14.6a36.5,36.5,0,1,1-72.99,0V98.57a36.5,36.5,0,0,1,72.99,0Z"
        />
        <path
          fill="#00efd1"
          d="M265.53,244.74a127.341,127.341,0,0,0,35.99,20.62l-.03.08-.01.02-47.57,51.89V419.01l-87.75,74.67V317.35L38.52,177.94H222.55A127.511,127.511,0,0,0,265.53,244.74Z"
        />
        <path
          fill="#00acea"
          d="M222.55,177.94H38.52V127.83H219.74c-.22,1.52-.41,3.05-.57,4.58A129.017,129.017,0,0,0,222.55,177.94Z"
        />
        <path
          fill="#083863"
          d="M334.29,11.815c-28.558,2.417-56.872,15.7-79.728,37.4-21.176,20.106-35.473,45.337-40.709,71.617H38.52a7,7,0,0,0-7,7v50.11a7,7,0,0,0,1.837,4.727l125.8,137.4V493.68a7,7,0,0,0,11.537,5.331l87.75-74.67a7,7,0,0,0,2.464-5.331V320.073L303.6,273.505a133.02,133.02,0,0,0,42.2,6.837,141.146,141.146,0,0,0,20.092-1.454A133.792,133.792,0,0,0,478.967,166.261c6.106-41.372-6.634-82.385-34.951-112.522A134.188,134.188,0,0,0,334.29,11.815ZM211.922,134.83a136.131,136.131,0,0,0,1.9,36.11H45.52V134.83ZM248.75,312.62a7,7,0,0,0-1.84,4.73v98.425l-73.75,62.757V317.35a7,7,0,0,0-1.837-4.727L54.42,184.94H217.255a135.6,135.6,0,0,0,43.853,65.227,134.06,134.06,0,0,0,28.557,17.821ZM363.9,265.031a120.144,120.144,0,0,1-91.374-23.7V217.39a6.979,6.979,0,0,0-.036-.708A30.917,30.917,0,0,1,303.26,182.65h4.981l12.046,46.216a7.008,7.008,0,0,0,13,1.453l12.042-23.25h.33l13.135,23.451a7,7,0,0,0,12.842-1.511l13.146-46.36h3.929a30.917,30.917,0,0,1,30.756,34.032,6.979,6.979,0,0,0-.036.708v24.233A119.077,119.077,0,0,1,363.9,265.031ZM343.3,183.77h4.243l-2.121,6.771Zm121.816-19.551a118.96,118.96,0,0,1-31.685,64.839V217.73a44.917,44.917,0,0,0-44.721-49.08h-9.22a7,7,0,0,0-6.734,5.09l-9.989,35.226-5.418-9.673,6.4-20.431a7,7,0,0,0-6.679-9.092h-23.3a7,7,0,0,0-6.679,9.092l6.438,20.548-4.291,8.286-8.813-33.812a7,7,0,0,0-6.774-5.234h-10.39a44.918,44.918,0,0,0-44.73,49.08v10.948a120.052,120.052,0,0,1-32.4-95.54c.151-1.441.331-2.889.536-4.305,7.641-52.4,56.447-98.637,108.8-103.067A120.585,120.585,0,0,1,465.115,164.219Z"
        />
        <path
          fill="#083863"
          d="M344.7,55.08a43.539,43.539,0,0,0-43.49,43.49v14.6a43.5,43.5,0,1,0,86.99,0V98.57a43.5,43.5,0,0,0-43.5-43.49Zm29.5,58.09a29.5,29.5,0,1,1-58.99,0V98.57a29.5,29.5,0,0,1,58.99,0Z"
        />
      </g>
    </svg>
  );
}

export default LeadGenIcon;
