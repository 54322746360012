import React from 'react';
import EmployeeAppBar from '../../components/EmployeeAppBar';
import EmployeeHeader from '../../components/EmployeeHeader';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const employeeHeaderHoc = (WrapComponent) => {
  function HocContent() {
    const theme = useTheme();
    const isLargeDevice = useMediaQuery(theme.breakpoints.up('sm'));

    return (
      <>
        { isLargeDevice ? 
          <div>
            <EmployeeAppBar />
          </div>
          :  
          <EmployeeHeader/>
        }
        <div style={isLargeDevice ? {marginLeft: "100px", marginTop: "16px"}: {}}>
          <WrapComponent />
        </div>
      </>
    );
  }
  return HocContent;
};

export default employeeHeaderHoc;
