import React from 'react';

function RetentionIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#fff1e8"
        d="M7,37.5c-2.481,0-4.5-2.019-4.5-4.5V7c0-2.481,2.019-4.5,4.5-4.5h26c2.481,0,4.5,2.019,4.5,4.5v26 c0,2.481-2.019,4.5-4.5,4.5H7z"
      />
      <path
        fill="#a16a4a"
        d="M33,3c2.206,0,4,1.794,4,4v26c0,2.206-1.794,4-4,4H7c-2.206,0-4-1.794-4-4V7c0-2.206,1.794-4,4-4 H33 M33,2H7C4.239,2,2,4.239,2,7v26c0,2.761,2.239,5,5,5h26c2.761,0,5-2.239,5-5V7C38,4.239,35.761,2,33,2L33,2z"
      />
      <path
        fill="#a16a4a"
        d="M38,11.692H2V7c0-2.761,2.239-5,5-5h26c2.761,0,5,2.239,5,5V11.692z"
      />
      <path
        fill="#b0c1d4"
        d="M32,9h2c0.552,0,1-0.448,1-1V6c0-0.552-0.448-1-1-1h-2c-0.552,0-1,0.448-1,1v2	C31,8.552,31.448,9,32,9z"
      />
      <path fill="#f78f8f" d="M6 2.692H8V11.692H6z" />
      <path fill="#ffeea3" d="M8 2.692H10V11.692H8z" />
      <path fill="#bae0bd" d="M10 2.692H12V11.692H10z" />
      <path fill="#8bb7f0" d="M12 2.692H14V11.692H12z" />
      <path fill="#fff1e8" d="M20 9A12 12 0 1 0 20 33A12 12 0 1 0 20 9Z" />
      <path
        fill="#b0c1d4"
        d="M20,30.5c-5.238,0-9.5-4.262-9.5-9.5s4.262-9.5,9.5-9.5s9.5,4.262,9.5,9.5S25.238,30.5,20,30.5z"
      />
      <path
        fill="#66798f"
        d="M20,12c4.963,0,9,4.037,9,9s-4.037,9-9,9s-9-4.037-9-9S15.037,12,20,12 M20,11 c-5.523,0-10,4.477-10,10s4.477,10,10,10s10-4.477,10-10S25.523,11,20,11L20,11z"
      />
      <path fill="#66798f" d="M20 19A2 2 0 1 0 20 23A2 2 0 1 0 20 19Z" />
    </svg>
  );
}

export default RetentionIcon;
