import * as actionTypes from "../actionTypes";

const initialState = {
  allStories: [],
};

export const marketingStoryGenReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_MARKETING_STORIES:
      return {
        ...state,
        allStories: action.payload,
      };

    default:
      return state;
  }
};
