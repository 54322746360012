import axios from "axios";
import {push} from "connected-react-router";
import {API_URL} from "../../utils/constants";
import * as actionTypes from "../actionTypes";
import {toast} from 'react-toastify'

export const setEmployeeLoginError = (err) => ({
  type: actionTypes.SET_EMPLOYEE_LOGIN_ERROR,
  payload: err,
});

export const employeeLogin = (user, pwd, redirectURL) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.EMPLOYEE_LOGIN_LOADING, payload: true });
    setTimeout(() => {
      dispatch({ type: actionTypes.EMPLOYEE_LOGIN_LOADING, payload: false });
    }, 5000);
    axios
      .post(`${API_URL}/gk_emp/auth/login`, {
        username: user,
        password: pwd,
      })
      .then(async (res) => {
        await window.localStorage.setItem(
          "employee_auth_token",
          res.data.token
        );
        await window.localStorage.setItem(
          "employee_refresh_token",
          res.data.refreshToken
        );
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_LOGIN_DATA,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.EMPLOYEE_LOGIN_LOADING,
          payload: false,
        });
        await dispatch(push(redirectURL));
      })
      .catch(() => {
        toast.error('Username/Password is wrong')
        dispatch({ type: actionTypes.EMPLOYEE_LOGIN_LOADING, payload: false });
        dispatch(setEmployeeLoginError(true));
      });
  };
};

export const employeeLogout = () => {
  return async (dispatch) => {
    window.localStorage.removeItem("employee_auth_token");
    window.localStorage.removeItem("employee_refresh_token");
    await dispatch({ type: actionTypes.SET_EMPLOYEE_LOGOUT });
  };
};
