import fileDownload from "js-file-download";
import {toast} from "react-toastify";
import {setEmployeeLoading} from ".";
import {employee_http} from "../../https/employee_http";
import {API_URL} from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchEstimatedFundInflowConfig = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/estimated-fund-inflow/config`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ESTIMATED_FUND_INFLOW_CONFIG,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEstimatedFundInflowConfigForPartner = (partner_id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/estimated-fund-inflow/config?partner_id=${partner_id}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ESTIMATED_FUND_INFLOW_CONFIG_FOR_PARTNER,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadEstimatedFundInflowConfig = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/estimated-fund-inflow/config/download`, {
        responseType: "blob",
      })
      .then(async (res) => {
        fileDownload(res.data, `estimated-fund-inflow-config.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to download!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadEstimatedFundInflowConfig = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .put(`${API_URL}/estimated-fund-inflow/config/upload`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(res.data.message);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Error while uploading file!");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEstimatedFundInflowCalculation = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/estimated-fund-inflow`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ESTIMATED_FUND_INFLOW_CALCULATION,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchEstimatedFundInflowCalculationTotal = (cocoId, cofoId) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/estimated-fund-inflow/total/${cocoId}/${cofoId}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ESTIMATED_FUND_INFLOW_CALCULATION_TOTAL,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadEstimatedFundInflowSchedule = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/estimated-fund-inflow/download/${id}`, {
        responseType: "blob",
      })
      .then(async (res) => {
        fileDownload(res.data, `${id}.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const downloadInternalEstimatedFundInflowSchedule = (id) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/estimated-fund-inflow/download/internal/${id}`, {
        responseType: "blob",
      })
      .then(async (res) => {
        fileDownload(res.data, `${id}.xlsx`);
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const createEstimatedFundInflowCalculation = (data, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/estimated-fund-inflow`, data)
      .then(async () => {
        toast.success("Schedule has been created");
        await dispatch(setEmployeeLoading(false));
        await dispatch(fetchEstimatedFundInflowCalculation());
        cb();
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateEstimatedFundInflowConfig = (id, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/estimated-fund-inflow/config/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_ESTIMATED_FUND_INFLOW_CONFIG,
          payload: res.data?.length > 0 ? res.data[0] : {},
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to update !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
