import React, {useEffect, useState} from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/SyncRounded";
import {useDispatch, useSelector} from "react-redux";
import {getCustomWhatsappLogout, getCustomWhatsappStatus, initCustomWhatsapp,} from "../../../redux/actions";
import {employee_http} from "../../../https/employee_http";
import {API_URL} from "../../../utils/constants";
import Dialog from "@mui/material/Dialog";

function CustomWhatsapp({ checkStatusUpdate }) {
  // const [status, setStatus] = useState('');
  const dispatch = useDispatch();
  const [qrImage, setQRImage] = useState("");
  const [myInterval, setMyInterval] = useState(null);
  const [init, setInit] = useState(false);
  const [openQR, setOpenQR] = useState(false);

  const status = useSelector((state) => state?.whatsapp?.status);

  const handleRefresh = () => {
    dispatch(getCustomWhatsappStatus());
  };

  useEffect(() => {
    if (checkStatusUpdate) {
      dispatch(getCustomWhatsappStatus());
    }
  }, [dispatch, checkStatusUpdate]);

  useEffect(() => {
    if (
      status === "" ||
      status === "tokenRemoved" ||
      status === "qrReadFail" ||
      status === "BACKEND_ERROR"
    ) {
      setInit(false);
      setOpenQR(false);
    }
  }, [status]);

  useEffect(() => {
    if (
      myInterval &&
      (status === "qrReadSuccess" ||
        status === "chatsAvailable" ||
        status === "CONNECTED" ||
        status === "connected" ||
        status === "isConnected" ||
        status === "qrReadFail" ||
        status === "BACKEND_ERROR" ||
        status === "open")
    ) {
      clearInterval(myInterval);
      setOpenQR(false);
    }
  }, [status, myInterval]);

  const statusUI = () => {
    switch (status) {
      case "qrReadSuccess":
      case "chatsAvailable":
      case "CONNECTED":
      case "connected":
      case "isConnected":
      case "isLogged":
      case "open":
        return (
          <span style={{ color: "#00b200", fontSize: 10 }}>Connected</span>
        );
      case "CONFLICT":
        return (
          <span style={{ color: "red", fontSize: 10 }}>
            Active in other browser
          </span>
        );
      case "TIMEOUT":
      case "deviceNotConnected":
        return (
          <span style={{ color: "red", fontSize: 10 }}>
            Device not connected
          </span>
        );
      case "notLogged":
      case "UNPAIRED":
      case "desconnectedMobile":
      case "connecting":
        return <span style={{ color: "red", fontSize: 10 }}>Scan QR</span>;
      case "PAIRING":
      case "OPENING":
        return <span style={{ color: "red", fontSize: 10 }}>Pairing</span>;
      case "qrReadFail":
        return (
          <span style={{ color: "red", fontSize: 10 }}>Failed to read QR</span>
        );
      default:
        return (
          <span style={{ color: "red", fontSize: 10 }}>Not Initialized</span>
        );
    }
  };

  const getTitle = () => {
    switch (status) {
      case "qrReadSuccess":
      case "chatsAvailable":
      case "CONNECTED":
      case "connected":
      case "isConnected":
      case "isLogged":
      case "open":
        return "Click here to logout";
      case "CONFLICT":
        return "Click here to reconnect here";
      case "deviceNotConnected":
      case "TIMEOUT":
        return "Phone not connected to internet";
      case "notLogged":
      case "UNPAIRED":
      case "desconnectedMobile":
      case "connecting":
        return "Click here to scan qr";
      case "PAIRING":
      case "OPENING":
        return "Pairing";
      case "qrReadFail":
        return "Click here to re-initialize instance";
      default:
        return "Click here to initialize instance";
    }
  };

  const getQR = async () => {
    try {
      const response = await employee_http.get(`${API_URL}/wp/get-qr`);
      setQRImage(response.data);
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleClick = () => {
    switch (status) {
      case "qrReadSuccess":
      case "chatsAvailable":
      case "CONNECTED":
      case "connected":
      case "isConnected":
      case "isLogged":
      case "open":
        dispatch(getCustomWhatsappLogout());
        setInit(false);
        break;
      case "CONFLICT":
        dispatch(getCustomWhatsappStatus());
        break;
      case "deviceNotConnected":
      case "TIMEOUT":
        console.log("connect whatsapp number to internet");
        break;
      case "PAIRING":
      case "OPENING":
        console.log("pairing");
        break;
      case "desconnectedMobile":
      case "UNPAIRED":
      case "notLogged":
      case "connecting":
        getQR();
        dispatch(getCustomWhatsappStatus());
        setOpenQR(true);
        const intvl = setInterval(() => {
          getQR();
          dispatch(getCustomWhatsappStatus());
        }, 10000);
        setMyInterval(intvl);
        break;
      case "qrReadFail":
      case "BACKEND_ERROR":
      default:
        init
          ? dispatch(getCustomWhatsappStatus())
          : dispatch(initCustomWhatsapp(() => setInit(true)));
        break;
    }
  };

  const qrPopupStatusCheck = () => {
    let temp = false;
    if (
      status === "notLogged" ||
      status === "qrReadFail" ||
      status === "desconnectedMobile" ||
      status === "UNPAIRED" ||
      status === "connecting"
    ) {
      temp = true;
    }
    return temp;
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ fontSize: 12, marginRight: 16 }}>Custom whatsapp</div>
        <IconButton size="small" onClick={handleRefresh}>
          <RefreshIcon fontSize="small" />
        </IconButton>
      </div>
      <div style={{ cursor: "pointer" }} onClick={handleClick}>
        <Tooltip title={getTitle()}>{statusUI()}</Tooltip>
      </div>
      <Dialog open={Boolean(qrImage) && qrPopupStatusCheck() && openQR}>
        <div
          style={{
            padding: 30,
            background: "#fff",
            borderRadius: 8,
            maxWidth: 250,
          }}
        >
          <div style={{ marginBottom: 16, fontSize: 12, textAlign: "center" }}>
            Open linked device in whatsapp settings to scan this qr
          </div>
          <img src={qrImage} alt="" height={250} width={250} />
          <div style={{ marginTop: 16, fontSize: 12 }}>
            QR will be autoclosed once successfully scanned
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default CustomWhatsapp;
