import {toast} from "react-toastify";
import * as actionTypes from "../actionTypes";
import {setEmployeeLoading, setReloadAPI} from ".";
import {employee_http} from "../../https/employee_http";
import {API_URL} from "../../utils/constants";
import fileDownload from "js-file-download";
import moment from "moment";
import {merchant_http} from "../../https/merchant_http";
import {distributor_http} from "../../https/distributor_http";

export const getIngredientItem = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/inventory/item?type=RECIPE`)
      .then((res) => {
        dispatch({ type: actionTypes.INIT_STOCK_ITEM, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getAllProducts = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/all`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_PRODUCTS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const updateProductRequest = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/product/update`, data)
      .then((res) => {
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getProductRequest = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/product/update`)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_ALL_PRODUCTS_REQUEST,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const updateProductConfirm = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/product/update/confirm`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.GET_ALL_PRODUCTS_REQUEST,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getAllProductsMerchant = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .get(`${API_URL}/product-master/all`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_PRODUCTS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getAllSupplierProducts = (supplierId) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/product-master/purchase-order/products?supplierId=${supplierId}`
      )
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_SUPPLIER_PRODUCTS,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getAllPartnerProducts = (partnerId) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/product-master/purchase-order/products?partnerId=${partnerId}`
      )
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_PARTNER_PRODUCTS,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const addNewProduct = (product) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/`, product)
      .then((res) => {
        dispatch({ type: actionTypes.ADD_NEW_PRODUCT, payload: res.data[0] });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const addCategory = (category) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/category`, category)
      .then((res) => {
        dispatch({ type: actionTypes.ADD_NEW_CATEGORY, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getProductTemplate = async () => {
  return await employee_http.get(`${API_URL}/product-master/export-template`, {
    responseType: "blob",
  });
};
export const getUnitTemplate = async () => {
  return await employee_http.get(`${API_URL}/product-master/unit/export`, {
    responseType: "blob",
  });
};
export const uploadUnits = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  employee_http.post(`${API_URL}/product-master/unit/import`, formData, {
    "Content-Type": "multipart/form-data",
  });
};
export const uploadCategories = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  employee_http.post(`${API_URL}/product-master/upload/category`, formData, {
    "Content-Type": "multipart/form-data",
  });
};
export const uploadBrands = async (file) => {
  let formData = new FormData();
  formData.append("file", file);
  employee_http.post(`${API_URL}/product-master/upload/brand`, formData, {
    "Content-Type": "multipart/form-data",
  });
};
export const downloadPricingTemplate = async () => {
  return await employee_http.get(
    `${API_URL}/product-master/export-pricing-template`
  );
};

export const getProductCostPricingTemplate = async () => {
  return await employee_http.get(
    `${API_URL}/product-master/purchase-order/export-cost`,
    { responseType: "blob" }
  );
};

export const uploadProductTemplate = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/product-master/import-template`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(`
          Uploaded Products
        `);
        dispatch(getAllProducts());
        await dispatch(setEmployeeLoading(false));
        await dispatch(setReloadAPI(false));
      })
      .catch(() => {
        toast.error("Error in uploading data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadProductPricingTemplate = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http.post(
      `${API_URL}/product-master/import-pricing-template`,
      formData,
      {
        "Content-Type": "multipart/form-data",
      }
    );

    toast.success(`
          Upload Initiated, Track status in Upload Status 
        `);
    dispatch(setEmployeeLoading(false));
  };
};
export const uploadBankStatement = (file, type) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    employee_http.post(`${API_URL}/bank-statements/upload`, formData, {
      "Content-Type": "multipart/form-data",
    });

    toast.success(`
          Upload Initiated, Track status in Upload Status 
        `);
    dispatch(setEmployeeLoading(false));
  };
};

export const fetchInflow = (fromDate, toDate) => {
  return async (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/bank-statements/inflow?fromDate=${moment(fromDate).format(
          "DD-MM-YYYY"
        )}&toDate=${moment(toDate).format("DD-MM-YYYY")}`
      )
      .then((res) => {
        dispatch({
          type: "STATEMENT_DATA",
          payload: res.data,
        });
      });

    dispatch(setEmployeeLoading(false));
  };
};
export const uploadSupplierPricingTemplate = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http.post(
      `${API_URL}/product-master/purchase-order/import-cost`,
      formData,
      {
        "Content-Type": "multipart/form-data",
      }
    );

    toast.success(`
          Upload Initiated, Track status in Upload Status 
        `);
    dispatch(setEmployeeLoading(false));
  };
};
export const addPurchaseOrder = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/purchase-order/`, data)
      .then((res) => {
        dispatch({
          type: "ADD_PURCHASE_ORDER",
          payload: res.data,
        });
        toast.success(`
              Purchase Order Added 
            `);
      });
    dispatch(setEmployeeLoading(false));
  };
};
export const addSaleOrder = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/sale-order/`, data)
      .then((res) => {
        dispatch({
          type: "ADD_SALE_ORDER",
          payload: res.data,
        });
        toast.success(`
              Sale Order Added 
            `);
      });
    dispatch(setEmployeeLoading(false));
  };
};
export const addMerchantSaleOrder = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .post(`${API_URL}/product-master/sale-order/merchant`, data)
      .then((res) => {
        dispatch({
          type: "ADD_MERCHANT_SALE_ORDER",
          payload: res.data,
          // type: actionTypes.SET_ALL_MERCHANT_SALE_ORDERS,
          // payload: res.data,
        });
        toast.success(`
              Sale Order Added 
            `);
      });
    dispatch(setEmployeeLoading(false));
  };
};

export const getAllSaleOrders = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/sale-order`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_SALE_ORDERS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const fetchSaleOrders = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/all-sale-order`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_SALE_ORDERS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getalldeliveryNotes = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/sale-order/deliveryNote`)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_DELIVERY_NOTES,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getallInventorydeliveryNotes = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/sale-order/inventory-dn`)
      .then((res) => {
        // console.log(res.data);
        dispatch({
          type: actionTypes.SET_ALL_INVENTORY_DN,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const monthlyOrderCounts = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/sale/store/order-count`, data)
      .then((res) => {
        dispatch({ type: actionTypes.MONTHLY_ORDER_COUNT, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getalldeliveryNotesMerchant = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .get(`${API_URL}/product-master/sale-order/deliveryNote`)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_DELIVERY_NOTES,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const allDNforMerchant = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .get(`${API_URL}/product-master/sale-order/allDNforMerchant`)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_DELIVERY_NOTES,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getallDistributordeliveryNotes = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    distributor_http
      .get(`${API_URL}/product-master/sale-order/distributor-deliveryNote`)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_DISTRIBUTOR_DELIVERY_NOTES,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getallDistributerPO = () => {
  console.log("hitting");
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    distributor_http
      .get(`${API_URL}/product-master/sale-order/distributerDeliveryNote`)
      .then((res) => {
        // dispatch({
        //   type: actionTypes.SET_ALL_DELIVERY_NOTES,
        //   payload: res.data,
        // });
        dispatch({
          type: actionTypes.SET_ALL_Distibritor_PO,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const addApprovedComment = (id, comment, status) => {
  return (dispatch) => {
    console.log(id, comment, status);
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/sale-order/comment`, {
        id,
        comment,
        status,
      })
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_DELIVERY_NOTES,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const updateDeliveryNote = (formData, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/sale-order/deliveryNote`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_SALE_ORDERS, payload: res.data });
        dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const updateDeliveryNoteMerchant = (formData, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .post(`${API_URL}/product-master/sale-order/deliveryNote`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_SALE_ORDERS, payload: res.data });
        dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const updateDistributorDeliveryNote = (formData, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    distributor_http
      .post(
        `${API_URL}/product-master/sale-order/distributor-deliveryNote`,
        formData,
        {
          "Content-Type": "multipart/form-data",
        }
      )
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_Distibritor_PO,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const updateGRN = (formData, cb) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .post(`${API_URL}/product-master/sale-order/grn`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_DELIVERY_NOTES,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
        cb();
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getAllMerchantSaleOrders = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .get(`${API_URL}/product-master/sale-order/merchant`)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_MERCHANT_SALE_ORDERS,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getAllAdminSaleOrders = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/sale-order/admin`)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_MERCHANT_SALE_ORDERS,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const addCreditPaymentMerchantSaleOrders = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .post(`${API_URL}/product-master/purchase-order/creditPayment`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_MERCHANT_SALE_ORDERS,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const paymentReceiveConfirm = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/purchase-order/paymentReceive`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.PAYMENT_CONFIRMATION_DATA,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const adminpaymentReceiveConfirm = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/purchase-order/admin-confirm`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.ADMIN_PAYMENT_CONFIRMATION,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setEmployeeLoading(false));
      });
  };
};
export const allCredits = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/purchase-order/allCredits`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.ALL_CREDITS_PAYMENT,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getProductCost = async (product, seller, unit) => {
  const response = await employee_http.post(
    `${API_URL}/product-master/purchase-order/get-cost`,
    {
      product,
      seller,
      unit,
    }
  );
  return response.data.price;
};
export const getProductCostForSale = async (product, partner, unit) => {
  const response = await employee_http.post(
    `${API_URL}/product-master/sale-order/get-cost`,
    {
      product,
      partner,
      unit,
    }
  );
  return response.data.price;
};
export const addBrand = (brand) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/brand`, brand)
      .then((res) => {
        dispatch({ type: actionTypes.ADD_NEW_BRAND, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const addUnit = (unit) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/product-master/unit`, unit)
      .then((res) => {
        dispatch({ type: actionTypes.ADD_NEW_UNIT, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getAllBrandStoreMapping = async () => {
  const res = await employee_http.get(
    `${API_URL}/product-master/brand-store-mapping`
  );

  return res.data;
};
export const saveAllBrandStoreMapping = async (data) => {
  const res = await employee_http.post(
    `${API_URL}/product-master/brand-store-mapping`,
    data
  );

  return res.data;
};

export const getAllProductData = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/unit`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_UNITS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
    employee_http
      .get(`${API_URL}/product-master/category`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_CATEGORIES, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
    employee_http
      .get(`${API_URL}/product-master/brand`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_BRANDS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
    employee_http
      .get(`${API_URL}/product-master/brand-unique`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_BRAND_UNIQUE, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getAllProductDataMerchant = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .get(`${API_URL}/product-master/unit`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_UNITS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
    merchant_http
      .get(`${API_URL}/product-master/category`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_CATEGORIES, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
    merchant_http
      .get(`${API_URL}/product-master/brand`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_BRANDS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
    merchant_http
      .get(`${API_URL}/product-master/brand-unique`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_BRAND_UNIQUE, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const addNewIngredientItem = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/inventory/item`, data)
      .then((res) => {
        dispatch({ type: actionTypes.ADD_STOCK_ITEM, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const updateIngredientItem = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/inventory/item/${data._id}`, data)
      .then((res) => {
        dispatch({ type: actionTypes.UPDATE_STOCK_ITEM, payload: data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const downloadAllIngredientAndSemiProcessedFood = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/inventory/ingredient/download-all`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(
          response.data,
          `ingredient_and_semi_processed_${moment().format()}.xlsx`
        );
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadIngredientAndSemiProcessedFood = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/inventory/ingredient/upload`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(`
          Added: ${res.data?.added_count},
          Error: ${res.data?.error_count}
        `);
        await dispatch(setEmployeeLoading(false));
        await dispatch(setReloadAPI(false));
      })
      .catch(() => {
        toast.error("Error in uploading data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getSemiProcessedItem = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/inventory/item?type=SEMI_PROCESSED`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const addNewSemiProcessedItem = (data, successCB) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/inventory/ingredient`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.ADD_STOCK_SEMI_PROCESSED_ITEM,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const updateSemiProcessedItem = (data, successCB) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/inventory/ingredient/${data._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.UPDATE_STOCK_SEMI_PROCESSED_ITEM,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getSemiProcessedRecipe = (item) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/inventory/item/${item._id}`)
      .then((res) => {
        console.log(res.data);
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: { ...item, recipe: {} },
        });
      });
  };
};

export const addSemiProcessedRecipe = (item) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/inventory/item/`, item)
      .then((res) => {
        dispatch({
          type: actionTypes.ADD_STOCK_SEMI_PROCESSED_ITEM,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
        toast.error("Unable to add recipe");
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: {},
        });
      });
  };
};

export const updateSemiProcessedRecipe = (item) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/inventory/item/${item._id}`, item)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: item,
        });
        dispatch({
          type: actionTypes.UPDATE_STOCK_SEMI_PROCESSED_ITEM,
          payload: item,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
        toast.error("Unable to add recipe");
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: {},
        });
      });
  };
};

export const uploadSemiProcessedFoodRecipe = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/inventory/recipe/upload/semi-processed`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(`
          Added: ${res.data?.added_count},
          Error: ${res.data?.error_count}
        `);
        await dispatch(setEmployeeLoading(false));
        await dispatch(setReloadAPI(false));
      })
      .catch(() => {
        toast.error("Error in uploading data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getInventoryItems = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/inventory/item`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_INVENTORY_ITEM,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const downloadInventoryItems = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/inventory/item/download`, {
        responseType: "blob",
      })
      .then(async (response) => {
        fileDownload(
          response.data,
          `inventory_items_${moment().format()}.xlsx`
        );
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const uploadItemRecipe = (file) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    employee_http
      .post(`${API_URL}/inventory/recipe/upload/item`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        toast.success(`
          Added: ${res.data?.added_count},
          Error: ${res.data?.error_count}
        `);
        await dispatch(setEmployeeLoading(false));
        await dispatch(setReloadAPI(false));
      })
      .catch(() => {
        toast.error("Error in uploading data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getItemRecipe = (id) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.INIT_STOCK_ITEM_RECIPE, payload: {} });
    employee_http
      .get(`${API_URL}/inventory/item/${id}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.recipe) {
          dispatch({
            type: actionTypes.INIT_STOCK_ITEM_RECIPE,
            payload: res.data,
          });
        }
      })
      .catch(() => {
        toast.error("unable to get date");
      });
  };
};

export const updateItemRecipe = (id, recipe, cb) => {
  return (dispatch) => {
    cb(true);
    employee_http
      .put(`${API_URL}/inventory/recipe/${id}`, { recipe })
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_ITEM_RECIPE,
          payload: res.data,
        });
        cb(false);
      })
      .catch(() => {
        toast.error("unable to update");
        cb(false);
      });
  };
};

export const addItemRecipe = (item_id, recipe, cb) => {
  return (dispatch) => {
    cb(true);
    employee_http
      .post(`${API_URL}/inventory/recipe/item/${item_id}`, { recipe })
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_ITEM_RECIPE,
          payload: res.data,
        });
        cb(false);
      })
      .catch(() => {
        toast.error("unable to add in recipe");
        cb(false);
      });
  };
};

export const uploadActiveSKUExcel = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/rista/active-skus-excel`, data, {
        "Content-Type": "multipart/form-data",
      })
      .then(() => {
        toast.success("Uploaded successfully !");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Error in uploading excel !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

//Suppliers

export const getAllSuppliers = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/supplier`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_SUPPLIERS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getAllSuppliersMerchant = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .get(`${API_URL}/product-master/supplier`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_SUPPLIERS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getAllPurchaseOrders = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/purchase-order`)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_PURCHASE_ORDERS,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getAllPaymentTerms = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/payment-terms`)
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ALL_PAYMENT_TERMS,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const getAllCocos = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/product-master/cocos`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_COCOS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getAllCocosMerchant = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
      .get(`${API_URL}/product-master/cocos`)
      .then((res) => {
        dispatch({ type: actionTypes.SET_ALL_COCOS, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const createSupplier = ({ supplier, cancelledCheque }) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("supplier", JSON.stringify({ supplier }));
    formData.append("file", cancelledCheque);

    employee_http
      .post(`${API_URL}/product-master/supplier`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        dispatch({ type: actionTypes.ADD_NEW_SUPPLIER, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
export const editSupplier = ({ supplier, GSTINCertificate, sampleInvoice }) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    let formData = new FormData();
    formData.append("supplier", JSON.stringify(supplier));
    formData.append("file", GSTINCertificate);
    // formData.append("file", sampleInvoice);
    employee_http
      .put(`${API_URL}/product-master/supplier`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        dispatch({ type: actionTypes.EDIT_SUPPLIER, payload: res.data });
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const uploadDotPe = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/sale/store/dotpe-upload`, data, {
        "Content-Type": "multipart/form-data",
      })
      .then(() => {
        toast.success("Uploaded successfully !");
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Error in uploading excel !");
        dispatch(setEmployeeLoading(false));
      });
  };
};
