import * as actionTypes from "../actionTypes";

let initialState = {
  approvalList: [],
  clientList: [],
};

export const employeeOperationsHeadReducers = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEE_OPERATIONS_HEAD_APPROVAL_LIST:
      return {
        ...state,
        approvalList: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        approvalList: [],
        clientList: [],
      };
    case actionTypes.ADD_CLIENT_DETAILS:
      return {
        ...state,
        clientList: [...state.clientList, {...action.payload}],
      };
    case actionTypes.FETCH_ALL_CLIENTS:
      return {
        ...state,
        clientList: action.payload,
      };
    case actionTypes.UPDATE_CLIENT_DETAILS: {
      let updateList = [...state.clientList];
      const updateListIndex = updateList.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateListIndex > -1)
        updateList[updateListIndex] = action.payload;
      return {
        ...state,
        clientList: updateList,
      };
    }
    default:
      return state;
  }
};
