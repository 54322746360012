import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { miscReducers } from "./miscReducers";
import { merchantAuthReducers } from "./merchantAuthReducers";
import { userAuthReducers } from "./userAuthReducers";
import { distributorAuthReducers } from "./distributorAuthReducers";
import { employeeAuthReducers } from "./employeeAuthReducers";
import { employeeDetailsReducers } from "./employeeDetailsReducers";
import { storeDetailsReducers } from "./storeDetailsReducers";
import { merchantReportsReducers } from "./merchantReportsReducers";
import { employeeLeadGenReducers } from "./employeeLeadGenReducers";
import { employeeAreaManagerReducers } from "./employeeAreaManagerReducers";
import { employeeOperationsHeadReducers } from "./employeeOperationsHeadReducers";
import { employeePropertyReducers } from "./employeePropertyReducers";
import { marketingLeadsReducers } from "./marketingLeadsReducers";
import { ticketReducers } from "./ticketReducers";
import { analyticsReducers } from "./analyticsReducers";
import { merchantFoodCostReducers } from "./merchantFoodCostReducers";
import { editRequestReducers } from "./editRequestReducers";
import { employeeReconReducers } from "./employeeReconReducers";
import { marketingStoryGenReducers } from "./marketingStoryGenReducers";
import { whatsappReducers } from "./whatsappReducers";
import { rupifiReducers } from "./rupifiReducers";
import { stockReducers } from "./stockReducers";
import { zsMetricReducers } from "./zsMetricReducers";
import { fundInflowReducers } from "./fundInflowReducers";
import { flagsReducers } from "./flagsReducers";
import { schedulerLogsReducers } from "./schedulerLogsReducers";
import { userDetailsReducers } from "./userDetailsReducers";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    misc: miscReducers,
    merchantAuth: merchantAuthReducers,
    userAuth: userAuthReducers,
    distributorAuth: distributorAuthReducers,
    employeeAuth: employeeAuthReducers,
    employeeDetails: employeeDetailsReducers,
    storeDetails: storeDetailsReducers,
    merchantReports: merchantReportsReducers,
    employeeLeadGen: employeeLeadGenReducers,
    employeeAreaManager: employeeAreaManagerReducers,
    employeeOperations: employeeOperationsHeadReducers,
    employeeProperties: employeePropertyReducers,
    marketingLeads: marketingLeadsReducers,
    tickets: ticketReducers,
    analytics: analyticsReducers,
    foodcost: merchantFoodCostReducers,
    editRequest: editRequestReducers,
    recon: employeeReconReducers,
    marketingStory: marketingStoryGenReducers,
    whatsapp: whatsappReducers,
    rupifi: rupifiReducers,
    stock: stockReducers,
    zsMetric: zsMetricReducers,
    fundInflow: fundInflowReducers,
    flags: flagsReducers,
    schedulerLogs: schedulerLogsReducers,
    userDetailsReducers: userDetailsReducers,
  });

export default reducers;
