import * as React from "react";
import Drawer from "@mui/material/Drawer";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import marketingIcon from "../../../assets/menu/marketing.svg";

import {useDispatch} from "react-redux";
import {useLocation} from "react-router";
import {push} from "connected-react-router";

const drawerWidth = 100;

const MenuDrawer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [selectedMenu, setSelectedMenu] = React.useState({});
  const [tab, setTab] = React.useState(0);
  const [subTab, setSubTab] = React.useState(false);

  React.useEffect(() => {
    const currentMenuOption = getCurrentMenuOption();
    setSelectedMenu(currentMenuOption);
    const currentTabIndex = getCurrentMenuTab(currentMenuOption);
    setTab(currentTabIndex);
    if (currentMenuOption?.elements[currentTabIndex]?.items?.length > 0) {
      const currentSubTabIndex = getCurrentMenuSubTab(
        currentMenuOption,
        currentTabIndex
      );
      setSubTab(currentSubTabIndex);
      currentMenuOption?.elements[currentTabIndex]?.items[
        currentSubTabIndex
      ]?.handleMenu();
    } else {
      currentMenuOption?.elements[currentTabIndex].handleMenu();
    }
  }, [location.pathname]);

  const menuOptions = [
    // {
    //   title: "Home",
    //   visible: true,
    //   icon: homeIcon,
    //   active: location.pathname === "/distributor/home",
    //   elements: [
    // {
    //   label: "Home",
    //   active: location.pathname === "/distributor/home",
    //   handleMenu: () => {
    //     dispatch(push("/distributor/home"));
    //   },
    //   visible: true,
    // },
    //   ],
    // },
    {
      title: "Inventory",
      visible: true,
      icon: marketingIcon,
      active: location.pathname === "/distributor/inventory",
      elements: [
        {
          label: "Delivery Notes",
          active: location.pathname === "/distributor/inventory",
          handleMenu: () => {
            dispatch(push("/distributor/inventory"));
          },
          visible: true,
        },
      ],
    },
  ];

  const selectMenuOption = (menuOption) => {
    setSelectedMenu(menuOption);
    if (menuOption && menuOption?.elements?.length > 0) {
      let tabIndx = menuOption?.elements?.findIndex((a) => a.visible);
      selectTab(menuOption, tabIndx > 0 ? tabIndx : 0);
    }
  };

  const selectTab = (menuOption, tabIndx) => {
    setTab(tabIndx);
    if (menuOption && menuOption?.elements?.length > 0) {
      if (menuOption?.elements[tabIndx]?.items?.length > 0) {
        selectSubTab(menuOption, tabIndx, 0);
      } else {
        menuOption?.elements[tabIndx].handleMenu();
      }
    }
  };

  const selectSubTab = (menuOption, tabIndx, subTabIndx) => {
    setSubTab(subTabIndx);
    if (
      menuOption &&
      menuOption?.elements?.length > 0 &&
      menuOption?.elements[tabIndx]?.items?.length > 0
    ) {
      menuOption?.elements[tabIndx]?.items[subTabIndx]?.handleMenu();
    }
  };

  const getCurrentMenuOption = () => {
    return menuOptions.find((a) => a.active);
  };

  const getCurrentMenuTab = (menuOption) => {
    if (menuOption && menuOption?.elements?.length > 0) {
      return menuOption?.elements?.findIndex((a) => a.active);
    }
    return 0;
  };

  const getCurrentMenuSubTab = (menuOption, tabIndex) => {
    if (
      menuOption &&
      menuOption?.elements?.length > 0 &&
      menuOption?.elements[tabIndex]?.items?.length > 0
    ) {
      return menuOption?.elements[tabIndex]?.items?.findIndex((a) => a.active);
    }
    return 0;
  };

  return (
    <>
      <Drawer
        variant="persistent"
        anchor="left"
        open={true}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#0DAA47",
            color: "white",
          },
        }}
      >
        <Toolbar />
        <Box>
          <List>
            {menuOptions.map(
              (obj, index) =>
                obj?.visible && (
                  <ListItem
                    key={obj?.title}
                    disablePadding
                    sx={obj?.active ? { background: "#00953b" } : {}}
                  >
                    <ListItemButton
                      onClick={() => selectMenuOption(obj)}
                      sx={{ display: "block", textAlign: "center", my: "4px" }}
                    >
                      <ListItemIcon sx={{ display: "block" }}>
                        <img src={obj?.icon} alt={""} />
                      </ListItemIcon>
                      <Typography textAlign="center" sx={{ fontSize: "12px" }}>
                        {obj?.title}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                )
            )}
          </List>
        </Box>
      </Drawer>
      <Box
      // sx={{
      //   width: `calc(100% - ${drawerWidth}px)`,
      //   ml: `${drawerWidth}px`,
      //   mt: "64px",
      //   height: "46px",
      //   display: location.pathname === "/distributor/home" ? "none" : "block",
      // }}
      >
        <Paper elevation={1}>
          <Box sx={{ width: "100%" }}>
            <Box>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
                onChange={(event, newValue) =>
                  selectTab(selectedMenu, newValue)
                }
                aria-label="basic tabs example"
              >
                {selectedMenu?.elements?.map(
                  (section, index) =>
                    section?.visible && (
                      <Tab
                        sx={{ fontSize: "11px", color: "#777777" }}
                        key={index}
                        value={index}
                        label={section?.label}
                      />
                    )
                )}
              </Tabs>
            </Box>
          </Box>
        </Paper>
      </Box>
      {selectedMenu?.elements?.length > 0 &&
        selectedMenu?.elements[tab]?.items?.length > 0 &&
        selectedMenu?.elements[tab]?.visible && (
          <Box
            sx={{
              width: `calc(100% - ${drawerWidth}px)`,
              ml: `${drawerWidth}px`,
              mt: "4px",
              height: "46px",
            }}
          >
            <Paper elevation={0}>
              <Box sx={{ width: "100%", borderBottom: "2px solid #eeeeee" }}>
                <Box>
                  <Tabs
                    variant="scrollable"
                    scrollButtons="auto"
                    value={subTab}
                    onChange={(event, newValue) =>
                      selectSubTab(selectedMenu, tab, newValue)
                    }
                  >
                    {selectedMenu?.elements[tab]?.items?.map((item, index) => (
                      <Tab
                        sx={{ fontSize: "11px", color: "#777777" }}
                        key={index}
                        value={index}
                        label={item?.label}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Box>
            </Paper>
          </Box>
        )}
    </>
  );
};

export default MenuDrawer;
