import React from 'react';

function DesignIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#00efd1"
        d="M479.03,437.14v.06a43.988,43.988,0,0,1-43.91,44H384.68V393.07h50.29a43.984,43.984,0,0,1,44.06,43.92v.15Z"
      />
      <path
        fill="#00acea"
        d="M382.53,264.05l.15-136.24H383l54.59.37c23.08,0,41.44,18.88,41.44,41.96V436.99a43.984,43.984,0,0,0-44.06-43.92H92.12a59.2,59.2,0,0,1-59.15-59.2v-.2c0-1.37.05-2.72.15-4.06a59.2,59.2,0,0,1,59.12-55.09h28.29V126.08h.53l58.19.38V320.25h78V126.98l56.25.37h.18l.15,136.7.03,23.77a34.32,34.32,0,0,0,68.64,0Z"
      />
      <polygon
        fill="#00efd1"
        points="348.18 30.8 382.73 83.46 313.63 83.46 348.18 30.8"
      />
      <polygon
        fill="#fedb41"
        points="382.73 83.46 382.68 127.81 382.53 264.05 314.13 264.05 313.83 264.05 313.68 127.35 313.63 83.46 382.73 83.46"
      />
      <path
        fill="#00efd1"
        d="M382.53,264.05l-.03,23.77a34.32,34.32,0,0,1-68.64,0l-.03-23.77h68.7Z"
      />
      <polygon
        fill="#fedb41"
        points="179.25 260 179.25 220 179.25 180 179.25 140 179.25 126.46 179.25 100 179.25 37.25 257.25 37.25 257.25 126.98 257.25 320.25 179.25 320.25 179.25 260"
      />
      <path
        fill="#00efd1"
        d="M120.53,126.08V274.52H92.24a59.2,59.2,0,0,0-59.12,55.09l-.15-.01V115.51a59.2,59.2,0,0,1,59.15-59.2h28.41Z"
      />
      <path
        fill="#083863"
        d="M437.629,120.18l-46.948-.318.045-36.393a8,8,0,0,0-1.309-4.4l-34.549-52.66a8,8,0,0,0-13.377,0l-34.55,52.66a8,8,0,0,0-1.311,4.4l-.085,35.829L265,119.032V37.25A8.009,8.009,0,0,0,257.25,29h-78A8.434,8.434,0,0,0,171,37.25v60.8c0,.626-.25,1.277-.25,1.952s.25,1.326.25,1.952v16.454l-42-.278V56.31A8.683,8.683,0,0,0,120.53,48H92.113C56.12,48,26.659,77,25.048,112H25V330h.11c-.088,1-.11,2.309-.11,3.67v.2A67.185,67.185,0,0,0,92.12,401H377v80.2a7.564,7.564,0,0,0,7.68,7.8h50.453A51.856,51.856,0,0,0,487,437.13V170.14C487,142.592,464.836,120.18,437.629,120.18ZM374.536,256H321.824l-.185-165h53.082Zm-.018,16-.018,15.787a26.32,26.32,0,0,1-52.64,0L321.842,272ZM348.18,44.923,367.913,75H328.447ZM249,45V312H187V268h8a8,8,0,0,0,0-16h-8V228h8a8,8,0,0,0,0-16h-8V188h8a8,8,0,0,0,0-16h-8V148h8a8,8,0,0,0,0-16h-8V108h8a8,8,0,0,0,0-16h-8V45ZM92.12,64H113V267H92.183C71.8,267,53,275.741,41,290.3V115.51C41,87.3,63.919,64,92.12,64Zm.007,321A51.183,51.183,0,0,1,41,333.8v-.13c0-1.162.028-2.088.116-3.274A51.087,51.087,0,0,1,92.191,283H120.53a8.835,8.835,0,0,0,8.47-8.48V134.129l42,.279v3.64c0,.626-.25,1.277-.25,1.952s.25,1.326.25,1.952v36.1c0,.626-.25,1.277-.25,1.952s.25,1.326.25,1.952v36.1c0,.626-.25,1.277-.25,1.952s.25,1.326.25,1.952v36.1c0,.626-.25,1.277-.25,1.952s.25,1.326.25,1.952v58.3a8.009,8.009,0,0,0,8.25,7.75h78a7.58,7.58,0,0,0,7.75-7.75V135.033l40.563.266.235,152.529a42.347,42.347,0,0,0,84.694,0l.175-151.968,46.906.318c18.439,0,33.427,15.235,33.427,33.96V399.557C461,390.212,448.572,385,435.058,385H92.127ZM471,437.2A35.824,35.824,0,0,1,435.12,473H393V401h42.044A36.018,36.018,0,0,1,471,436.99Z"
      />
    </svg>
  );
}

export default DesignIcon;
