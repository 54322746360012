import * as actionTypes from "../actionTypes";
import {employee_http} from "../../https/employee_http";
import {merchant_http} from "../../https/merchant_http";
import {API_URL} from "../../utils/constants";
import {setEmployeeLoading, setMerchantLoading} from ".";


export const sharePartnerGMVWithRupifi = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/rupifi/gmv/all?from=${from}&to=${to}`, {})
      .then(async (res) => {
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};



export const updateSupportingDocument = async (id, payoutId, file) => {
    let formData = new FormData();
    formData.append("file", file);
    const res = await employee_http.post(`${API_URL}/payout-schedules/supporting-document/${payoutId}/${id}`, formData)
    return res.data;
};

export const getPartnerGMV = (from, to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/rupifi/gmv/all?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_GMV_DATA,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const checkRupifiEligibility = (cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}/rupifi/eligibility`, {})
      .then(async (res) => {
        if(cb){
          cb(res?.data)
        }
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

export const fetchRupifiAccountStatement = (cb) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .post(`${API_URL}/rupifi/statement`, {})
      .then(async (res) => {
        if(cb){
          cb(res?.data)
        }
        await dispatch({
          type: actionTypes.SET_PARTNER_RUPIFI_STATEMENT,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};



