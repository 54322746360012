import * as actionTypes from "../actionTypes";

const initialState = {
  swiggyPlatformDates: [],
  zomatoPlatformDates: [],
  swiggySummaryData: [],
  zomatoSummaryData: [],
  zsPartnerHealthDetailsDataIndividual: [],
  salesAnalytics: [],
  dailyReport: {
    _id: "",
    totalOrders: 0,
    kptLessthanBenchMark: 0,
    rwtLessthanBenchMark: 0,
    adtLessthanBenchMark: 0
  }
};

export const zsMetricReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ZS_SWIGGY_PLATFORM_DATES:
      return {
        ...state,
        swiggyPlatformDates: action.payload,
      };
    case actionTypes.SET_ZS_ZOMATO_PLATFORM_DATES:
      return {
        ...state,
        zomatoPlatformDates: action.payload,
      };
    case actionTypes.SET_ZS_SWIGGY_SUMMARY_DATA:
      return {
        ...state,
        swiggySummaryData: action.payload,
      };
    case actionTypes.SET_ZS_ZOMATO_SUMMARY_DATA:
      return {
        ...state,
        zomatoSummaryData: action.payload,
      };
    case actionTypes.SET_PARTNER_IGCC_METRICS:
      return {
        ...state,
        partnerIgccMetrics: action.payload,
      };
    case actionTypes.SET_ZS_PARTNER_HEALTH:
      return {
        ...state,
        zsPartnerHealthData: action.payload,
      };
    case actionTypes.SET_ZS_PARTNER_HEALTH_DETAILS:
      return {
        ...state,
        zsPartnerHealthDetailsData: action.payload,
      };
    case actionTypes.SET_ZS_PARTNER_HEALTH_DETAILS_INDIVIDUAL:
      return {
        ...state,
        zsPartnerHealthDetailsDataIndividual: action.payload,
      };
    case actionTypes.SET_ZS_PARTNER_HEALTH_DETAILS_SALES:
      return {
        ...state,
        salesAnalytics: action.payload,
      };
    case actionTypes.SET_ZS_PARTNER_DAILY_REPORT:
      return {
        ...state,
        dailyReport: action.payload,
      };
    case actionTypes.SET_EMPLOYEE_LOGOUT:
      return {
        swiggyPlatformDates: [],
        zomatoPlatformDates: [],
        swiggySummaryData: [],
        zomatoSummaryData: [],
        zsPartnerHealthData: [],
        zsPartnerHealthDetailsData: [],
        zsPartnerHealthDetailsDataIndividual: [],
        salesAnalytics: [],
        dailyReport: {
          _id: "",
          totalOrders: 0,
          kptLessthanBenchMark: 0,
          rwtLessthanBenchMark: 0,
          adtLessthanBenchMark: 0
        }
      };
    default:
      return state;
  }
};
