import React from 'react';

function ManageTableIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#f283a5"
        d="M22,33h-4c-0.552,0-1-0.448-1-1l0-4.828c0-2.089-1.527-3.955-3.607-4.153 C11.007,22.791,9,24.661,9,27v8.035C9,38.329,11.671,41,14.965,41H20C20.185,39.9,21.8,33.889,22,33z"
      />
      <path
        fill="#fdeedd"
        d="M47.25 12.75C47.25 10.679 48.929 9 51 9s3.75 1.679 3.75 3.75v2.5c0 2.071-1.679 3.75-3.75 3.75s-3.75-1.679-3.75-3.75V12.75zM9.25 12.75C9.25 10.679 10.929 9 13 9s3.75 1.679 3.75 3.75v2.5c0 2.071-1.679 3.75-3.75 3.75s-3.75-1.679-3.75-3.75V12.75z"
      />
      <path
        fill="#85cbf8"
        d="M50.607,23.019C48.527,23.217,47,25.083,47,27.172V32c0,0.552-0.448,1-1,1h-4l2,8h5.014 C52.32,41,55,38.32,55,35.014V27C55,24.661,52.993,22.791,50.607,23.019z"
      />
      <path
        fill="#8d6c9f"
        d="M19 46h-5C8.486 46 4 41.514 4 36V15c0-.552.448-1 1-1s1 .448 1 1v21c0 4.411 3.589 8 8 8h5c.552 0 1 .448 1 1S19.552 46 19 46zM13.491 8.023C10.509 7.739 8 10.076 8 13v2c-.001.611.201 1.342.48 2 .255.601.657 1.127 1.143 1.562.146.13.296.264.424.378.219.195.453.376.714.509 1.541.785 3.391.719 4.881-.195C16.551 18.654 17 18 17.281 17.595 17.751 16.812 18.004 15.916 18 15v-1.785C18 10.603 16.091 8.271 13.491 8.023zM16 15c0 1.657-1.343 3-3 3s-3-1.343-3-3v-2c0-1.657 1.343-3 3-3s3 1.343 3 3V15z"
      />
      <path
        fill="#8d6c9f"
        d="M19.438 42H15c-3.86 0-7-3.14-7-7v-8c0-1.406.597-2.754 1.637-3.7 1.04-.945 2.444-1.412 3.85-1.276C16.018 22.265 18 24.526 18 27.172V32h2.5c.552 0 1 .448 1 1s-.448 1-1 1H18c-1.103 0-2-.897-2-2v-4.828c0-1.626-1.187-3.013-2.702-3.157-.858-.083-1.682.189-2.315.765C10.358 25.347 10 26.157 10 27v8c0 2.757 2.243 5 5 5h4.438c.552 0 1 .448 1 1S19.99 42 19.438 42zM46 13.215V15c-.004.916.249 1.812.719 2.595C47 18 47.449 18.654 48.357 19.254c1.49.914 3.34.98 4.881.195.261-.133.495-.314.714-.509.128-.114.278-.248.424-.378.486-.435.888-.961 1.143-1.562.28-.658.481-1.389.48-2v-2c0-2.924-2.509-5.261-5.491-4.977C47.909 8.271 46 10.603 46 13.215zM48 13c0-1.657 1.343-3 3-3s3 1.343 3 3v2c0 1.657-1.343 3-3 3s-3-1.343-3-3V13zM49 42h-4.5c-.552 0-1-.448-1-1s.448-1 1-1H49c2.757 0 5-2.243 5-5v-8c0-.843-.358-1.653-.982-2.221-.634-.576-1.46-.848-2.315-.765C49.187 24.159 48 25.546 48 27.172V32c0 1.103-.897 2-2 2h-2.438c-.552 0-1-.448-1-1s.448-1 1-1H46v-4.828c0-2.646 1.982-4.907 4.513-5.148 1.407-.136 2.81.331 3.85 1.276C55.403 24.246 56 25.594 56 27v8C56 38.86 52.86 42 49 42z"
      />
      <path
        fill="#8d6c9f"
        d="M50 46h-5c-.552 0-1-.448-1-1s.448-1 1-1h5c4.411 0 8-3.589 8-8V15c0-.552.448-1 1-1s1 .448 1 1v21C60 41.514 55.514 46 50 46zM5 58c-.128 0-.258-.025-.384-.078-.51-.212-.75-.798-.538-1.308l5-12c.212-.509.796-.751 1.308-.538.51.212.75.798.538 1.308l-5 12C5.763 57.769 5.391 58 5 58z"
      />
      <path
        fill="#8d6c9f"
        d="M59,58c-0.392,0-0.764-0.232-0.923-0.616l-5-12c-0.212-0.51,0.028-1.095,0.538-1.308 c0.512-0.213,1.096,0.029,1.308,0.538l5,12c0.212,0.51-0.028,1.095-0.538,1.308C59.259,57.976,59.128,58,59,58z"
      />
      <path fill="#faefde" d="M42 29L22 29 18 53 46 53z" />
      <path
        fill="#8d6c9f"
        d="M46,54H18c-0.294,0-0.573-0.129-0.763-0.354c-0.19-0.225-0.271-0.521-0.223-0.811l4-24 C21.094,28.354,21.511,28,22,28h20c0.489,0,0.906,0.354,0.986,0.835l4,24c0.048,0.29-0.033,0.586-0.223,0.811 C46.573,53.871,46.294,54,46,54z M19.181,52h25.639l-3.667-22H22.847L19.181,52z"
      />
      <path
        fill="#8d6c9f"
        d="M23 58c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1s1 .448 1 1v4C24 57.552 23.552 58 23 58zM41 58c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1s1 .448 1 1v4C42 57.552 41.552 58 41 58zM24 50c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1s1 .448 1 1v2C25 49.552 24.552 50 24 50zM29 50c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1s1 .448 1 1v2C30 49.552 29.552 50 29 50zM34 50c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1s1 .448 1 1v2C35 49.552 34.552 50 34 50zM39 50c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1s1 .448 1 1v2C40 49.552 39.552 50 39 50z"
      />
    </svg>
  );
}

export default ManageTableIcon;
