// import { toast } from "react-toastify";
import {setEmployeeLoading} from ".";
import {employee_http} from "../../https/employee_http";
import {API_URL} from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchAreaManagerApprovalList = () => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/store/approval?role=area-manager`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_AREA_MANAGER_APPROVAL_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const updateAreaManagerApproval = (partnerID, data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .put(`${API_URL}/store/approval?store_id=${partnerID}`, data)
      .then(async (res) => {
        await dispatch(setEmployeeLoading(false));
        await dispatch(fetchAreaManagerApprovalList());
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchNewBrandsReport = (from1, to1, from2, to2) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/sale/branch-diff?firstFromDate=${from1}&firstToDate=${to1}&secondFromDate=${from2}&secondToDate=${to2}`
      )
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_BRANCH_DIFF_LIST,
          payload: res.data,
        });
        await dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        dispatch(setEmployeeLoading(false));
      });
  };
};
