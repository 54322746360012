export const SET_STORE_APPROVAL_LIST = "SET_STORE_APPROVAL_LIST";
export const DELETE_APPROVAL_LIST = "DELETE_APPROVAL_LIST";
export const UPDATE_STORE_APPROVAL_LIST = "UPDATE_STORE_APPROVAL_LIST";
export const SET_STORE_LIST = "SET_STORE_LIST";
export const SET_RISTA_INTEGRATION_STORE_LIST =
  "SET_RISTA_INTEGRATION_STORE_LIST";
export const SET_CHANNEL_LIST = "SET_CHANNEL_LIST";
export const SET_FSSAI_STORE_LIST = "SET_FSSAI_STORE_LIST";
export const SET_ALL_STORE_LIST = "SET_ALL_STORE_LIST";
export const GET_ALL_STORE = "GET_ALL_STORE";
export const GET_ALL_CPC = "GET_ALL_CPC";
export const SET_EMPLOYEE_BLOGGER_ORDER = "SET_EMPLOYEE_BLOGGER_ORDER";

export const UPDATE_STORE_LIST = "UPDATE_STORE_LIST";

export const SET_STORE_AREA_MANAGER_LIST = "SET_STORE_AREA_MANAGER_LIST";

export const SET_STORE_BRAND_MAPPING = "SET_STORE_BRAND_MAPPING";
export const UPDATE_STORE_BRAND_MAPPING = "UPDATE_STORE_BRAND_MAPPING";

export const SET_PARTNER_PAYMENT_LIST = "SET_PARTNER_PAYMENT_LIST";
export const UPDATE_PARTNER_PAYMENT_LIST = "UPDATE_PARTNER_PAYMENT_LIST";
export const SET_PARTNER_PAYMENT_LINK_LIST = "SET_PARTNER_PAYMENT_LINK_LIST";

export const SET_EMPLOYEE_SALES_SUMMARY = "SET_EMPLOYEE_SALES_SUMMARY";
export const SET_EMPLOYEE_SALES_INSIGHTS = "SET_EMPLOYEE_SALES_INSIGHTS";

export const SET_EMPLOYEE_PAYOUT_SCHEDULES = "SET_EMPLOYEE_PAYOUT_SCHEDULES";
export const UPDATE_EMPLOYEE_PAYOUT_SCHEDULES =
  "UPDATE_EMPLOYEE_PAYOUT_SCHEDULES";

export const SET_PARTNER_PAYOUT_SCHEDULES = "SET_PARTNER_PAYOUT_SCHEDULES";
export const SET_BRAND_WISE_PAYOUT = "SET_BRAND_WISE_PAYOUT";

export const GET_ALL_BRAND_LIST = "GET_ALL_BRAND_LIST";

export const SET_STORE_STATUS_COUNT = "SET_STORE_STATUS_COUNT";

export const SET_RISTA_ITEMS_OUT_OF_STOCK = "SET_RISTA_ITEMS_OUT_OF_STOCK";

export const SET_STORE_BRAND_ZS_URL = "SET_STORE_BRAND_ZS_URL";
export const UPDATE_STORE_BRAND_ZS_URL = "UPDATE_STORE_BRAND_ZS_URL";

export const SET_EMPLOYEE_HOURLY_SALES = "SET_EMPLOYEE_HOURLY_SALES";
export const SET_GK_INVESTMENTS = "SET_GK_INVESTMENTS";
export const SET_BLOGGER_ORDERS = "SET_BLOGGER_ORDERS";
export const SET_ALL_REGIONS_SALES_SUMMARY = "SET_ALL_REGIONS_SALES_SUMMARY";
export const SET_REGION_SALES_SUMMARY = "SET_REGION_SALES_SUMMARY";
export const SET_BRANCH_SALES_SUMMARY = "SET_BRANCH_SALES_SUMMARY";
export const SET_BRAND_SALES_SUMMARY = "SET_BRAND_SALES_SUMMARY";
export const SET_CHANNEL_SALES_SUMMARY = "SET_CHANNEL_SALES_SUMMARY";
export const SET_ALL_BRANCHES_SALES_SUMMARY = "SET_ALL_BRANCHES_SALES_SUMMARY";
export const SET_ALL_BRANDS_SALES_SUMMARY = "SET_ALL_BRANDS_SALES_SUMMARY";
export const SET_ALL_CHANNELS_SALES_SUMMARY = "SET_ALL_CHANNELS_SALES_SUMMARY";
export const SET_PARTNER_INVOICES = "SET_PARTNER_INVOICES";
export const SET_PARTNER_PAYOUT_TRANSACTIONS =
  "SET_PARTNER_PAYOUT_TRANSACTIONS";
export const SET_AREA_MANAGER_OVERVIEW = "SET_AREA_MANAGER_OVERVIEW";
export const SET_AREA_MANAGER_REGION = "SET_AREA_MANAGER_REGION";
export const SET_EMPLOYEE_PAYOUT_TRANSACTIONS =
  "SET_EMPLOYEE_PAYOUT_TRANSACTIONS";
export const GET_ALL_CPC_REQUESTS = "GET_ALL_CPC_REQUESTS";

export const SET_ZS_DAILY_UPDATE = "SET_ZS_DAILY_UPDATE";
export const SET_TECH_SUPPROT = "SET_TECH_SUPPROT";
