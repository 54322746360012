import {toast} from "react-toastify";
import * as actionTypes from "../actionTypes";
import {setEmployeeLoading, setMerchantLoading} from ".";
import {employee_http} from "../../https/employee_http";
import {merchant_http} from "../../https/merchant_http";
import {API_URL} from "../../utils/constants";

export const uploadZSMetrics = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/sale-dump/upload`, data, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Error in uploading metrics !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const getPlatformDates = (platform) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(`${API_URL}/sale-dump/platform-dates?platform=${platform}`)
      .then((res) => {
        switch (platform) {
          case "swiggy":
            dispatch({
              type: actionTypes.SET_ZS_SWIGGY_PLATFORM_DATES,
              payload: res.data,
            });
            break;
          case "zomato":
            dispatch({
              type: actionTypes.SET_ZS_ZOMATO_PLATFORM_DATES,
              payload: res.data,
            });
            break;
          default:
            break;
        }
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getStorePlatformDates = (platform) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
        .get(`${API_URL}/sale-dump/platform-dates?platform=${platform}`)
        .then((res) => {
          switch (platform) {
            case "swiggy":
              dispatch({
                type: actionTypes.SET_ZS_SWIGGY_PLATFORM_DATES,
                payload: res.data,
              });
              break;
            case "zomato":
              dispatch({
                type: actionTypes.SET_ZS_ZOMATO_PLATFORM_DATES,
                payload: res.data,
              });
              break;
            default:
              break;
          }
          dispatch(setEmployeeLoading(false));
        })
        .catch((err) => {
          dispatch(setEmployeeLoading(false));
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          }
        });
  };
};


export const getStoreSalesAnalytics = (from,to,source) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
        .get(`${API_URL}/sale/store/analytics?fromDate=${from}&toDate=${to}&source=${source}`)
        .then((res) => {

              dispatch({
                type: actionTypes.SET_ZS_PARTNER_HEALTH_DETAILS_SALES,
                payload: res.data.salesData,
              });
          dispatch(setEmployeeLoading(false));
        })
        .catch((err) => {
          dispatch(setEmployeeLoading(false));
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          }
        });
  };
};

export const getStoreHealthAnalytics = (from,timeSlot) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
        .get(`${API_URL}/analytics/sale_dump/zs-partner-rwt-adt-kpt-stats?from=${from}&time_slot=${timeSlot}`)
        .then((res) => {

          dispatch({
            type: actionTypes.SET_ZS_PARTNER_DAILY_REPORT,
            payload: res.data,
          });
          dispatch(setEmployeeLoading(false));
        })
        .catch((err) => {
          dispatch(setEmployeeLoading(false));
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          }
        });
  };
};

export const resetMetricsSummaryData = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_ZS_SWIGGY_SUMMARY_DATA,
      payload: [],
    });
    dispatch({
      type: actionTypes.SET_ZS_ZOMATO_SUMMARY_DATA,
      payload: [],
    });
  };
};

export const fetchMetricsByPartner = (
  platform,
  metric,
  from1,
  to1,
  from2,
  to2
) => {
  return (dispatch) => {
    dispatch(resetMetricsSummaryData());
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/sale-dump/metric-by-partners?platform=${platform}&metric=${metric}&from1=${from1}&from2=${from2}&to1=${to1}&to2=${to2}`
      )
      .then((res) => {
        switch (platform) {
          case "swiggy":
            dispatch({
              type: actionTypes.SET_ZS_SWIGGY_SUMMARY_DATA,
              payload: res.data,
            });
            break;
          case "zomato":
            dispatch({
              type: actionTypes.SET_ZS_ZOMATO_SUMMARY_DATA,
              payload: res.data,
            });
            break;
          default:
            break;
        }
        dispatch(setEmployeeLoading(false));
      })
      .catch((err) => {
        dispatch(setEmployeeLoading(false));
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const uploadIGCCMetrics = (data) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .post(`${API_URL}/sale-dump/igcc/upload`, data, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        toast.success(res.data.message);
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Error in uploading metrics !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchPartnerIgccMetrics = (from, to) => {
  return (dispatch) => {
    dispatch(setMerchantLoading(true));
    merchant_http
      .get(`${API_URL}/sale-dump/igcc?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_IGCC_METRICS,
          payload: res.data,
        });
        await dispatch(setMerchantLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setMerchantLoading(false));
      });
  };
};


export const fetchZSPartnerHealth = (partner_id,from,to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/analytics/sale_dump/zs-partner-health?partner_id=${partner_id}&from=${from}&to=${to}`
      )
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ZS_PARTNER_HEALTH,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchZSPartnerHealthDetails = (partner_id,from,to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    employee_http
      .get(
        `${API_URL}/analytics/sale_dump/zs-partner-health-details?partner_id=${partner_id}&from=${from}&to=${to}`
      )
      .then((res) => {
        dispatch({
          type: actionTypes.SET_ZS_PARTNER_HEALTH_DETAILS,
          payload: res.data,
        });
        dispatch(setEmployeeLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get data !");
        dispatch(setEmployeeLoading(false));
      });
  };
};

export const fetchZSPartnerHealthDetailsSingle = (from,to) => {
  return (dispatch) => {
    dispatch(setEmployeeLoading(true));
    merchant_http
        .get(
            `${API_URL}/analytics/sale_dump/zs-partner-health-details-single?from=${from}&to=${to}`
        )
        .then((res) => {
          dispatch({
            type: actionTypes.SET_ZS_PARTNER_HEALTH_DETAILS_INDIVIDUAL,
            payload: res.data,
          });
          dispatch(setEmployeeLoading(false));
        })
        .catch(() => {
          toast.error("Unable to get data !");
          dispatch(setEmployeeLoading(false));
        });
  };
};
