import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import logo from "../../assets/logo/logo_1.svg";
import empAvatar from "../../assets/header/employee-profile.jpg";

import {useDispatch, useSelector} from "react-redux";
import {merchantLogout} from "../../redux/actions";
import MenuDrawer from "./components/MenuDrawer";
import Notifications from "./components/Notifications";

const ResponsiveAppBar = () => {
  const dispatch = useDispatch();
  const restaurant = useSelector((state) => state.merchantAuth?.loginData);

  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "black",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ maxHeight: "50px" }}>
            <Box sx={{ mx: "12px", flexGrow: 1 }}>
              <img src={logo} height={64} width={97} alt={""} />
            </Box>
            <Box sx={{ mx: "12px", flexGrow: 0 }}>
              <Typography textAlign="center">{restaurant?.nickname}</Typography>
            </Box>
            <Box sx={{ mx: "12px", flexGrow: 0 }}>
              <Notifications />
            </Box>
            <Box sx={{ mx: "12px", flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Profile" src={empAvatar} />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem onClick={() => dispatch(merchantLogout())}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <MenuDrawer />
    </>
  );
};
export default ResponsiveAppBar;
