import * as React from "react";
import { Route, Switch } from "react-router";
import suspenseHoc from "../hoc/suspenseHoc";

const DeliveryNotes = React.lazy(() =>
  import("../pages/DistributorDeliveryNote")
);
const UserBrandsPage = React.lazy(() =>
  import("../pages/UserBrandsPage/UserBrandsPage")
);
const UserLocationPage = React.lazy(() =>
  import("../pages/UserLocationPage/index")
);

const UserMappingPage = React.lazy(() =>
  import("../pages/UserMappingPage/index")
);

const UserWalletPage = React.lazy(() =>
  import("../pages/UserWalletPage/index")
);
const OptiproPoorRatedReviews = React.lazy(() =>
  import("../pages/OptiproPoorRatedReviews")
);

const OptiproSalesBusinessReport = React.lazy(() =>
  import("../pages/OptiproSalesBusinessReport")
);

const OptiproSalesDashboard = React.lazy(() =>
  import("../pages/OptiproSalesDashboard")
);

const OptiproOutOfStockPage = React.lazy(() =>
  import("../pages/OptiproOutOfStockPage")
);

const UserOrderLevelData = React.lazy(() =>
  import("../pages/UserOrderLevelData")
);

function UserRoutes() {
  console.log("working");
  return (
    <Switch>
      <Route
        exact
        path="/user/brands"
        component={suspenseHoc(UserBrandsPage)}
      />
      <Route
        exact
        // allowRole={["inventory"]}
        path="/user/location"
        component={suspenseHoc(UserLocationPage)}
      />
      <Route
        exact
        // allowRole={["inventory"]}
        path="/user/mapping"
        component={suspenseHoc(UserMappingPage)}
      />
      <Route
        exact
        // allowRole={["inventory"]}
        path="/user/wallet"
        component={suspenseHoc(UserWalletPage)}
      />
      <Route
        exact
        path="/user/poor-rating"
        component={suspenseHoc(OptiproPoorRatedReviews)}
      />

      <Route
        exact
        path="/user/order-level-data"
        component={suspenseHoc(UserOrderLevelData)}
      />
      <Route
        exact
        path="/user/business-report"
        component={suspenseHoc(OptiproSalesBusinessReport)}
      />
      <Route
        exact
        path="/user/oos"
        component={suspenseHoc(OptiproOutOfStockPage)}
      />
      <Route
        exact
        path="/user/sale-dashboard"
        component={suspenseHoc(OptiproSalesDashboard)}
      />
    </Switch>
  );
}

export default UserRoutes;
